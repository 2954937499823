<template>
  <div>
    <div class="footer py-6 text-center text-white h-auto relative">
      <div class="footer-left absolute hidden sm:block" />
      <div class="footer-right absolute hidden sm:block" />
      <!-- <blog-content class="mt-0 sm:mt-8" /> -->
      <ul class="footer-links py-2">
        <li>
          <a
            class="text-xs text-white hover:text-yellow-500 px-2"
            href="/"
            target="Info"
          >
            Why Choose LEAGUE88
          </a>
          |
        </li>
        <li>
          <a
            class="text-xs text-white hover:text-yellow-500 px-2"
            href="/"
            target="Info"
          >
            About Us
          </a>
          |
        </li>
        <li>
          <a
            class="text-xs text-white hover:text-yellow-500 px-2"
            href="/"
            target="_blank"
          >
            Blog
          </a>
          |
        </li>
        <li>
          <a
            class="text-xs text-white hover:text-yellow-500 px-2"
            href="/"
            target="Info"
          >
            Responsible Gambling
          </a>
          |
        </li>
        <li>
          <a
            class="text-xs text-white hover:text-yellow-500 px-2"
            href="/"
            target="Info"
          >
            Betting Rules &amp; Regulations
          </a>
          |
        </li>
        <li style="">
          <a
            class="text-xs text-white hover:text-yellow-500 px-2"
            href="/"
            target="affiliates"
          >
            Affiliates
          </a>
          |
        </li>
        <li>
          <a
            class="text-xs text-white hover:text-yellow-500 px-2"
            href="/"
            target="Info"
          >
            Help
          </a>
          |
        </li>
        <li>
          <a
            class="text-xs text-white hover:text-yellow-500 px-2"
            href="/"
            target="Info"
          >
            Contact Us
          </a>
        </li>
      </ul>
    </div>
    <div class="copy-right my-3">
      <div class="text-white text-center text-xs font-bold">
        Copyright © League88. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
// import BlogContent from '@/components/BlogContent.vue'

export default {
  name: 'FooterContentContainer',
  components: {
    // BlogContent
  },
};
</script>

<style scoped>
.footer,
.footer-left,
.footer-right {
  background: url(../assets/images/landing-page.png) no-repeat;
}
.footer {
  background-position: -74px -1130px;
  width: 996px;
}
.footer-left {
  background-position: 0 -1133px;
  height: 50px;
  left: -70px;
  top: 3px;
  width: 70px;
}
.footer-right {
  background-position: -1068px -1133px;
  height: 50px;
  right: -70px;
  top: 3px;
  width: 70px;
}
.footer-links li {
  display: inline;
}

@media (max-width: 639px) {
  .footer {
    background-position: -81px -1130px;
    width: 100%;
  }
  .footer-links {
    width: 100%;
    height: auto;
  }
}
</style>
