<template>
  <betting-live-table
    v-if="hasHdpLiveList"
    :list="bettingState.hdpLiveList"
    @click:match="onClickMatch"
    @click:odds="onClickOdds"
  />
  <betting-table
    v-if="hasFtHdpList"
    :list="bettingState.ftHdpList"
    @click:match="onClickMatch"
    @click:odds="onClickOdds"
  />
  <not-found-card v-if="!hasHdpLiveList && !hasFtHdpList" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import BettingTable from '@/components/Betting/BettingTable.vue';
import BettingLiveTable from '@/components/Betting/BettingLiveTable.vue';
import { useStore } from 'vuex';
import { useSetTicket } from '@/composition/set-ticket';
import NotFoundCard from '@/components/NotFoundCard.vue';
import { useBettingStore } from '../store/betting';
import { OddsType } from '../constants/OddsType.enum';

import type { ComputedRef } from 'vue';
import type { Hdp } from '@/interfaces/Hdp.interface';
import type { TeamSelected } from '@/interfaces/TeamSelected.interface';
import type { MatchSelected } from '@/interfaces/Ticket.interface';

export default defineComponent({
  name: 'ContainerFullTimeTable',
  components: {
    BettingTable,
    BettingLiveTable,
    NotFoundCard,
  },
  setup() {
    const store = useStore();
    const bettingStore = useBettingStore();

    onMounted(() => {
      bettingStore.getOddsList(OddsType.HDP);
    });

    const bettingState = computed(() => bettingStore.$state);

    const hdpLiveList: ComputedRef<Hdp[]> = computed(
      () => store.state.betting.hdpLiveList
    );

    const hasHdpLiveList: ComputedRef<boolean> = computed(
      () => bettingState.value?.hdpLiveList?.length > 0
    );
    const hasFtHdpList: ComputedRef<boolean> = computed(
      () => bettingState.value?.ftHdpList?.length > 0
    );

    const { useSetTicketInputList, useSetSelectedTicketInputList } =
      useSetTicket();

    const onClickMatch = (newTicketItem: MatchSelected) => {
      return useSetTicketInputList(newTicketItem);
    };

    const onClickOdds = (newOddsItem: TeamSelected) => {
      return useSetSelectedTicketInputList(newOddsItem);
    };

    return {
      bettingState,
      onClickMatch,
      onClickOdds,
      hdpLiveList,
      hasFtHdpList,
      hasHdpLiveList,
    };
  },
});
</script>

<style></style>
