import type { Module } from 'vuex';
import type { RootState } from '@/vuex-store/RootState.interface';
import state from './state';
import type { StatementState } from './StatementState.interface';

const statementModule: Module<StatementState, RootState> = {
  namespaced: true,
  state,
};

export default statementModule;
