<template>
  <div
    v-for="betItem in betList"
    :key="betItem.id"
    class="ticket-block font-bold"
  >
    <div class="head flex py-1 px-2 items-center justify-between">
      <div class="text-xs uppercase flex gap-1">
        <span class="bet-type">
          {{ getBetType(betItem?.matchId) }}
        </span>
      </div>
      <div
        class="delete-ticket-icon cursor-pointer no-underline text-right flex items-center"
        @click="onDeleteTicket(betItem?.matchId)"
      >
        <font-awesome-icon icon="times" class="text-xs font-bold" />
      </div>
    </div>
    <div class="body text-xs relative">
      <div
        class="px-2 py-1"
        :class="{
          'bg-slate-300 opacity-80 brightness-[0.65]': showError(betItem),
        }"
      >
        <div
          v-if="isShowDelete(betItem?.matchId)"
          class="absolute top-0 bottom-0 bg-gray-500 w-full left-0 flex items-center justify-center opacity-70"
        >
          <span class="rounded bg-gray-600 py-3 px-4 text-white">
            คู่นี้ปิดแล้ว
          </span>
        </div>
        <div class="match">
          <span
            :class="advColorClass(ADV_SELECTION.HOME_ADV, betItem?.adv)"
            class="home"
          >
            {{ betItem?.home?.[locale] }}
          </span>
          <span class="px-1 text-[#00f]">-vs-</span>
          <span
            :class="advColorClass(ADV_SELECTION.AWAY_ADV, betItem?.adv)"
            class="away"
          >
            {{ betItem?.away?.[locale] }}
          </span>
        </div>
        <span>{{ time(betItem.koTime) }}</span>
        <span class="block" :class="getSelectItemClass(betItem?.matchId)">
          <span>
            {{
              getSelectedItem(betItem?.matchId)?.label?.[locale] ||
              getSelectedItem(betItem?.matchId).label
            }}
            /
          </span>
          <span v-show="!isShowOdds(betItem?.matchId)">
            <span v-html="getHdpFormat(betItem?.matchId)" />
            @
          </span>
          <span :class="[getTicketBgClass(betItem?.matchId)]">
            <span
              v-show="showOldOddsValue(betItem)"
              class="px-0.5 line-through text-slate-500"
            >
              {{ getOldOddsValue(betItem?.matchId) }}
            </span>
            <span class="px-0.5" :class="oddsErrorClass(betItem)">
              {{ getOddsValue(betItem?.matchId) }}
            </span>
            <span
              :class="getTicketStatusIconColor(betItem?.matchId)"
              class="text-xs relative"
            >
              <div
                v-if="isShowOddsAnimation(betItem?.matchId)"
                class="absolute top-1.5 right-1 -mr-1 -mt-1 w-full h-2.5 bg-yellow-odds animate-ping"
              />
              {{ getTicketStatusIcon(betItem?.matchId) }}
            </span>
          </span>
        </span>
      </div>
      <div
        v-if="showError(betItem)"
        class="grid bg-red-600 top-1/3 p-1 w-full absolute opacity-80"
      >
        <div class="grid gap-1 text-white text-center">
          {{ $t(`ticketError.${betItem.error?.errorCode}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useHdpFormat } from '@/composition/hdp-format';
import { useI18n } from 'vue-i18n';
import { useUnixTimeToDate } from '@/composition/datetime-format';
import { useAdvTextColor } from '../composition/adv-color';
import { ADV_SELECTION, TICKET_STATUS } from '@/constants/Ticket.enum';
import { BettingOddsType } from '../constants/OddsType.enum';

import type { PropType } from 'vue';
import type { MatchSelected } from '../interfaces/Ticket.interface';
import type { TeamSelected } from '@/interfaces/TeamSelected.interface';
import { formatNumber } from '../utils/number-format';

export default defineComponent({
  name: 'TicketForm',

  props: {
    betList: {
      type: Array as PropType<MatchSelected[]>,
      default: () => [],
    },
    selectedTeamList: {
      type: Array as PropType<TeamSelected[]>,
      default: () => [],
    },
  },
  emits: ['click:delete'],
  setup(props, { emit }) {
    const { locale } = useI18n();

    const getHdpFormat = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      if (selectedItem?.odds) {
        return useHdpFormat(String(selectedItem?.odds));
      }
      return 0;
    };

    const advColorClass = (currentAdv: number, advValue: number) => {
      return useAdvTextColor(currentAdv, advValue);
    };

    const time = (accDate: string) => {
      return useUnixTimeToDate(accDate, 'HH:mm');
    };

    const getSelectedItem = (matchId: number): TeamSelected | undefined => {
      return props.selectedTeamList?.find(
        (el: TeamSelected) => el?.matchId === matchId
      );
    };

    const getSelectItemClass = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      const oddsTypeOverList = [
        BettingOddsType.OU_OVER,
        BettingOddsType.FH_OU_OVER,
      ];
      if (selectedItem) {
        if (
          oddsTypeOverList.includes(selectedItem?.oddsType) ||
          (selectedItem?.isHome &&
            selectedItem?.adv === ADV_SELECTION.HOME_ADV) ||
          (!selectedItem?.isHome &&
            selectedItem?.adv === ADV_SELECTION.AWAY_ADV)
        ) {
          return 'text-red-500';
        }
      }
      return 'text-blue-600';
    };

    const onDeleteTicket = (matchId: number) => {
      emit('click:delete', matchId);
    };

    const getOddsValue = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      return formatNumber(selectedItem?.value ?? 0, true);
    };

    const getOldOddsValue = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      return formatNumber(selectedItem?.oldValue ?? 0, true);
    };
    const getTicketStatusIcon = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);

      if (selectedItem?.status === TICKET_STATUS.UP) {
        return '▲';
      } else if (selectedItem?.status === TICKET_STATUS.DOWN) {
        return '▼';
      }
      return '';
    };

    const getTicketStatusIconColor = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      if (selectedItem?.status === TICKET_STATUS.UP) {
        return 'text-green-500';
      } else if (selectedItem?.status === TICKET_STATUS.DOWN) {
        return 'text-red-500';
      }
      return '';
    };

    const getTicketBgClass = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      if (selectedItem?.status === TICKET_STATUS.UP) {
        return 'bg-yellow-odds text-green-500 px-0.5';
      } else if (selectedItem?.status === TICKET_STATUS.DOWN) {
        return 'bg-yellow-odds text-red-500 px-0.5';
      } else if (selectedItem?.status === TICKET_STATUS.DEL) {
        return 'bg-red-50 px-0.5';
      }
      return '';
    };

    const oddsErrorClass = (betItem: MatchSelected) => {
      return betItem.error?.errorCode === 'InvalidOdds'
        ? 'bg-odds-changed'
        : '';
    };

    const showOldOddsValue = (betItem: MatchSelected) => {
      return betItem.error?.errorCode === 'InvalidOdds';
    };

    const isShowOddsAnimation = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      if (
        selectedItem?.status === TICKET_STATUS.UP ||
        selectedItem?.status === TICKET_STATUS.DOWN
      ) {
        return true;
      }
      return false;
    };

    const isShowDelete = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      if (selectedItem?.status === TICKET_STATUS.DEL) {
        return true;
      }
      return false;
    };

    const getBetType = (matchId: number) => {
      const selectedItem = getSelectedItem(matchId);
      return selectedItem?.betType;
    };

    const isShowOdds = (matchId: number) => {
      const ignoreOddsTypeList = [
        BettingOddsType.FT_HOME,
        BettingOddsType.FT_DRAW,
        BettingOddsType.FT_AWAY,
        BettingOddsType.FH_FT_HOME,
        BettingOddsType.FH_FT_DRAW,
        BettingOddsType.FH_FT_AWAY,
        BettingOddsType.ODD,
        BettingOddsType.EVEN,
      ];
      const selectedItem = getSelectedItem(matchId);

      if (selectedItem) {
        return ignoreOddsTypeList.includes(selectedItem.oddsType);
      }
      return false;
    };

    const showError = (betItem: MatchSelected) => {
      return betItem.error && betItem.error?.errorCode !== 'InvalidOdds';
    };

    return {
      showOldOddsValue,
      getOldOddsValue,
      getOddsValue,
      getBetType,
      isShowDelete,
      isShowOddsAnimation,
      getTicketBgClass,
      getTicketStatusIconColor,
      getTicketStatusIcon,
      getSelectItemClass,
      getSelectedItem,
      getHdpFormat,
      onDeleteTicket,
      time,
      showError,
      oddsErrorClass,
      advColorClass,
      isShowOdds,
      locale,
      ADV_SELECTION,
    };
  },
});
</script>

<style scoped lang="scss">
@keyframes oddsChange {
  0% {
    background-color: #d6e8fe;
  }
  50% {
    @apply bg-yellow-200;
  }
  100% {
    background-color: #d6e8fe;
  }
}
.bg-odds-changed {
  animation: 1s infinite oddsChange;
}
</style>

<style lang="scss">
.btn-box-shadows {
  box-shadow: 1px 1px 1px 1px #aaa;
}
.ticket-block {
  .body {
    .bet-info.red {
      color: #e8000d;
    }
    .bet-info {
      color: #002bff;
    }
    .match {
      text-xs {
        color: #002bff;
        .red {
          text-decoration: underline;
        }
      }
    }
  }
  .head {
    background-color: #c1d4fc;
    .bet-type {
      text-decoration: underline;
    }
    .delete-ticket-icon {
      color: #be2121;
      &:hover {
        color: #005fe8;
      }
    }
  }
}
</style>
