<template>
  <bet-list-detail :list="statementState?.statementDetailList" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStatementStore } from '../../store/statement';
import BetListDetail from '@/components/Ticket/BetListDetail.vue';

export default defineComponent({
  name: 'ContainerStatementDetail',
  components: {
    BetListDetail,
  },
  setup() {
    const statementStore = useStatementStore();

    const statementState = computed(() => statementStore.$state);

    return { statementState };
  },
});
</script>

<style></style>
