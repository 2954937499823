<template>
  <table class="record-table">
    <thead class="sticky top-8">
      <tr>
        <th>{{ $t('numberTitle') }}</th>
        <th>{{ $t('info') }}</th>
        <th>{{ $t('detail') }}</th>
        <th>{{ $t('odds') }}</th>
        <th>{{ $t('stake') }}</th>
        <th>{{ $t('commission') }}</th>
        <th>{{ $t('winlose') }}</th>
        <th>{{ $t('status') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ticket, index) in list" :key="index">
        <td class="text-center">
          {{ index + 1 }}
        </td>
        <td class="text-center">
          <p class="font-bold">
            REF
            <span class="text-blue-700"># {{ ticket?.id }}</span>
          </p>
          <p class="ticket-time font-medium">
            {{ ticket?.time }}
          </p>
        </td>
        <td class="pb-3 pt-1">
          <div
            v-for="(parlay, indexParlay) in ticket?.parlay"
            :key="indexParlay"
            class="bet-details p-2 leading-relaxed border-b-1"
          >
            <p class="info font-bold">
              <span :class="colorSelection(parlay?.isAdv)" class="pr-1">
                {{ parlay?.selection?.[locale] }}
              </span>
              <mark class="bg-white p-0.5">
                <span>{{ formatNumber(parlay.oddsLine) }}</span>
                <span class="px-0.5">@</span>
                <span>{{ formatNumber(parlay.odds) }}</span>
              </mark>
            </p>
            <p class="font-bold text-green-600">
              {{ parlay?.betType }}
            </p>
            <p
              v-show="parlay?.home?.[locale] && parlay?.away?.[locale]"
              class="match"
            >
              <span
                :class="parlay?.adv === 1 ? 'text-red-500' : 'text-blue-700'"
                class="font-bold"
              >
                {{ parlay?.home?.[locale] }}
              </span>
              <span>-v-</span>
              <span
                :class="parlay?.adv === 2 ? 'text-red-500' : 'text-blue-700'"
                class="font-bold"
              >
                {{ parlay?.away?.[locale] }}
              </span>
            </p>
            <p class="league text-green-600 font-medium">
              {{ parlay?.league?.[locale] }}
            </p>
            <p class="time font-bold italic text-gray-600">
              {{ formatDate(parlay?.koTime) }}
            </p>
            <mark
              v-if="Number(parlay?.status) !== 0"
              class="result bg-yellow-300 text-black font-bold px-0.5"
            >
              <span v-html="parlay?.result?.text?.[locale]" />
              @ {{ parlay?.result?.score }}
            </mark>
          </div>
        </td>
        <td class="text-center font-medium">
          <span>{{ formatNumber(ticket?.totalOdds) }}</span>
        </td>
        <td class="text-center">
          <strong>{{ formatNumber(ticket?.stake) }}</strong>
        </td>
        <td class="text-center font-medium">
          <span>{{ formatNumber(ticket?.wlCommMember) }}</span>
        </td>
        <td class="text-center font-medium">
          <span>
            {{ ticket?.winlose ? formatNumber(ticket?.winlose) : '' }}
          </span>
        </td>
        <td class="text-center capitalize">
          <strong>{{ ticket?.active?.[locale] }}</strong>
        </td>
      </tr>
    </tbody>
    <tfoot class="bg-yellow-400">
      <tr>
        <th colspan="4">Total:</th>
        <th :class="Number(totalWinlose) < 0 && 'text-red-500'" colspan="4">
          {{ totalWinlose }}
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, defineComponent, type PropType } from 'vue';
import dayjs from 'dayjs';
import { useNumberUtils } from '../../composition/number-utils';

import type { Ticket } from '../../interfaces/api/Ticket.interface';

export default defineComponent({
  name: 'BetListDetail',
  props: {
    list: {
      type: Array as PropType<Ticket[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const { formatNumber } = useNumberUtils();

    const colorSelection = (isAdv: boolean) => {
      return isAdv ? 'text-red-500' : 'text-blue-700';
    };

    const formatDate = (unixTime: number) => {
      return dayjs(unixTime * 1000).format('DD-MM-YYYY HH:mm');
    };

    const totalWinlose = computed(() => {
      const amount = props.list?.reduce(
        (acc, item: Ticket) => acc + Number(item?.winlose),
        0
      );

      return formatNumber(Number(amount));
    });

    return {
      locale,
      totalWinlose,
      colorSelection,
      formatDate,
      formatNumber,
    };
  },
});
</script>
