<template>
  <div>
    <div class="flex items-center">
      <!-- <button class="mr-2" aria-label="Open Menu" @click="drawer">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          class="w-8 h-8"
        >
          <path d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button> -->
      <transition
        enter-class="opacity-0"
        enter-active-class="ease-out transition-medium"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-active-class="ease-out transition-medium"
        leave-to-class="opacity-0"
      >
        <div
          v-show="isOpen"
          class="z-10 fixed inset-0 transition-opacity"
          @keydown.esc="isOpen = false"
        >
          <div
            class="absolute inset-0 bg-black opacity-50"
            tabindex="0"
            @click="isOpen = false"
          />
        </div>
      </transition>
    </div>
    <aside
      class="transform top-0 right-0 w-64 bg--blue-gradient fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30"
      :class="isOpen ? 'translate-x-0' : 'translate-x-64'"
    >
      <span
        class="flex w-full items-center p-4 border-b"
        @click="isOpen = false"
      >
        <img
          src="@/assets/images/logo.webp"
          alt="Logo"
          class="h-auto w-56 mx-auto"
        />
      </span>
      <span
        v-for="menuItem in menuList"
        :key="menuItem.label"
        class="flex items-center p-4 hover:bg-blue-800 text-white font-bold"
        @click="isOpen = false"
      >
        <span>{{ $t(`${menuItem.label}`) }}</span>
      </span>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenu',
  data() {
    return {
      isOpen: false,
      menuList: [
        { label: 'home', path: '/' },
        { label: 'today', path: '/' },
        { label: 'earlyMarket', path: '/' },
        { label: 'games', path: '/' },
      ],
    };
  },
  methods: {
    drawer() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
