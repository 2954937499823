<template>
  <span>
    <slot :number="numberFormatted">Please define default number</slot>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { formatNumber } from '@/utils/number-format';

import type { Ref } from 'vue';

export default defineComponent({
  name: 'NumberFormat',
  props: {
    number: {
      type: Number || String,
      default: '',
    },
    decimal: {
      type: Number,
      default: 0,
    },
    isDecimal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const numberFormatted: Ref<string> = ref('');

    const getColor = computed(() => {
      if (props.number > 0) {
        return 'text-blue-900';
      }
      if (props.number < 0) {
        return 'text-red-600';
      }
      return '';
    });

    return { numberFormatted, getColor };
  },
  watch: {
    number: {
      immediate: true,
      handler(newNumber: number | string) {
        if (newNumber) {
          this.numberFormatted = formatNumber(newNumber, this.isDecimal);
        } else {
          this.numberFormatted = '0.00';
        }
      },
    },
  },
});
</script>
