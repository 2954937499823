<template>
  <main-body :title="t('resultTitle')" icon="poll">
    <container-result-table />
  </main-body>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainBody from '@/components/MainBody.vue';
import ContainerResultTable from '@/containers/Result/ContainerResultTable.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ResultPage',
  components: {
    MainBody,
    ContainerResultTable,
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
</script>
