<template>
  <ul class="">
    <li
      v-for="(menuItem, index) in menuList"
      :key="menuItem.label"
      :class="`${currentMenu === index && 'selected'}`"
      class="cursor-pointer"
      @click="onClickMenu(index)"
    >
      <div class="product-tab-left" />
      <div class="product-tab-body">
        <a href="">{{ $t(`${menuItem.label}`) }}</a>
        <div
          :class="`${
            currentMenu === index && 'common-header-bg selected-arrow'
          }`"
        />
      </div>
      <div class="product-tab-right" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavbarMenu',
  data() {
    return {
      currentMenu: 0,
      menuList: [
        { label: 'home', path: '/' },
        { label: 'today', path: '/' },
        { label: 'earlyMarket', path: '/' },
        { label: 'games', path: '/' },
      ],
    };
  },
  methods: {
    onClickMenu(index) {
      this.currentMenu = index;
    },
  },
};
</script>

<style scoped>
.product-list ul {
  float: right;
  position: relative;
  right: 0;
  top: 25px;
  margin: 0;
}
.product-list ul li {
  float: left;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  margin: 0 5px;
}
.product-list .logo-login {
  padding-top: 15px;
  margin-left: 20px;
  width: 165px;
  float: left;
}
.product-list .selected .product-tab-left {
  width: 9px;
  background: url(../../assets/images/common-header.png) 0 -173px;
}
.product-list .selected .product-tab-body {
  padding: 0 10px;
  color: #fff;
  background: url(../../assets/images/common-header.png) 0 -128px;
  position: relative;
}
.product-list .selected .product-tab-body a {
  color: #fff;
}
.product-list .selected .product-tab-right {
  width: 9px;
  background: url(../../assets/images/common-header.png) -10px -173px;
}
.product-list .selected-arrow {
  width: 9px;
  height: 7px;
  background-position: -7px -282px;
  position: absolute;
  left: 50%;
  margin: auto auto auto -4px;
  bottom: -2px;
}
.product-tab-body,
.product-tab-left,
.product-tab-right {
  display: inline-block;
  zoom: 1;
  float: left;
  height: 44px;
}
.product-tab-left {
  width: 9px;
}
.product-tab-body {
  padding: 0;
  color: #283e87;
  font-family: trebuchet ms, serif;
  position: relative;
  cursor: pointer;
}
.product-tab-body a {
  color: #00175f;
  position: relative;
  z-index: 50;
  text-decoration: none;
  line-height: 44px;
  display: inline-block;
}
.product-tab-body a:hover {
  color: #4871d3;
}
.product-tab-right {
  width: 9px;
}
.account-right li {
  list-style: none outside none;
  display: inline-block;
}
.account-right {
  font-family: Tahoma, Arail, sans-serif;
  font-size: 1.1em;
  display: inline-block;
  vertical-align: middle;
  float: right;
}
input {
  line-height: 1.2em;
  padding: 5px;
  border: none;
  border-radius: 3px;
  -ms-box-shadow: inset 1px 0 1px 1px #666;
  -o-box-shadow: inset 1px 0 1px 1px #666;
  box-shadow: inset 1px 0 1px 1px #666;
}

.common-header-bg {
  background-image: url(../../assets/images/common-header.png);
}
.product-list .selected-arrow {
  width: 9px;
  height: 7px;
  background-position: -7px -282px;
  position: absolute;
  left: 50%;
  margin: auto auto auto -4px;
  bottom: -2px;
}
</style>
