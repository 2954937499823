<template>
  <div class="date-selection">
    <fieldset class="p-1.5 mb-3 rounded border-2 border-blue-800">
      <legend class="p-1">
        <strong>{{ $t('chooseDate') }}</strong>
      </legend>
      <div class="flex items-center">
        <button
          :class="activeClass(isActiveYesterday)"
          class="ml-1 py-1 border border-gray-300 rounded-md shadow-md text-xs hover:bg-blue-600 hover:text-white w-20 font-medium"
          @click="onClickYesterday"
        >
          <font-awesome-icon
            v-if="isActiveYesterday && loadingStore.is('getResultList')"
            icon="fa-solid fa-spinner"
            class="animate-spin"
          />
          <span v-else>
            {{ $t('yesterday') }}
          </span>
        </button>
        <button
          :class="activeClass(isActiveToday)"
          class="mx-2 py-1 border border-gray-300 rounded-md shadow-md text-xs hover:bg-blue-600 hover:text-white w-14 font-medium"
          @click="onClickToday"
        >
          <font-awesome-icon
            v-if="isActiveToday && loadingStore.is('getResultList')"
            icon="fa-solid fa-spinner"
            class="animate-spin"
          />
          <span v-else>
            {{ $t('today') }}
          </span>
        </button>
        <vue-datepicker
          v-model="date"
          auto-apply
          :max-date="new Date()"
          :enable-time-picker="false"
          placeholder="Start Typing ..."
          text-input
          class="shadow-md"
          format="dd/MM/yyyy"
        />
        <button
          class="ml-2 bg-blue-800 py-1 border border-gray-300 rounded-md shadow-md text-white hover:bg-blue-700 w-20 font-medium"
          :disabled="loadingStore.is('getResultList')"
          @click="onSubmit"
        >
          <font-awesome-icon
            v-if="
              !isActiveYesterday &&
              !isActiveToday &&
              loadingStore.is('getResultList')
            "
            icon="fa-solid fa-spinner"
            class="animate-spin"
          />
          <span v-else>
            {{ $t('ok') }}
          </span>
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { computed, defineComponent, ref, watch } from 'vue';
import { useLoadingStore } from '../../store/wait';

export default defineComponent({
  name: 'FilterResult',
  emits: ['current-date'],
  setup(_, { emit }) {
    const loadingStore = useLoadingStore();
    const date = ref(
      dayjs().subtract(11, 'hour').format('YYYY-MM-DD HH:mm:ss')
    );

    const onClickToday = () => {
      date.value = dayjs().subtract(11, 'hour').format('YYYY-MM-DD HH:mm:ss');
      emit('current-date', date.value);
    };

    const onClickYesterday = () => {
      date.value = dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss');
      date.value = dayjs(date.value)
        .subtract(11, 'hour')
        .format('YYYY-MM-DD HH:mm:ss');

      emit('current-date', date.value);
    };

    watch(date, (value: string) => {
      date.value = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
    });

    const onSubmit = () => {
      emit('current-date', date.value);
    };

    const isActiveToday = computed(() => {
      const todaySubtractHour = dayjs()
        .subtract(11, 'hour')
        .format('YYYY-MM-DD');
      if (dayjs(date.value).format('YYYY-MM-DD') === todaySubtractHour) {
        return true;
      }
      return false;
    });

    const isActiveYesterday = computed(() => {
      const yesterday = dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss');
      const yesterdaySubtractHour = dayjs(yesterday)
        .subtract(11, 'hour')
        .format('YYYY-MM-DD');
      if (dayjs(date.value).format('YYYY-MM-DD') === yesterdaySubtractHour) {
        return true;
      }
      return false;
    });

    const activeClass = (isActive: boolean) => {
      if (isActive) {
        return 'bg-blue-800 text-white';
      }
      return 'bg-gray-100 text-black';
    };
    return {
      date,
      onClickToday,
      onClickYesterday,
      onSubmit,
      isActiveToday,
      isActiveYesterday,
      loadingStore,
      activeClass,
    };
  },
});
</script>

<style>
/* custom css date picker */
input.dp__input {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  box-shadow: none;
  font-size: 14px;
  cursor: pointer;
}

svg.dp__input_icons {
  padding: 6px;
}
</style>
