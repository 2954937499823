export const ticketList = [
  {
    id: '205146344',
    userId: '19',
    accDate: '2021-02-13',
    totalOdds: '4.95',
    stake: '100.00',
    active: {
      en: 'waiting',
      th: 'รอคอนเฟิร์ม',
    },
    comm: '10.00',
    isMarked: '0',
    username: '00770077aaa',
    parent: '18',
    time: '2021-02-13 17:30:38',
    step: 3,
    cancel: false,
    totalMember: 90,
    winLose: null,
    parlay: [
      {
        home: {
          en: 'Leicester City',
          th: 'เลสเตอร์ ซิตี้',
        },
        away: {
          en: 'Liverpool',
          th: 'ลิเวอร์พูล',
        },
        league: {
          en: '*ENGLISH PREMIER LEAGUE',
          th: 'พรีเมียร์ลีก อังกฤษ',
        },
        selection: {
          en: 'Liverpool',
          th: 'ลิเวอร์พูล',
        },
        adv: '2',
        advSelection: '1',
        koTime: '1613219400',
        result: {
          text: {
            en: 'Lose',
            th: 'แพ้',
          },
          score: 'HT 0:0 FT 3:1',
        },
        odds: '1.72',
        matchId: '41406260',
        oddsLine: '-0.25',
        oddsType: 'hdp_away',
        betType: 'HDP',
        status: '-100',
      },
      {
        home: {
          en: 'Manchester City',
          th: 'แมนเชสเตอร์ ซิตี้',
        },
        away: {
          en: 'Tottenham Hotspur',
          th: 'ท็อตแนม ฮ็อตสเปอร์ส',
        },
        league: {
          en: '*ENGLISH PREMIER LEAGUE',
          th: 'พรีเมียร์ลีก อังกฤษ',
        },
        selection: {
          en: 'Manchester City',
          th: 'แมนเชสเตอร์ ซิตี้',
        },
        adv: '1',
        advSelection: '1',
        koTime: '1613237400',
        result: {
          text: {
            en: 'Won',
            th: 'ชนะ',
          },
          score: 'HT 1:0 FT 3:0',
        },
        odds: '1.60',
        matchId: '41406262',
        oddsLine: '-1.25',
        oddsType: 'hdp_home',
        betType: 'HDP',
        status: '4',
      },
    ],
  },
  {
    id: '156192595',
    userId: '19',
    accDate: '2020-01-25',
    totalOdds: '1.90',
    stake: '10.00',
    active: {
      en: 'waiting',
      th: 'รอคอนเฟิร์ม',
    },
    comm: '0.20',
    isMarked: '0',
    username: '00770077aaa',
    parent: '18',
    time: '2020-01-25 16:02:35',
    step: 1,
    cancel: false,
    totalMember: 9.8,
    winLose: null,
    parlay: [
      {
        home: {
          en: 'Brentford',
          th: 'เเบรนท์ฟอร์ด',
        },
        away: {
          en: 'Leicester City',
          th: 'เลสเตอร์ ซิตี้',
        },
        league: {
          en: 'ENGLISH FA CUP',
          th: 'เอฟเอคัพ อังกฤษ',
        },
        selection: {
          en: 'Leicester City',
          th: 'เลสเตอร์ ซิตี้',
        },
        adv: '2',
        advSelection: '1',
        koTime: '1579956300',
        result: {
          text: {
            en: 'Won &#189',
            th: 'ชนะ &#189',
          },
          score: 'HT 0:1 FT 0:1',
        },
        odds: '1.90',
        matchId: '34254313',
        oddsLine: '-0.75',
        oddsType: 'hdp_away',
        betType: 'HDP',
        status: '3',
      },
    ],
  },
];
