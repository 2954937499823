<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <VueDialog as="div" class="relative z-10" @close="onCloseModal">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex justify-center border-b-2 border-yellow-400 pb-3"
                :class="{ '!border-green-600': isSuccess }"
              >
                <font-awesome-icon
                  v-show="!isSuccess"
                  icon="fa-solid fa-triangle-exclamation"
                  class="text-yellow-400 text-2xl"
                />
                <font-awesome-icon
                  v-show="isSuccess"
                  icon="fa-solid fa-circle-check"
                  class="text-green-600 text-2xl"
                />
                <span class="pl-2">
                  {{ isSuccess ? 'สำเร็จ' : 'เกิดข้อผิดพลาด' }}
                </span>
              </DialogTitle>
              <div class="mt-4 text-center">
                <p class="text-sm text-gray-500 font-medium">
                  {{ message }}
                </p>
              </div>

              <div class="mt-4 flex justify-center">
                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  @click="onCloseModal"
                >
                  {{ $t('ok') }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </VueDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  TransitionRoot,
  TransitionChild,
  Dialog as VueDialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';

import type { PropType } from 'vue';

export default defineComponent({
  components: {
    VueDialog,
    DialogPanel,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  },
  props: {
    isOpen: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isSuccess: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    message: {
      type: String as PropType<string>,
      default:
        'Your payment has been successfully submitted. We’ve sent you an email with all of the details of your order',
    },
  },
  emits: ['close-notify-modal'],
  setup(_, { emit }) {
    const onCloseModal = () => {
      emit('close-notify-modal');
    };

    return { onCloseModal };
  },
});
</script>
