<template>
  <div
    class="py-12 px-3 text-center text-sm not-found__bg-gradient text-blue-900"
  >
    {{ label || t('matchesNotEnoughForBet') }}
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NotFoundCard',
  props: {
    label: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
</script>

<style>
.not-found__bg-gradient {
  background-image: linear-gradient(#c3d3fc, #ecf1fe, #c4d4fc);
}
</style>
