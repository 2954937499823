<template>
  <div class="header-bottom-link font-medium">
    <div class="header-bottom-left">
      <language-switch />
      <div class="flex items-center">
        <span class="system-time ml-1 sm:ml-2">
          <!-- <span>{{ $dayjs().format('dddd DD MMMM BBBB HH:mm:ss') }}</span> -->
        </span>
      </div>
    </div>
    <div class="announcement hidden sm:block">
      <div class="marquee">
        <div class="text-xs animate-marquee">
          <span v-html="contentRendered" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from '@/components/Head/LanguageSwitch.vue';

export default {
  name: 'HeaderBottomLink',
  components: { LanguageSwitch },
  data() {
    return {
      isOpen: false,
      currentLanguage: { label: 'ภาษาไทย', value: 'th' },
      languageList: [
        { label: 'ภาษาไทย', value: 'th' },
        { label: 'English', value: 'en' },
      ],
      wpContent: [],
    };
  },
  async fetch() {
    this.wpContent = await this.$wp.pages().slug('marquee');
  },
  computed: {
    wpItem() {
      if (this.wpContent?.length > 0) {
        return this.wpContent[0];
      }
      return null;
    },
    contentRendered() {
      return (
        this.wpItem?.content.rendered ||
        ' League88 เว็ปบอลสเต็ปที่ดีที่สุด ยินดีต้อนรับค่ะ'
      );
    },
  },
  mounted() {
    const localStorageLang = localStorage.getItem('lang');
    if (localStorageLang) {
      this.$root.$i18n.locale = localStorageLang;
    } else {
      localStorage.setItem('lang', this.$root.$i18n.locale);
    }

    const findLangItem = this.languageList.find(
      (item) => item.value === this.$root.$i18n.locale
    );
    this.currentLanguage = findLangItem;
  },
  methods: {
    onClickLanguageBtn() {
      this.isOpen = true;
    },
    onChangeLanguage(languageItem) {
      this.isOpen = false;
      this.currentLanguage = languageItem;
      this.$root.$i18n.locale = languageItem.value;
      localStorage.setItem('lang', this.$root.$i18n.locale);
    },
  },
};
</script>

<style scoped>
.header-bottom-link {
  position: relative;
  z-index: 1;
}
.announcement {
  position: absolute;
  top: 0;
  width: 656px;
  left: 324px;
  overflow: hidden;
  z-index: 1000;
}
.lang-selection {
  display: inline-block;
  float: left;
  font-weight: 700;
  color: #274ea0;
  line-height: 18px;
  cursor: pointer;
}
.lang-selection .lang-drop-down {
  float: left;
  position: relative;
  white-space: nowrap;
}
.lang-selection .lang-drop-down .lang-img.th {
  background: url(../../assets/images/flag-th.png);
}
.lang-selection .lang-drop-down .lang-img.en {
  background: url(../../assets/images/flag-en.png);
}
.lang-selection .lang-drop-down .lang-img {
  display: inline-block;
  zoom: 1;
  width: 16px;
  height: 16px;
  margin: 1px 3px 0 6px;
  background-repeat: no-repeat;
}
.lang-selection .lang-drop-down span {
  float: left;
}
.lang-selection .lang-drop-down .lang-divider {
  display: inline-block;
  zoom: 1;
  width: 17px;
  height: 15px;
  margin-left: 3px;
  margin-top: 1px;
  background: url(../../assets/images/global-setting-icon.png) no-repeat right -627px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul {
  width: 110px;
  position: absolute;
  margin-top: 20px;
  border: 1px solid #deebfd;
  border-top: none;
  background-color: #fff;
  left: -1px;
  box-shadow: 1px 1px 2px #17275f;
  -webkit-box-shadow: 1px 1px 2px #17275f;
  -moz-box-shadow: 1px 1px 2px #17275f;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li {
  color: #22377b;
  border-top: 1px solid #d2e7ff;
  height: 100%;
  white-space: nowrap;
  width: 110px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a {
  display: block;
  padding: 4px 24px;
  white-space: nowrap;
  color: #274ea0;
  text-decoration: none;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.th {
  background: url(../../assets/images/flag-th.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.en {
  background: url(../../assets/images/flag-en.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li:hover {
  background-color: #d2e7ff;
  border-top: 1px solid #d2e7ff;
}
.system-time {
  font-size: 11px;
  font-weight: 700;
  color: #444;
}
.lang-selection {
  display: inline-block;
  float: left;
  font-weight: 700;
  color: #274ea0;
  line-height: 18px;
  cursor: pointer;
}
.lang-selection .lang-drop-down {
  float: left;
  position: relative;
  white-space: nowrap;
}
.lang-selection .lang-drop-down .lang-img.th {
  background: url(../../assets/images/flag-th.png);
}
.lang-selection .lang-drop-down .lang-img {
  display: inline-block;
  zoom: 1;
  width: 16px;
  height: 16px;
  margin: 1px 3px 0 6px;
  background-repeat: no-repeat;
}
.lang-selection .lang-drop-down span {
  float: left;
}
.lang-selection .lang-drop-down .lang-divider {
  display: inline-block;
  zoom: 1;
  width: 17px;
  height: 15px;
  margin-left: 3px;
  margin-top: 1px;
  background: url(../../assets/images/global-setting-icon.png) no-repeat right -627px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul {
  width: 110px;
  position: absolute;
  margin-top: 20px;
  border: 1px solid #deebfd;
  border-top: none;
  background-color: #fff;
  left: -1px;
  box-shadow: 1px 1px 2px #17275f;
  -webkit-box-shadow: 1px 1px 2px #17275f;
  -moz-box-shadow: 1px 1px 2px #17275f;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li {
  color: #22377b;
  border-top: 1px solid #d2e7ff;
  height: 100%;
  white-space: nowrap;
  width: 110px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a {
  display: block;
  padding: 4px 24px;
  white-space: nowrap;
  color: #274ea0;
  text-decoration: none;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.th {
  background: url(../../assets/images/flag-th.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.en {
  background: url(../../assets/images/flag-en.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li:hover {
  background-color: #d2e7ff;
  border-top: 1px solid #d2e7ff;
}
</style>
