<template>
  <nav class="blue-bar-inner sm:px-6">
    <login-container class="hidden sm:block" />
  </nav>
</template>

<script>
import LoginContainer from '@/containers/LoginContainer.vue';

export default {
  name: 'NavbarTitle',
  components: {
    LoginContainer,
  },
};
</script>

<style scoped>
.blue-bar-inner {
  background: rgb(53, 89, 139);
  background: linear-gradient(
    180deg,
    rgba(53, 89, 139, 1) 0%,
    rgba(34, 57, 106, 1) 100%
  );
  width: 987px;
}
.fix-height-nev {
  height: 40px;
}
@media (max-width: 639px) {
  .blue-bar-inner {
    width: auto;
  }
}
</style>
