<template>
  <form name="changePassword" @submit.prevent="onChangePassword">
    <table class="change-password-table w-full shadow-md border-solid border-1">
      <thead>
        <tr>
          <th colspan="2" class="py-1 text-white">
            {{ $t('changePassword') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="current-password text-right pr-1">
            {{ $t('currentPassword') }}
          </td>
          <td class="pl-3 py-1">
            <input
              v-model="currentPassword"
              class="p-1 rounded shadow-none border border-blue-500 mr-2"
              :type="showPassword ? 'text' : 'password'"
              :placeholder="$t('currentPassword')"
              :disabled="loading"
            />
            <font-awesome-icon
              :icon="
                showPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'
              "
              class="cursor-pointer"
              @click="showPassword = !showPassword"
            />
          </td>
        </tr>
        <tr>
          <td class="new-password text-right pr-1">
            {{ $t('newPassword') }}
          </td>
          <td class="pl-3 py-1">
            <input
              v-model="newPassword"
              class="p-1 rounded shadow-none border border-blue-500 mr-2"
              :type="showVerifiedPassword ? 'text' : 'password'"
              name="newPassword"
              :placeholder="$t('newPassword')"
              :disabled="loading"
              :class="{ 'border-red-500': !isVerifiedPassword }"
            />
            <font-awesome-icon
              :icon="
                showVerifiedPassword
                  ? 'fa-regular fa-eye'
                  : 'fa-regular fa-eye-slash'
              "
              class="cursor-pointer"
              @click="showVerifiedPassword = !showVerifiedPassword"
            />
          </td>
        </tr>
        <tr>
          <td class="verify-password text-right pr-1">
            {{ $t('verifyPassword') }}
          </td>
          <td class="pl-3 py-1">
            <input
              v-model="verifyPassword"
              class="p-1 rounded shadow-none border border-blue-500"
              :type="showVerifiedPassword ? 'text' : 'password'"
              name="verifyPassword"
              :placeholder="$t('verifyPassword')"
              :disabled="loading"
              :class="{ 'border-red-500': !isVerifiedPassword }"
              @change="onVerifiedPassword"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2" class="pl-48 py-1">
            <div v-show="successMessage" class="text-green-600 pb-1">
              {{ successMessage }}
            </div>
            <div v-show="errorMessage" class="text-red-500 pb-1">
              {{ errorMessage }}
            </div>
            <button
              type="submit"
              class="btn bg-amber-600 hover:bg-amber-700 disabled:opacity-50 disabled:pointer-events-none shadow-sm drop-shadow-sm text-white font-bold px-3 py-1 rounded"
              :disabled="loading"
            >
              {{ $t('submit') }}
              <font-awesome-icon
                v-if="loading"
                icon="fa-solid fa-spinner"
                class="animate-spin ml-1"
              />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</template>

<script lang="ts" setup>
import { computed, ref, onUnmounted } from 'vue';
import { useLoadingStore } from '../../store/wait';
import { useModalStore } from '../../store/modal';
import type { ChangePasswordInput } from '../../store/member';
import { apiFetch } from '../../composition/useAxios';

const loadingStore = useLoadingStore();
const modalStore = useModalStore();

const currentPassword = ref<string | null>(null);
const newPassword = ref<string | null>(null);
const verifyPassword = ref<string | null>(null);
const showPassword = ref<boolean>(false);
const showVerifiedPassword = ref<boolean>(false);
const isVerifiedPassword = ref<boolean>(true);
const errorMessage = ref<string | null>(null);
const successMessage = ref<string | null>(null);

const loading = computed(() => loadingStore.is('changePasswordLoading'));

function onVerifiedPassword() {
  if (verifyPassword.value !== newPassword.value) {
    isVerifiedPassword.value = false;
  } else {
    isVerifiedPassword.value = true;
  }
}

function onChangePassword() {
  onVerifiedPassword();
  if (currentPassword.value && newPassword.value && isVerifiedPassword.value) {
    const input: ChangePasswordInput = {
      oldPassword: currentPassword.value,
      newPassword: newPassword.value,
    };
    changePassword(input);
  }
}

async function changePassword(input: ChangePasswordInput) {
  loadingStore.start('changePasswordLoading');
  const lang = localStorage.getItem('lang') || 'th';
  try {
    const { data, error } = await apiFetch('/change-password ', {
      headers: {
        'Accept-Language': lang,
      },
      method: 'POST',
      data: input,
    });
    if (data?.value?.success) {
      currentPassword.value = null;
      newPassword.value = null;
      verifyPassword.value = null;
      errorMessage.value = null;
      errorMessage.value = null;
      successMessage.value = 'เปลี่ยนรหัสผ่านสำเร็จ';
    }
    if (error.value?.response) {
      successMessage.value = null;
      errorMessage.value = error.value?.response?.data.errorMessage;
    }
    if (error.value?.response?.status === 401) {
      modalStore.message =
        lang === 'th' ? 'เซสชั่นไม่ถูกต้องหรือหมดอายุ' : 'Session expired';
      modalStore.isShow = true;
      modalStore.isSessionExpired = true;
    }
  } catch (error) {
    errorMessage.value = 'เกิดข้อผิดพลาด ไม่สามารถดำเนินการได้';
  }
  loadingStore.end('changePasswordLoading');
}

onUnmounted(() => {
  currentPassword.value = null;
  newPassword.value = null;
  verifyPassword.value = null;
  errorMessage.value = null;
});
</script>

<style lang="scss">
.change-password-table {
  td {
    border-right: 1px solid #b0c6ff;
    border-bottom: 1px solid #b0c6ff;
    border-left-width: 0;
    border-top-width: 0;
    &:nth-child(1) {
      width: 30%;
    }
  }
  th {
    border-right: 1px solid #b0c6ff;
    border-bottom: 1px solid #b0c6ff;
    border-left-width: 0;
    border-top-width: 0;
  }
  thead {
    background-color: #4c69b8;
  }
}
.btn-blue {
  background-color: #375aab;
  box-shadow: 1px 1px 1px 1px #aaa;
}
</style>
