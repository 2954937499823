<template>
  <div>
    <div class="single-tab font-bold cursor-pointer flex">
      <div class="tab-bg tab-left" />
      <div class="text-xs one-px-bg tab-body text-white text-center block">
        {{ $t('account') }}
      </div>
      <div class="tab-bg tab-right" />
    </div>
    <div class="selection-body fav-body text-center">
      <font-awesome-icon
        v-show="loading"
        icon="fa-solid fa-spinner"
        class="animate-spin my-4"
      />
      <table
        v-show="!loading"
        class="table-bordered flex justify-center font-medium"
      >
        <tbody class="text-xs">
          <tr>
            <td class="text-right px-1">{{ t('username') }}:</td>
            <td class="text-left px-1">
              {{ memberState?.member?.username }}
            </td>
          </tr>
          <tr>
            <td class="text-right px-1">{{ t('outstanding') }}:</td>
            <td class="text-left px-1">
              {{ outstandingFormatted }}
            </td>
          </tr>
          <tr>
            <td class="text-right px-1">{{ t('betCredit') }}:</td>
            <td class="text-blue-700 font-bold text-left px-1">
              {{ balanceFormatted }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNumberUtils } from '../composition/number-utils';
import { useMemberStore } from '../store/member';
import { useLoadingStore } from '../store/wait';

const { t } = useI18n();
const memberStore = useMemberStore();
const loadingStore = useLoadingStore();
const { formatNumber } = useNumberUtils();

const memberState = computed(() => memberStore.$state);
const loading = computed(() => loadingStore.is('getMemberLoading'));

const balanceFormatted: ComputedRef<string> = computed(() => {
  if (memberState.value.member?.balance) {
    return formatNumber(memberState.value.member?.balance);
  }
  return '0';
});

const outstandingFormatted: ComputedRef<string> = computed(() => {
  if (memberState.value.member?.balance) {
    return formatNumber(memberState.value.member.outstanding);
  }
  return '0';
});
</script>

<style>
.one-px-bg {
  background-image: url(../assets/images/one-px-bg.png);
}
.tab-bg {
  background-image: url(../assets/images/header-tab-bg.png);
}
.single-tab {
  font-family: trebuchet ms, Arial, Helvetica, sans-serif;
  height: 30px;
}
.single-tab .tab-body {
  width: 89.4%;
  height: 30px;
  line-height: 30px;
}
.single-tab .tab-bg.tab-right {
  background-position: -3px 0;
}
.single-tab .tab-bg.tab-left,
.single-tab .tab-bg.tab-right {
  width: 10px;
  height: 30px;
}
.selection-body {
  background-color: #d6e8fe;
  border: 1px solid #f4f9ff;
  min-height: 5px;
}
</style>
