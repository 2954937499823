import dayjs from 'dayjs';
import { computed } from 'vue';
import { useBettingStore } from '../store/betting';

import type { League } from '../interfaces/League.interface';
import type { MatchSelected } from '../interfaces/Ticket.interface';

export function useLeagueDisplay() {
  const isShowLeague = (currentLeague: string) => {
    const bettingStore = useBettingStore();
    const bettingState = computed(() => bettingStore.$state);
    if (bettingState.value?.filterLeagueList?.length) {
      const findExistLeague = bettingState.value?.filterLeagueList?.find(
        (league: League): boolean => league?.en === currentLeague
      );

      if (findExistLeague) {
        return true; // show league
      }
      return false; // hidden league
    } else {
      return true; // show league
    }
  };

  const isShowTeamByTime = (
    matchItem: MatchSelected,
    isBettingTable: boolean,
    timeNow: number | null
  ) => {
    const koTime = dayjs(matchItem.koTime);
    const currentTime = dayjs(timeNow);
    const currentTimeSubtract30second = currentTime.add(2, 'minute');
    const currentTimeSubtract2minute = currentTime.add(5, 'minute');
    const minuteUnixTime = koTime.diff(currentTimeSubtract2minute);
    const secondUnixTime = koTime.diff(currentTimeSubtract30second);

    if (isBettingTable && minuteUnixTime <= 0) {
      return false; // hidden match
    } else if (secondUnixTime <= 0) {
      return false; // hidden match
    } else {
      return true; // show match
    }
  };

  return {
    isShowLeague,
    isShowTeamByTime,
  };
}
