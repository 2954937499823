import type { Module } from 'vuex';
import type { RootState } from '@/vuex-store/RootState.interface';
import state from './state';
import mutations from './mutations';
import type { ProfileState } from './ProfileState.interface';

const profileModule: Module<ProfileState, RootState> = {
  namespaced: true,
  state,
  mutations,
};

export default profileModule;
