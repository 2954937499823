<template>
  <div class="relative z-10">
    <div class="flex">
      <language-switch class="w-28" />
      <div class="wrap-date flex justify-between items-center w-full mr-6">
        <div class="font-semibold font-size-11">
          {{ currentDate }}
        </div>
        <div class="max-payout font-bold font-size-13">
          {{ $t('maxPayout') }} :
          {{ formatNumber(Number(memberState.member?.maxPayout), '0,0') }} THB
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMemberStore } from '../../store/member';
import { useNumberUtils } from '../../composition/number-utils';
import LanguageSwitch from '@/components/Head/LanguageSwitch.vue';
import dayjs from 'dayjs';

import type { Ref } from 'vue';
import type { Language } from '@/interfaces/Language.interface';

export default defineComponent({
  name: 'MenuInfo',
  components: { LanguageSwitch },
  setup(this: void) {
    const { locale } = useI18n();
    const memberStore = useMemberStore();
    const { formatNumber } = useNumberUtils();

    const currentDate = ref<string>('');

    const memberState = computed(() => memberStore.$state);
    const isOpen: Ref<boolean> = ref(false);
    const currentLanguage: Ref<Language> = ref({
      label: 'ภาษาไทย',
      value: 'th',
    });
    const languageList: Ref<Language[]> = ref([
      { label: 'ภาษาไทย', value: 'th' },
      { label: 'English', value: 'en' },
    ]);

    const onClickLanguageBtn = () => {
      isOpen.value = true;
    };

    const onChangeLanguage = (languageItem: Language) => {
      isOpen.value = false;
      currentLanguage.value = languageItem;
      locale.value = languageItem.value;
    };

    onMounted(() => {
      setInterval(() => {
        currentDate.value = dayjs()
          .locale(locale.value)
          .format('dddd, MMMM D, YYYY HH:mm:ss');
      }, 1000);
    });

    return {
      isOpen,
      currentDate,
      currentLanguage,
      languageList,
      memberState,
      onClickLanguageBtn,
      onChangeLanguage,
      formatNumber,
    };
  },
});
</script>

<style>
.font-size-11 {
  font-size: 11px;
}
.font-size-13 {
  font-size: 13px;
}
.lang-selection {
  color: #274ea0;
  line-height: 18px;
}
.lang-selection .lang-drop-down .lang-img.th {
  background: url(../../assets/images/th.png);
}
.lang-selection .lang-drop-down .lang-img.en {
  background: url(../../assets/images/en.png);
}

.lang-selection .lang-drop-down .lang-img {
  width: 16px;
  height: 16px;
  margin: 1px 3px 0 6px;
}
.lang-selection .lang-drop-down span {
  float: left;
}
.lang-selection .lang-drop-down .lang-divider {
  width: 17px;
  height: 15px;
  margin-top: 1px;
  background: url(../../assets/images/global-setting-icon.png) no-repeat right -627px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul {
  width: 110px;
  border: 1px solid #deebfd;
  background-color: #fff;
  left: -1px;
  box-shadow: 1px 1px 2px #17275f;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li {
  color: #22377b;
  border-top: 1px solid #d2e7ff;
  height: 100%;
  width: 110px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a {
  padding: 4px 24px;
  color: #274ea0;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.th {
  background: url(../../assets/images/th.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.en {
  background: url(../../assets/images/en.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li:hover {
  background-color: #d2e7ff;
  border-top: 1px solid #d2e7ff;
}
.lang-selection .lang-drop-down .lang-img.th {
  background: url(../../assets/images/th.png);
}
.lang-selection .lang-drop-down span {
  float: left;
}
.lang-selection .lang-drop-down .lang-divider {
  display: inline-block;
  zoom: 1;
  width: 17px;
  height: 15px;
  margin-left: 3px;
  margin-top: 1px;
  background: url(../../assets/images/global-setting-icon.png) no-repeat right -627px;
}
</style>
