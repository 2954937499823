<template>
  <first-haft-table
    v-if="hasFhHdpList"
    :list="bettingState.fhHdpList"
    @click:match="onClickMatch"
    @click:odds="onClickOdds"
  />
  <not-found-card v-else />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import FirstHaftTable from '@/components/Betting/FirstHaftTable.vue';
import { useSetTicket } from '@/composition/set-ticket';
import NotFoundCard from '@/components/NotFoundCard.vue';
import { useBettingStore } from '../store/betting';
import { OddsType } from '../constants/OddsType.enum';

import type { ComputedRef } from 'vue';
import type { TeamSelected } from '@/interfaces/TeamSelected.interface';
import type { MatchSelected } from '../interfaces/Ticket.interface';

export default defineComponent({
  name: 'ContainerFirstHalfTable',
  components: {
    FirstHaftTable,
    NotFoundCard,
  },
  setup() {
    const bettingStore = useBettingStore();
    const bettingState = computed(() => bettingStore.$state);

    onMounted(async () => {
      await bettingStore.getOddsList(OddsType.FH);
    });

    const hasFhHdpList: ComputedRef<boolean> = computed(
      () => bettingState.value?.fhHdpList?.length > 0
    );

    const { useSetTicketInputList, useSetSelectedTicketInputList } =
      useSetTicket();

    const onClickMatch = (matchItem: MatchSelected) => {
      return useSetTicketInputList(matchItem);
    };

    const onClickOdds = (newOddsItem: TeamSelected) => {
      return useSetSelectedTicketInputList(newOddsItem);
    };

    return {
      bettingState,
      onClickMatch,
      onClickOdds,
      hasFhHdpList,
    };
  },
});
</script>

<style></style>
