import type { BettingState } from '@/vuex-store/betting/BettingState.interface';
import type { FbMatch } from '@/interfaces/FbMatch.interface';
import type { FhHdpMatch } from '@/interfaces/FhHdpMatch.interface';
import type { HdaMatch } from '@/interfaces/HdaMatch.interface';
import type { HdpMatch } from '@/interfaces/HdpMatch.interface';
import type { Market } from '@/interfaces/Market.interface';

export default {
  setCurrentBettingTable(state: BettingState, currentBettingTable: string) {
    state.currentBettingTable = currentBettingTable;
  },
  setTicketList(state: BettingState, ticketList: unknown) {
    state.ticketList = ticketList;
  },
  setSelectedTeamList(state: BettingState, selectedTeamList: unknown) {
    state.selectedTeamList = selectedTeamList;
  },
  setFtHdpOddsList(state: BettingState, ftHdpList: HdpMatch[]) {
    state.ftHdpList = [...ftHdpList];
  },
  setFhHdpOddsList(state: BettingState, fhHdpList: FhHdpMatch[]) {
    state.fhHdpList = [...fhHdpList];
  },
  setHdaOddsList(state: BettingState, hdaList: HdaMatch[]) {
    state.hdaList = [...hdaList];
  },
  setFullBetOddsList(state: BettingState, fullBetList: FbMatch[]) {
    state.fullBetList = [...fullBetList];
  },
  setOddsMarket(state: BettingState, marketItem: Market) {
    state.market = { ...marketItem };
  },
  setHdp(state: BettingState, hdpType: number) {
    state.hdpType = hdpType;
  },
  setHdpOddsLive(state: BettingState, hdpLiveList: HdpMatch[]) {
    state.hdpLiveList = [...hdpLiveList];
  },
};
