import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router';
import Home from '../pages/Home.vue';
import Login from '../pages/Login.vue';
import LayoutPrivate from '@/components/Layouts/LayoutPrivate.vue';
import LayoutPublic from '@/components/Layouts/LayoutPublic.vue';
import Statement from '@/pages/Statement.vue';
import Result from '@/pages/Result.vue';
import BetList from '@/pages/BetList.vue';
import Profile from '@/pages/Profile.vue';
import Rules from '@/pages/Rules.vue';
import TicketSlip from '@/pages/TicketSlip.vue';
import ExternalLogin from '@/pages/ExternalLogin.vue';
import HelpCheck from '@/pages/HelpCheck.vue';
import { useAuthStore } from '../store/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: LayoutPublic,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { isLogin: true },
      },
    ],
  },
  {
    path: '/external-login',
    name: 'externalLogin',
    component: ExternalLogin,
    meta: { isPublic: true },
  },
  {
    path: '/slip/:ticketId/:createdAt',
    name: 'ticketSlip',
    component: TicketSlip,
    meta: { isPublic: true },
  },
  {
    path: '/',
    component: LayoutPrivate,
    children: [
      {
        path: '/',
        name: 'main',
        component: Home,
      },
      {
        path: '/bet-list',
        name: 'betList',
        component: BetList,
      },
      {
        path: '/statement',
        name: 'statement',
        component: Statement,
      },
      {
        path: '/result',
        name: 'result',
        component: Result,
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile,
      },
    ],
  },
  {
    path: '/rules',
    name: 'rules',
    component: Rules,
  },
  {
    path: '/help-check',
    name: 'help-check',
    component: HelpCheck,
    meta: { isPublic: true },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const token = localStorage.getItem('APP_TOKEN');
  const isPublic = to.meta?.isPublic || false;
  const isLogin = to.meta?.isLogin || false;
  authStore.isLoggedIn = !!token;

  if (!token && !isLogin && !isPublic) {
    next('/login');
  }

  if (isPublic) {
    return next();
  }
  if (token && isLogin) {
    return next('/');
  } else {
    return next();
  }
});

export default router;
