<template>
  <div>
    <div>
      <table class="odds-details odds-live w-full">
        <thead>
          <tr class="orange-bg">
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
              rowspan="2"
            >
              {{ $t('time') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
              colspan="2"
              rowspan="2"
            >
              {{ $t('event') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
              colspan="6"
            >
              {{ $t(bettingTableStateKey[currentTable]) }}
            </th>
          </tr>
          <tr class="orange-bg">
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('hdp') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('home') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('away') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('goal') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('over') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('under') }}
            </th>
          </tr>
        </thead>
        <tbody v-for="(listItem, index) in list" :key="index">
          <tr class="orange-bg">
            <td
              colspan="4"
              class="league-name uppercase text-white text-left font-bold"
            >
              {{ listItem?.league?.[locale] }}
            </td>
            <td
              colspan="5"
              class="league-name uppercase text-white text-left font-bold"
            />
          </tr>
          <tr
            v-for="(item, indexItem) in listItem?.record"
            :key="indexItem"
            class="odds-group orange-bg"
          >
            <td class="orange-bg">
              <div class="text-red-500 leading-3">HT</div>
              <div class="leading-3">
                {{
                  `${Number(item?.scoreHomeHt)}:${Number(item?.scoreAwayHt)}`
                }}
              </div>
            </td>
            <td class="orange-bg">
              <span :class="advColorClass(ADV_SELECTION.HOME_ADV, item?.adv)">
                {{ item?.home?.[locale] }}
              </span>
            </td>
            <td class="orange-bg">
              <span :class="advColorClass(ADV_SELECTION.AWAY_ADV, item?.adv)">
                {{ item?.away?.[locale] }}
              </span>
            </td>
            <td class="odds-line">
              <span v-html="hdpFormat(item?.hdp)" />
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ht hdp',
                    oddsType: bettingOddsType.HDP_HOME,
                    value: item?.hdpHome,
                    oldValue: item?.hdpHome,
                    label: `HT (${Number(item?.scoreHomeHt)}:${Number(
                      item?.scoreAwayHt
                    )}) ${item?.home?.[locale]}`,
                    odds: item?.hdp,
                    adv: item?.adv,
                    isHome: true,
                    oddsLine: item?.hdpOddsLine,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.hdpHome) }}
                </a>
              </span>
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ht hdp',
                    oddsType: bettingOddsType.HDP_AWAY,
                    value: item?.hdpAway,
                    oldValue: item?.hdpAway,
                    label: `HT (${Number(item?.scoreHomeHt)}:${Number(
                      item?.scoreAwayHt
                    )}) ${item?.away?.[locale]}`,
                    odds: item?.hdp,
                    adv: item?.adv,
                    isHome: false,
                    oddsLine: item?.hdpOddsLine,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.hdpAway) }}
                </a>
              </span>
            </td>
            <td class="odds-line">
              <span v-html="hdpFormat(item?.ou)" />
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ht ou',
                    oddsType: bettingOddsType.OU_OVER,
                    value: item?.ouOver,
                    oldValue: item?.ouOver,
                    label: `${t('over')}`,
                    odds: item?.ou,
                    adv: item?.adv,
                    oddsLine: item?.ouOddsLine,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.ouOver) }}
                </a>
              </span>
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ht ou',
                    oddsType: bettingOddsType.OU_UNDER,
                    value: item?.ouUnder,
                    oldValue: item?.ouUnder,
                    label: `${t('under')}`,
                    odds: item?.ou,
                    adv: item?.adv,
                    oddsLine: item?.ouOddsLine,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ oddsFormat(item?.ouUnder) }}
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { BettingTableStateKey } from '@/constants/BettingTableState.enum';
import { useHdpFormat } from '@/composition/hdp-format';
import { useI18n } from 'vue-i18n';
import { useOddsFormat } from '@/composition/odds-format';
import { useAdvTextColor } from '@/composition/adv-color';
import { ADV_SELECTION } from '@/constants/Ticket.enum';
import { useBettingStore } from '../../store/betting';
import { BettingOddsType } from '@/constants/OddsType.enum';

import type { TeamSelected } from '../../interfaces/TeamSelected.interface';
import type { Hdp } from '../../interfaces/Hdp.interface';
import type { HdpMatch } from '../../interfaces/HdpMatch.interface';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'BettingLiveTable',
  props: {
    list: {
      type: Array as PropType<Hdp[]>,
      default: () => [],
    },
  },
  emits: ['click:match', 'click:odds'],
  setup(_, { emit }) {
    const { locale, t } = useI18n();

    const bettingOddsType = computed(() => BettingOddsType);

    const bettingStore = useBettingStore();

    const currentTable = computed(
      () => bettingStore.$state.currentBettingTable
    );
    const bettingTableStateKey = computed(() => BettingTableStateKey);

    const hdpFormat = (hdp: string | number) => useHdpFormat(hdp);

    const oddsFormat = (oddsValue: number) => {
      return useOddsFormat(oddsValue);
    };

    const advColorClass = (currentAdv: number, advValue: number) => {
      return useAdvTextColor(currentAdv, advValue);
    };

    const onClickOdds = (teamSelected: TeamSelected, matchItem: HdpMatch) => {
      if (teamSelected.value >= 0) {
        emit('click:odds', teamSelected);
        emit('click:match', matchItem);
      }
    };

    return {
      t,
      bettingOddsType,
      currentTable,
      bettingTableStateKey,
      hdpFormat,
      onClickOdds,
      locale,
      advColorClass,
      oddsFormat,
      ADV_SELECTION,
    };
  },
});
</script>
