<template>
  <div class="w-48">
    <Listbox v-model="selectedData">
      <div class="relative mt-1">
        <ListboxButton
          class="relative border border-blue-700 w-full cursor-default rounded bg-blue-50 py-1 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        >
          <span class="block truncate text-xs font-medium">
            {{ selectedData?.date?.[locale] }}
          </span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <font-awesome-icon icon="fa-solid fa-caret-down" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-for="item in list"
              v-slot="{ active, selected }"
              :key="item.accDate"
              :value="item"
              as="template"
            >
              <li
                :class="[
                  active
                    ? 'bg-amber-100 text-amber-900 bg-blue-50'
                    : 'text-gray-900',
                  'relative select-none py-1.5 pl-8 pr-4 cursor-pointer',
                ]"
                @click="onClickStatement(item)"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate text-xs',
                  ]"
                >
                  {{ item?.date?.[locale] }}
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                >
                  <font-awesome-icon icon="fa-solid fa-check" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, watchEffect } from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { useI18n } from 'vue-i18n';

import type { Statement } from '../../interfaces/api/Statement.interface';

export default defineComponent({
  name: 'SelectStatementInput',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  props: {
    list: {
      type: Array as PropType<Statement[]>,
      default: () => [],
    },
    currentDate: {
      type: String as PropType<string>,
      default: '',
    },
  },
  emits: ['change-acc-date'],
  setup(props, { emit }) {
    const { locale } = useI18n();
    const selectedData = ref();

    const onClickStatement = (value: Statement) => {
      emit('change-acc-date', value.accDate);
    };

    watchEffect(() => {
      const findDefaultStatement = props.list?.find(
        (item: Statement) => item.accDate === props.currentDate
      );

      if (findDefaultStatement) {
        selectedData.value = findDefaultStatement;
      }
    });

    return { selectedData, locale, onClickStatement };
  },
});
</script>
