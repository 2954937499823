<template>
  <div class="container mx-auto my-12 flex justify-center">
    <div class="rounded inner-container w-9/12">
      <div class="head">
        <h1 class="ng-binding">
          <i class="fa fa-th-list" name="th-list" />
          กฎกติกา
        </h1>
      </div>
      <div class="body">
        <table class="rules-table text-sm border-collapse border">
          <thead class="bg-blue-800 text-white border border-blue-800">
            <tr>
              <th class="border-t border-b border-l border-blue-200" />
              <th class="border-t border-b border-r border-blue-200">ฟุตบอล</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="px-2 border border-blue-200">1</th>
              <td class="px-2 leading-6 border border-blue-200">
                เมื่อท่านได้ทำการเดิมพันแล้วกรุณาตรวจสอบสถานะรายการพนันของท่านอีกครั้งที่เมนู
                "รายการพนันแบบเต็ม"
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">2</th>
              <td class="px-2 leading-6 border border-blue-200">
                กรุณา "บันทึก" หรือ "พิมพ์"
                รายการพนันของท่านไว้เป็นหลักฐานเพื่อตรวจสอบความถูกต้อง
                มิฉะนั้นทางเวปจะไม่รับผิดชอบใดๆทั้งสิ้น
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">3</th>
              <td class="px-2 leading-6 border border-blue-200">
                ทีมที่เลือกในการพนันจะต้องเป็นทีมที่ยังไม่มีการแข่งขัน
                หากมีทีมใดดำเนินการแข่งไปแล้วก่อนที่ท่านเลือกจะถือเป็นโมฆะ
                ยกเลิกทั้งชุด
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">4</th>
              <td class="px-2 leading-6 border border-blue-200">
                ถ้าคู่แข่งขันที่ เลือกคู่ใดผิดกติกาการแข่งขันในเวลาปกติ 90
                นาทีหรือมีการยกเลิกระหว่างแข่งขัน
                ให้ถือว่าคู่แข่งขันนั้นเป็นโมฆะ ยกเลิกเฉพาะคู่นั้น
                ส่วนคู่อื่นคิดตามปกติ
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">5</th>
              <td class="px-2 leading-6 border border-blue-200">
                ผลการแข่งขันอ้างอิงและคิดได้เสียตามเว็บ USUN เท่านั้น
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">6</th>
              <td class="px-2 leading-6 border border-blue-200">
                ถ้าคู่แข่งขันที่ เลือกคู่ใดมีการเลื่อนเตะออกไปจากเวลาเดิม
                เกินกว่า 12 ชั่วโมง ให้ถือว่าคู่แข่งขันนั้นเป็นโมฆะ
                ยกเลิกเฉพาะคู่นั้น ส่วนคู่อื่นคิดตามปกติ
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">7</th>
              <td class="px-2 leading-6 border border-blue-200">
                กรณีมีการเลื่อนการคิดผลการแข่งขัน เกินกว่า 2 วัน
                ให้ถือว่าคู่แข่งขันนั้นเป็นโมฆะ ยกเลิกเฉพาะคู่นั้น
                ส่วนคู่อื่นคิดตามปกติ
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">8</th>
              <td class="px-2 leading-6 border border-blue-200">
                กรณีมีคู่แข่งขันที่เลือกถูกเลื่อนเตะออกไปจากเดิม เกินกว่า 12
                ชั่วโมงและยังคงมีคู่ปกติอยู่ในรายการ ให้ถือว่าได้เสียตามปกติ
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">9</th>
              <td class="px-2 leading-6 border border-blue-200">
                หากมีการเปิดหน้าบอล ทีมต่อรองสูงต่ำ หรือราคาผิด
                ทางเวปจะแจ้งในประกาศให้ทราบและทำการยกเลิกการเดิมพันนั้นทั้งชุด
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">10</th>
              <td class="px-2 leading-6 border border-blue-200">
                การเดิมพันบอลสเต็ปจะต้องไม่มีคู่แข่งขันที่เลือกซ้ำกันไม่ว่าจะเป็นการเล่นหน้าบอลหรือสูง
                ต่ำภายในรายการพนันชุดเดียวกัน มิฉะนั้นจะถือว่าเป็นโมฆะ
                ยกเลิกทั้งชุด
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">11</th>
              <td class="px-2 leading-6 border border-blue-200">
                หากมีข้อผิดพลาด จากการคำนวณยอดได้-เสีย
                ส่วนลดหรือการถือสู้ใดๆของสมาชิกกรุณาแจ้งตัวแทนของท่าน ภายใน12
                ชั่วโมง มิฉะนั้นทางเว็ปจะไม่รับผิดชอบใดๆทั้งสิ้น
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">12</th>
              <td class="px-2 leading-6 border border-blue-200">
                การคำนวนยอดได้เสียในบอลสเต็ปให้ถือตามสูตรคำนวณของทางเว็ปเท่านั้น
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">13</th>
              <td class="px-2 leading-6 border border-blue-200">
                กรุณาตรวจสอบราคาก่อนแทงทุกครั้ง
                หากทางเว็ปพบราคาหรือหน้าบอลที่ผิดพลาด
                ทางเว็ปจะขอยกเลิกรายการแทงคู่นั้น ในรายการตั๋วของท่าน
              </td>
            </tr>
            <tr ng-repeat="rule in rules">
              <th class="px-2 border border-blue-200">14</th>
              <td class="px-2 border border-blue-200">
                ทางเว็ปมีสิทธิในการปิดรับการแทง
                ในคู่ที่เลือกตรงกันและมียอดการแทงในคู่ที่เลือกนั้นเกินกว่าจำนวนที่เว็ปกำหนด
              </td>
            </tr>
          </tbody>
        </table>
        <table class="rules-table text-sm border-separate border mt-4">
          <thead class="bg-blue-800 text-white border border-blue-800">
            <tr>
              <th class="border-t border-b border-l border-blue-200" />
              <th class="border-t border-b border-r border-blue-200">มวย</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="px-2 border border-blue-200">1</th>
              <td class="px-2 border border-blue-200">
                ตลาดราคาต่อรอง หมายถึง ทายผู้ชนะของแข่งกัน หากผลเป็นเสมอ
                การเดิมพันจะยกเลิก
              </td>
            </tr>
            <tr>
              <th class="px-2 border border-blue-200">2</th>
              <td class="px-2 border border-blue-200">
                สีประจำมุมสำหรับนักมวย (มุมแดง มุมน้ำเงิน)
                ใช้เพื่ออ้างอิงเท่านั้น
                การเปลี่ยนแปลงมุมไม่มีผลต่อการเดิมพันแต่อย่างใด
              </td>
            </tr>
            <tr>
              <th class="px-2 border border-blue-200">3</th>
              <td class="px-2 border border-blue-200">
                หากนักมวยฝ่ายใดฝ่ายหนึ่ง หรือทั้งสองฝ่าย ถูกกรรมการไล่ลงจากเวที
                การเดิมพันทั้งหมดจะถูกยกเลิก
              </td>
            </tr>
            <tr>
              <th class="px-2 border border-blue-200">4</th>
              <td class="px-2 border border-blue-200">
                หากมีการเลื่อนเวลาต่อยออกไปจากกำหนดเดิมมากกว่า 12 ชั่วโมง
                การเดิมพันทั้งหมดจะถูกยกเลิก
              </td>
            </tr>
            <tr>
              <th class="px-2 border border-blue-200">5</th>
              <td class="px-2 border border-blue-200">
                เมื่อระฆังเริ่มยกที่หนึ่งดังขึ้น
                แต่นักมวยฝ่ายใดฝ่ายหนึ่งหรือทั้งสองฝ่าย ไม่สามารถทำการชกได้
                การเดิมพันทั้งหมดจะถูกยกเลิก
              </td>
            </tr>
          </tbody>
        </table>
        <notify-modal
          :message="bettingState.bettingErrorMessage"
          :is-open="bettingState.isShowBettingModal"
          @close-notify-modal="onCloseNotifyModal"
        />
      </div>
      <div class="foot border-b">
        <div class="flex justify-center">
          <button
            class="bg-blue-800 hover:bg-blue-700 text-white px-3 py-2 rounded"
            @click="onAcceptRules"
          >
            ยอมรับ
          </button>
          <button
            class="bg-gray-200 hover:bg-gray-100 text-black px-3 py-2 rounded ml-3"
            @click="onLogout"
          >
            ไม่ยอมรับ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../store/auth';
import { useBettingStore } from '../store/betting';
import { useStorage } from '@vueuse/core';

import NotifyModal from '@/components/NotifyModal.vue';

export default defineComponent({
  name: 'RulesPage',
  components: { NotifyModal },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const bettingStore = useBettingStore();

    const bettingState = computed(() => bettingStore.$state);

    const onAcceptRules = async () => {
      await router.replace('/');
    };

    const onLogout = async () => {
      await authStore.logout();
    };

    const onCloseNotifyModal = async () => {
      if (bettingState.value.isSessionExpired) {
        const token = useStorage('APP_TOKEN', null);
        token.value = null;
        await router.replace('/login');
      }
      bettingState.value.isShowBettingModal = false;
    };

    return { onAcceptRules, onLogout, bettingState, onCloseNotifyModal };
  },
});
</script>
