<template>
  <main-body :title="$t('betListTitle')" icon="th-list">
    <container-bet-list />
  </main-body>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainBody from '@/components/MainBody.vue';
import ContainerBetList from '@/containers/Ticket/ContainerBetList.vue';

export default defineComponent({
  name: 'BetListPage',
  components: {
    MainBody,
    ContainerBetList,
  },
});
</script>
