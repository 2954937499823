<template>
  <div class="logo-login pt-0 sm:pt-4 ml-5">
    <img class="logo" src="@/assets/images/logo.webp" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PublicLogo',
});
</script>

<style>
.NuxtLogo {
  animation: 1s appear;
  margin: auto;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.product-list .logo-login {
  width: 165px;
  float: left;
}
.logo {
  height: 42px;
  margin: -3px 0 0 -10px;
  max-width: none;
}
</style>
