import { createStore, type ModuleTree, createLogger } from 'vuex';
import bettingModule from '@/vuex-store/betting';
import ticketModule from '@/vuex-store/ticket';
import profileModule from '@/vuex-store/profile';
import statementModule from '@/vuex-store/statement';
import resultModule from '@/vuex-store/result';

import type { RootState } from './RootState.interface';

const modules: ModuleTree<RootState> = {
  betting: bettingModule,
  ticket: ticketModule,
  profile: profileModule,
  statement: statementModule,
  result: resultModule,
};

export default createStore({
  modules,
  plugins: [createLogger()],
});
