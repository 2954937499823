export enum BettingTableState {
  FULL_TIME = 'FULL_TIME',
  FIRST_HALF = 'FIRST_HALF',
  ODDS_EVEN = 'ODDS_EVEN',
  FULL_BET = 'FULL_BET',
}

export enum BettingTableStateKey {
  FULL_TIME = 'hdpOu',
  FIRST_HALF = 'fhHdpOu',
  ODDS_EVEN = 'oddsEven',
  FULL_BET = 'fullBet',
}
