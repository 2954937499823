export const mockResultList = [
  {
    leagueName: '*ENGLISH PREMIER LEAGUE',
    data: [
      {
        away: 'Brighton & Hove Albion',
        awayTh: 'ไบรท์ตัน แอนด์ โฮฟ อัลเบี้ยน',
        awayName: {
          en: 'Brighton & Hove Albion',
          th: 'ไบรท์ตัน แอนด์ โฮฟ อัลเบี้ยน',
        },
        home: 'Southampton',
        homeName: {
          en: 'Southampton',
          th: 'เซาแธมป์ตัน',
        },
        homeTh: 'เซาแธมป์ตัน',
        id: '41943729',
        koTime: '2021-03-14 19:00:00',
        leagueTh: 'พรีเมียร์ลีก อังกฤษ',
        scoreAway: null,
        scoreAwayHt: '1',
        scoreHome: null,
        scoreHomeHt: '1',
        status: 'play 1H',
        time: '19:00',
      },
      {
        away: 'Sheffield United',
        awayName: {
          en: 'Sheffield United',
          th: 'เชฟฟิลด์ ยูไนเต็ด',
        },
        awayTh: 'เชฟฟิลด์ ยูไนเต็ด',
        home: 'Leicester City',
        homeName: {
          en: 'Leicester City',
          th: 'เลสเตอร์ ซิตี้',
        },
        homeTh: 'เลสเตอร์ ซิตี้',
        id: '41943732',
        koTime: '2021-03-14 21:00:00',
        leagueTh: 'พรีเมียร์ลีก อังกฤษ',
        scoreAway: null,
        scoreAwayHt: null,
        scoreHome: null,
        scoreHomeHt: null,
        status: 'incoming',
        time: '21:00',
      },
      {
        away: 'Tottenham Hotspur',
        awayName: {
          en: 'Tottenham Hotspur',
          th: 'ท็อตแนม ฮ็อตสเปอร์ส',
        },
        awayTh: 'ท็อตแนม ฮ็อตสเปอร์ส',
        home: 'Arsenal',
        homeName: {
          en: 'Arsenal',
          th: 'อาร์เซนอล',
        },
        homeTh: 'อาร์เซนอล',
        id: '41943733',
        koTime: '2021-03-14 23:30:00',
        leagueTh: 'พรีเมียร์ลีก อังกฤษ',
        scoreAway: null,
        scoreAwayHt: null,
        scoreHome: null,
        scoreHomeHt: null,
        status: 'incoming',
        time: '23:30',
      },
    ],
  },
  {
    leagueName: '*GERMANY-BUNDESLIGA I',
    data: [
      {
        away: 'Arminia Bielefeld',
        awayName: {
          en: 'Arminia Bielefeld',
          th: 'Arminia Bielefeld',
        },
        awayTh: 'Arminia Bielefeld',
        home: 'Bayer 04 Leverkusen',
        homeName: {
          en: 'Bayer 04 Leverkusen',
          th: 'Bayer 04 Leverkusen',
        },
        homeTh: 'Bayer 04 Leverkusen',
        id: '41943847',
        koTime: '2021-03-14 19:30:00',
        leagueTh: '*GERMANY-BUNDESLIGA I',
        scoreAway: null,
        scoreAwayHt: '0',
        scoreHome: null,
        scoreHomeHt: '0',
        status: 'play 1H',
        time: '19:30',
      },
      {
        away: 'Eintracht Frankfurt',
        awayName: {
          en: 'Eintracht Frankfurt',
          th: 'ไอน์ทรัค แฟร้งค์เฟิร์ต',
        },
        awayTh: 'ไอน์ทรัค แฟร้งค์เฟิร์ต',
        home: 'RB Leipzig',
        homeName: {
          en: 'RB Leipzig',
          th: 'RB ลีบซิก',
        },
        homeTh: 'RB ลีบซิก',
        id: '41943848',
        koTime: '2021-03-14 21:30:00',
        leagueTh: 'บุนเดสลีกา เยอรมัน',
        scoreAway: null,
        scoreAwayHt: null,
        scoreHome: null,
        scoreHomeHt: null,
        status: 'incoming',
        time: '21:30',
      },
    ],
  },
];
