<template>
  <LoginForm :error="loginError" @submit:login="onSubmitForm" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import LoginForm from '@/components/Login/LoginForm.vue';
import { useAuthStore } from '../store/auth';

import type { LoginInput } from '../interfaces/api/LoginInput.interface';

const authStore = useAuthStore();

const loginError = computed(() => authStore.loginErrorMessage);

const onSubmitForm = async (payload: LoginInput) => {
  await authStore.login(payload);
};
</script>
