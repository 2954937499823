<template>
  <main-body :title="$t('statementTitle')" icon="list-alt">
    <container-statement-table
      v-show="!accDate"
      @change-acc-date="onChangeAccDate"
    />
    <div v-show="accDate">
      <div class="flex items-center justify-between mb-2.5">
        <router-link
          to="/statement"
          class="bg-blue-800 text-white rounded font-medium px-2 py-1 block max-w-min min-w-max"
          @click="resetState"
        >
          <font-awesome-icon icon="fa-circle-left" class="mr-0.5" />
          {{ $t('backToStatement') }}
        </router-link>
        <div class="flex items-center">
          <font-awesome-icon
            class="text-xl mr-2 text-blue-800"
            icon="fa-calendar-days"
          />
          <select-statement-input
            v-if="statementState.statementList?.length"
            :list="statementState.statementList"
            :current-date="`${accDate}`"
            class="select-statement-input"
            @change-acc-date="onChangeAccDate"
          />
        </div>
      </div>
      <container-statement-detail />
    </div>
  </main-body>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import MainBody from '@/components/MainBody.vue';
import ContainerStatementTable from '@/containers/Statement/ContainerStatementTable.vue';
import { useRoute } from 'vue-router';
import { useStatementStore } from '../store/statement';
import ContainerStatementDetail from '@/containers/Statement/ContainerStatementDetail.vue';
import SelectStatementInput from '@/containers/Statement/SelectStatementInput.vue';
export default defineComponent({
  name: 'StatementPage',
  components: {
    MainBody,
    SelectStatementInput,
    ContainerStatementTable,
    ContainerStatementDetail,
  },
  setup() {
    const route = useRoute();
    const statementStore = useStatementStore();

    onMounted(async () => {
      await statementStore.getStatementList();
      await fetchStatementDetail(accDate.value as string);
    });

    const statementState = computed(() => statementStore.$state);

    const accDate = computed(() => route.query?.accDate);

    const fetchStatementDetail = async (accDate?: string) => {
      await statementStore.getStatementDetailList(accDate);
    };

    const resetState = () => {
      statementStore.$state.statementDetailList = [];
    };

    const onChangeAccDate = async (accDate: string) => {
      await fetchStatementDetail(accDate);
    };

    return { accDate, statementState, onChangeAccDate, resetState };
  },
});
</script>

<style lang="scss">
.select-statement-input ul {
  @apply z-10;
}
</style>
