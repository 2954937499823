<template>
  <div id="app-root">
    <router-view />
  </div>
  <teleport to="head">
    <meta name="viewport" :content="content" />
  </teleport>
  <Suspense>
    <AppUpdateOverlay />
  </Suspense>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import AppUpdateOverlay from './components/AppUpdateOverlay.vue';
import { useAuthStore } from './store/auth';

export default defineComponent({
  name: 'App',
  components: { AppUpdateOverlay },
  setup() {
    const authStore = useAuthStore();

    const content = computed(() =>
      authStore.isLoggedIn ? '' : 'width=device-width,initial-scale=1.0'
    );

    return { content };
  },
});
</script>
