import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { App } from 'vue';

library.add(fas);
library.add(far);

export function useFontAwesome(app: App<Element>) {
  app.component('FontAwesomeIcon', FontAwesomeIcon);
}
