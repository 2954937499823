<template>
  <table class="record-table">
    <thead class="sticky top-8">
      <tr>
        <th>{{ t('time') }}</th>
        <th>{{ t('match') }}</th>
        <th>{{ t('htScore') }}</th>
        <th>{{ t('ftScore') }}</th>
        <th>{{ t('status') }}</th>
      </tr>
    </thead>
    <tbody v-for="(resultItem, index) in list" :key="index">
      <tr>
        <td colspan="5" class="py-0.5 league text-white pl-3 text-left">
          <strong>{{ resultItem?.leagueName }}</strong>
        </td>
      </tr>
      <tr v-for="(item, indexItem) in resultItem?.data" :key="indexItem">
        <td class="py-0.5 time text-center font-bold">
          {{ item?.time }}
        </td>
        <td class="py-0.5 match px-1 font-medium">
          {{ item?.homeName?.[locale] }} - vs - {{ item?.awayName?.[locale] }}
        </td>
        <td class="py-0.5 text-center">
          {{ item?.scoreHomeHt }} - {{ item?.scoreAwayHt }}
        </td>
        <td class="py-0.5 text-center">
          {{ item?.scoreHome }} - {{ item?.scoreAway }}
        </td>
        <td class="py-0.5 status-score text-center">
          <strong :class="{ 'text-[#008338]': item?.status === 'complete' }">
            {{ item?.status }}
          </strong>
        </td>
      </tr>
    </tbody>
    <tr v-if="!hasList">
      <td colspan="5" class="py-0.5 pl-3 text-center">
        <strong>{{ t('dataNotFound') }}</strong>
      </td>
    </tr>
  </table>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Result } from '../../interfaces/api/Result.interface';

export default defineComponent({
  name: 'ContainerResultTable',
  props: {
    list: {
      type: Array as PropType<Result[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { locale, t } = useI18n();

    const hasList = computed(() => props?.list?.length > 0);

    return { locale, t, hasList };
  },
});
</script>
