<template>
  <div class="ticket-content">
    <div>
      <div class="tickets-selected">
        <ticket-form
          v-if="ticketList?.length"
          :bet-list="ticketList"
          :selected-team-list="selectedTeamList"
          @click:delete="onClickDelete"
          @click:cancel="onClickCancel"
          @submit-ticket="onSubmitCreateTicket"
        />
        <span v-else class="empty-ticket text-xs text-center block font-bold">
          {{ $t('emptyTicket') }}
        </span>
      </div>
    </div>
  </div>
  <teleport to="body">
    <notify-modal
      :message="bettingState.bettingErrorMessage"
      :is-open="bettingState.isShowBettingModal"
      @close-notify-modal="onCloseNotifyModal"
    />
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import TicketForm from '@/components/TicketForm.vue';
import { useBettingStore } from '../store/betting';
import intersectionBy from 'lodash/intersectionBy';
import { TICKET_STATUS } from '../constants/Ticket.enum';
import NotifyModal from '@/components/NotifyModal.vue';
import { useBetTicketStore } from '../store/betTicket';

import type { Ref, ComputedRef } from 'vue';
import type { TeamSelected } from '../interfaces/TeamSelected.interface';
import type { UpdateTicket } from '@/interfaces/api/UpdateTicket.interface';
import type { MatchSelected } from '../interfaces/Ticket.interface';
import type { CreateTicketInput } from '../interfaces/api/CreateTicketInput.interface';

export default defineComponent({
  name: 'TicketFormContainer',
  components: {
    TicketForm,
    NotifyModal,
  },
  setup() {
    const ticketFormList: Ref<TeamSelected[]> = ref([]);
    const latestTicketList = ref([]);
    const bettingStore = useBettingStore();
    const betTicketStore = useBetTicketStore();

    const bettingState = computed(() => bettingStore.$state);
    const betTicketState = computed(() => betTicketStore.$state);

    const ticketList: ComputedRef<MatchSelected[]> = computed(
      () => betTicketStore.$state.ticketList
    );
    const selectedTeamList: ComputedRef<TeamSelected[]> = computed(
      () => betTicketStore.$state.selectedTeamList
    );

    const hasTicketList = ticketList.value?.length > 0;

    const setTicketList = (
      ticketList: MatchSelected[],
      selectedTeamList: TeamSelected[]
    ) => {
      betTicketStore.$state.ticketList = ticketList;
      betTicketStore.$state.selectedTeamList = selectedTeamList;
    };
    const onClickDelete = (matchId: number) => {
      const filterTicketList = ticketList.value?.filter(
        (e: MatchSelected) => e.matchId !== matchId
      );
      const filterSelectedTeamList = selectedTeamList.value?.filter(
        (e: TeamSelected) => e.matchId !== matchId
      );

      setTicketList(filterTicketList, filterSelectedTeamList);
    };

    const onCloseNotifyModal = async () => {
      bettingState.value.isShowBettingModal = false;
    };

    const onClickCancel = () => {
      setTicketList([], []);
    };

    watch(
      () => bettingState.value?.updateTicketList,
      () => {
        if (betTicketState.value?.selectedTeamList?.length) {
          // loop select team list for update odds value
          ticketFormList.value =
            betTicketState.value?.selectedTeamList?.flatMap(
              (item: TeamSelected) => {
                if (bettingState.value?.updateTicketList?.length > 0) {
                  const matchedListItem =
                    bettingState.value?.updateTicketList?.find(
                      (updateItem: UpdateTicket) => updateItem?.id === item?.id
                    );

                  if (matchedListItem) {
                    // add new odds to ticket list
                    return {
                      ...item,
                      value: Number(matchedListItem?.latestOdds),
                      status: getStatus(item.value, matchedListItem.latestOdds),
                    };
                  } else {
                    return {
                      ...item,
                      status: TICKET_STATUS.IDLE,
                    };
                  }
                }
                return betTicketState.value?.selectedTeamList;
              }
            );

          const unionList = intersectionBy(
            ticketFormList.value,
            betTicketStore.$state.selectedTeamList,
            'id'
          );
          betTicketStore.$state.selectedTeamList = unionList;
        }
      },
      { deep: true }
    );

    const getStatus = (value: number, latestOdds: string | number | null) => {
      if (Number(latestOdds) === 0) {
        return TICKET_STATUS.DEL;
      } else if (Number(latestOdds) < value) {
        return TICKET_STATUS.DOWN;
      } else if (Number(latestOdds) > value) {
        return TICKET_STATUS.UP;
      } else {
        return TICKET_STATUS.IDLE;
      }
    };

    const onSubmitCreateTicket = async (payload: CreateTicketInput) => {
      await bettingStore.createTicket(payload);
    };

    return {
      bettingState,
      latestTicketList,
      ticketList,
      selectedTeamList,
      onClickDelete,
      hasTicketList,
      onClickCancel,
      onCloseNotifyModal,
      onSubmitCreateTicket,
    };
  },
});
</script>

<style>
.ticket-content .empty-ticket {
  padding: 5px;
}
</style>
