<template>
  <div
    class="headercentericonblue leading-8 font-bold text-xs"
    @click="onClickFetchOdds"
  >
    <a>
      <span class="titlerefreshiconblue mt-2 mr-1 cursor-pointer" />
    </a>
    <span>{{ timeLeft }}</span>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  onMounted,
  watch,
  type Ref,
} from 'vue';
import { BettingTableState } from '@/constants/BettingTableState.enum';
import { useBettingStore } from '../../store/betting';
import { OddsType } from '../../constants/OddsType.enum';
import { useBetTicketStore } from '../../store/betTicket';
import type { HdpMatch } from '../../interfaces/HdpMatch.interface';
import type { FbMatch } from '../../interfaces/FbMatch.interface';
import type { FhHdpMatch } from '../../interfaces/FhHdpMatch.interface';
import type { HdaMatch } from '../../interfaces/HdaMatch.interface';

export default defineComponent({
  name: 'FetchInterval',
  setup() {
    const bettingStore = useBettingStore();
    const betTicketStore = useBetTicketStore();
    const timeLeft: Ref<number> = ref(60);
    const isPauseInterval = ref(false);

    const currentTable = computed(
      () => bettingStore.$state.currentBettingTable
    );
    const selectedTeamList = computed(() => betTicketStore.selectedTeamList);
    const ticketList = computed(() => betTicketStore.ticketList);
    const matchList = computed(() => {
      if (currentTable.value === BettingTableState.FULL_TIME) {
        return bettingStore.ftHdpList;
      }
      if (currentTable.value === BettingTableState.FIRST_HALF) {
        return bettingStore.fhHdpList;
      }
      if (currentTable.value === BettingTableState.ODDS_EVEN) {
        return bettingStore.hdaList;
      }
      if (currentTable.value === BettingTableState.FULL_BET) {
        return bettingStore.fullBetList;
      }
      return [];
    });

    const fetchOddsList = async () => {
      if (currentTable.value === BettingTableState.FULL_TIME) {
        await bettingStore.getOddsList(OddsType.HDP);
      } else if (currentTable.value === BettingTableState.FIRST_HALF) {
        await bettingStore.getOddsList(OddsType.FH);
      } else if (currentTable.value === BettingTableState.ODDS_EVEN) {
        await bettingStore.getOddsList(OddsType.HDA);
      } else if (currentTable.value === BettingTableState.FULL_BET) {
        await bettingStore.getOddsList(OddsType.FULL);
      }
      timeLeft.value = 60;
    };

    const timer = setInterval(() => {
      if (!isPauseInterval.value) {
        if (timeLeft.value) {
          timeLeft.value -= 1;
        } else if (!document.hidden) {
          fetchOddsList();
        }
      }
    }, 1000);

    const onClickFetchOdds = async () => {
      await fetchOddsList();
    };

    const handleInterval = () => {
      if (!document.hidden) {
        fetchOddsList();
      }
    };

    watch(
      matchList,
      (newMatchList) => {
        if (newMatchList.length > 0) {
          let matchListMapper: (HdpMatch | FbMatch | FhHdpMatch | HdaMatch)[] =
            [];
          newMatchList.forEach((el) => {
            if (el.record) {
              matchListMapper = [...matchListMapper, ...el.record];
            }
            matchListMapper = [...matchListMapper];
          });
          const selectedTeamListMapper = selectedTeamList.value.map((mapEl) => {
            const newMatch = matchListMapper.find(
              (someEl) => someEl.id === mapEl.id
            );
            if (
              newMatch &&
              !!newMatch[mapEl.oddsType] &&
              newMatch[mapEl.oddsType] !== 0 &&
              mapEl.value !== newMatch[mapEl.oddsType]
            ) {
              const oldValue = mapEl.value;
              return { ...mapEl, oldValue, value: newMatch[mapEl.oddsType] };
            }
            return mapEl;
          });
          const ticketListMapper = ticketList.value.map((mapEl) => {
            const newMatch = matchListMapper.find(
              (someEl) => someEl.id === mapEl.id
            );
            const selectedMatch = selectedTeamListMapper.find(
              (someEl) => someEl.id === mapEl.id
            );
            if (
              newMatch &&
              Number(selectedMatch?.value) !== Number(selectedMatch?.oldValue)
            ) {
              return {
                ...mapEl,
                error: {
                  errorCode: 'InvalidOdds',
                },
              };
            }
            return mapEl;
          });
          betTicketStore.$patch({
            selectedTeamList: selectedTeamListMapper,
            ticketList: ticketListMapper,
          });
        }
      },
      { deep: true }
    );

    onMounted(() => {
      document.addEventListener('visibilitychange', handleInterval);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('visibilitychange', handleInterval);
      clearInterval(timer);
    });

    return { onClickFetchOdds, timeLeft };
  },
});
</script>

<style>
.titlerefreshiconblue {
  background: url(../../assets/images/table-icon.png) -46px -41px;
  float: left;
  height: 14px;
  width: 14px;
}
</style>
