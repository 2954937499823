import { defineStore } from 'pinia';
import { apiFetch } from '../composition/useAxios';
import { BettingTableState } from '../constants/BettingTableState.enum';
import { HdpType } from '../constants/HdpType.enum';
import { OddsType } from '../constants/OddsType.enum';
import { useBetTicketStore } from './betTicket';
import { useMemberStore } from './member';
import { useTicketStore } from './ticket';
import { useLoadingStore } from './wait';
import router from '../router';

import type { CreateTicketInput } from '../interfaces/api/CreateTicketInput.interface';
import type { BettingStore } from './BettingStore.interface';
import { useStorage } from '@vueuse/core';
import { useModalStore } from './modal';

export const useBettingStore = defineStore('betting', {
  state: (): BettingStore => {
    return {
      version: '1.0.0',
      currentBettingTable: BettingTableState.FULL_TIME,
      ftHdpList: [],
      fhHdpList: [],
      hdaList: [],
      fullBetList: [],
      hdpLiveList: [],
      market: {
        hdp: 0,
        fh: 0,
        hda: 0,
        full: 0,
      },
      hdpType: HdpType.HDP_ONE,
      updateTicketList: [],
      filterLeagueList: [],
      isShowBettingModal: false,
      isSessionExpired: false,
      bettingErrorMessage: '',
      timeNow: null,
    };
  },
  actions: {
    async getOddsList(oddsType = OddsType.HDP) {
      const lang = localStorage.getItem('lang') || 'th';
      const modalStore = useModalStore();
      try {
        const { data, error } = await apiFetch(`/odds?type=${oddsType}`, {
          method: 'GET',
        });
        if (data?.value) {
          this.timeNow = data?.value.timeNow;
          if (oddsType !== OddsType.HDP) {
            this.market = data?.value?.items?.[0].market;
          }
          if (oddsType === OddsType.HDP) {
            this.market = data?.value?.items?.[1]?.market;
            this.hdpLiveList = data.value?.items?.[0]?.odds;
            this.ftHdpList = data.value?.items?.[1].odds;
          } else if (oddsType === OddsType.FH) {
            this.fhHdpList = data.value?.items?.[0].odds;
          } else if (oddsType === OddsType.HDA) {
            this.hdaList = data.value?.items?.[0].odds;
          } else if (oddsType === OddsType.FULL) {
            this.fullBetList = data.value?.items?.[0].odds;
          }
        }
        if (error.value?.response?.status === 401) {
          const token = useStorage('APP_TOKEN', null);
          token.value = null;
          await router.push('/login');
          modalStore.message =
            lang === 'th'
              ? 'เซสชั่นไม่ถูกต้องหรือหมดอายุ เนื่องจากล็อคอินซ้อน'
              : 'Session expired';
          modalStore.isShow = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async createTicket(input: CreateTicketInput) {
      const ticketStore = useTicketStore();
      const memberStore = useMemberStore();
      const loadingStore = useLoadingStore();
      const betTicketStore = useBetTicketStore();
      const modalStore = useModalStore();
      const lang = localStorage.getItem('lang') || 'th';
      try {
        loadingStore.start('createTicket');
        const { data, error } = await apiFetch('/bet/create', {
          headers: {
            'Accept-Language': lang,
          },
          method: 'POST',
          data: input,
        });
        if (error.value?.response?.data) {
          this.isShowBettingModal = true;
          this.bettingErrorMessage = error.value?.response?.data.errorMessage;
        }
        if (data?.value) {
          if (data?.value.errors?.length > 0) {
            betTicketStore.$state.selectedTeamList =
              betTicketStore.$state.selectedTeamList.map((mapEl) => {
                const matchError = data?.value.errors.find(
                  (findEl: any) => mapEl.id === findEl.matchRateID
                );
                if (
                  matchError?.error.errorCode === 'InvalidOdds' &&
                  Number(mapEl.value) !== Number(matchError.odds)
                ) {
                  const oldValue = mapEl.value;
                  return {
                    ...mapEl,
                    oldValue,
                    value: matchError.odds,
                  };
                }
                return { ...mapEl };
              });
            betTicketStore.$state.ticketList = betTicketStore.$state.ticketList
              .map((mapEl) => {
                const matchError = data?.value.errors.find(
                  (findEl: any) => mapEl.id === findEl.matchRateID
                );
                if (matchError) {
                  if (
                    matchError?.error.errorCode === 'InvalidOdds' &&
                    !matchError?.odds
                  ) {
                    return {
                      ...mapEl,
                      error: {
                        newOdds: matchError.odds,
                        errorCode: 'OddsLessThanLimit',
                        errorMessage: matchError.error.errorMessage,
                      },
                    };
                  }
                  return {
                    ...mapEl,
                    error: {
                      newOdds: matchError.odds,
                      errorCode: matchError.error.errorCode,
                      errorMessage: matchError.error.errorMessage,
                    },
                  };
                }
                return { ...mapEl, error: null };
              })
              .sort((prev, curr) => {
                if (curr.error && prev.error?.errorCode !== 'InvalidOdds') {
                  return 0;
                }
                return curr.error ? 0 : -1;
              });
            if (
              betTicketStore.$state.ticketList.some(
                (el) =>
                  el.error?.errorCode === 'InvalidOdds' &&
                  el.error?.newOdds &&
                  el.error?.newOdds !== 0
              )
            ) {
              this.isShowBettingModal = true;
              this.bettingErrorMessage =
                lang === 'th'
                  ? 'ราคามีการเปลี่ยนแปลง ตรวจสอบและทำรายการใหม่อีกครั้ง'
                  : 'Odds has been changed';
            }
          } else {
            betTicketStore.$state.selectedTeamList = [];
            betTicketStore.$state.ticketList = [];
            this.updateTicketList = [];
            await ticketStore.getTicketList();
            await memberStore.getMember();
          }
        }
        if (error.value) {
          this.updateTicketList = error.value?.response?.data.parlay;
        }
        if (error.value?.response?.status === 401) {
          const token = useStorage('APP_TOKEN', null);
          token.value = null;
          await router.push('/login');
          modalStore.message =
            lang === 'th'
              ? 'เซสชั่นไม่ถูกต้องหรือหมดอายุ เนื่องจากล็อคอินซ้อน'
              : 'Session expired';
          modalStore.isShow = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingStore.end('createTicket');
      }
    },
  },
});
