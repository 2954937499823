import { computed, type ComputedRef } from 'vue';
import type { TeamSelected } from '../interfaces/TeamSelected.interface';
import type { MatchSelected } from '../interfaces/Ticket.interface';
import { useBetTicketStore } from '../store/betTicket';
import { useMemberStore } from '../store/member';
import { useBettingStore } from '../store/betting';

export function useSetTicket() {
  const betTicketStore = useBetTicketStore();
  const memberStore = useMemberStore();
  const bettingStore = useBettingStore();

  const ticketList: ComputedRef<MatchSelected[]> = computed(
    () => betTicketStore.$state.ticketList
  );

  const selectedTeamList: ComputedRef<TeamSelected[]> = computed(
    () => betTicketStore.$state.selectedTeamList
  );

  const setTicketList = (newTicketList: MatchSelected[]) => {
    betTicketStore.$state.ticketList = newTicketList;
  };

  const updateTicketList = (newTicketItem: MatchSelected) => {
    const ticketListUpdated = ticketList.value.map(
      (currentTicketItem: MatchSelected) => {
        if (currentTicketItem.matchId === newTicketItem.matchId) {
          return { ...newTicketItem };
        } else {
          return { ...currentTicketItem };
        }
      }
    );
    setTicketList(ticketListUpdated);
  };

  const useSetTicketInputList = (newTicketItem: MatchSelected) => {
    const findTicketItem = ticketList.value?.find(
      (e: MatchSelected) => e.matchId === newTicketItem.matchId
    );
    if (
      (memberStore.member?.parlayMax &&
        memberStore.member?.parlayMax >= ticketList.value.length + 1) ||
      findTicketItem
    ) {
      if (ticketList.value.length > 0) {
        if (findTicketItem) {
          updateTicketList(newTicketItem);
        } else {
          setTicketList([...ticketList.value, newTicketItem]);
        }
      } else {
        setTicketList([...ticketList.value, newTicketItem]);
      }
    }
  };

  const setSelectedTeamList = (newSelectedTeamList: TeamSelected[]) => {
    betTicketStore.$state.selectedTeamList = newSelectedTeamList;
  };

  const updateOddsList = (newOddsItem: TeamSelected) => {
    const oddsListUpdated = selectedTeamList.value.map(
      (currentTicketItem: TeamSelected) => {
        if (currentTicketItem.matchId === newOddsItem.matchId) {
          return { ...newOddsItem };
        } else {
          return { ...currentTicketItem };
        }
      }
    );
    setSelectedTeamList(oddsListUpdated);
  };

  const useSetSelectedTicketInputList = (newOddsItem: TeamSelected) => {
    const findOddsItem = selectedTeamList.value?.find(
      (e: TeamSelected) => e.matchId === newOddsItem.matchId
    );
    if (
      (memberStore.member?.parlayMax &&
        memberStore.member?.parlayMax >= selectedTeamList.value.length + 1) ||
      findOddsItem
    ) {
      if (selectedTeamList.value.length > 0) {
        if (findOddsItem) {
          updateOddsList(newOddsItem);
        } else {
          setSelectedTeamList([...selectedTeamList.value, newOddsItem]);
        }
      } else {
        setSelectedTeamList([...selectedTeamList.value, newOddsItem]);
      }
    } else {
      bettingStore.bettingErrorMessage = `จำนวนคู่ต่ำ-สูงสุด ${memberStore.member?.parlayMin} - ${memberStore.member?.parlayMax}คู่ ต่อบิล`;
      bettingStore.isShowBettingModal = true;
    }
  };

  return {
    useSetSelectedTicketInputList,
    useSetTicketInputList,
  };
}
