<template>
  <div class="px-4">
    <Popover v-slot="{ open, close }" class="relative text-center">
      <PopoverButton
        :class="open ? '' : 'text-opacity-90'"
        class="group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <div
          class="headerrighticonblue flex relative font-bold text-xs w-auto cursor-pointer"
        >
          <div class="championiconblue" />
          <a>
            <span class="pl-1">{{ t('selectLeague') }}</span>
          </a>
        </div>
      </PopoverButton>
      <PopoverPanel
        class="absolute z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0"
      >
        <div
          class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div
            class="relative gap-8 bg-white border-2 rounded border-blue-900 pb-6"
          >
            <h1
              class="text-lg mt-0 mb-3 bg-blue-900 text-white text-center py-2"
            >
              {{ t('selectLeague') }}

              <font-awesome-icon
                class="absolute right-3 top-3 cursor-pointer"
                icon="fa-xmark"
                @click="close"
              />
            </h1>
            <ul class="px-6 h-96 overflow-scroll">
              <li
                v-for="(item, index) in bettingState.ftHdpList"
                :key="index"
                class="leading-5 flex items-center"
              >
                <input
                  :id="`league-${index}`"
                  v-model="leagueList"
                  type="checkbox"
                  :value="item.league"
                  @click="select"
                />
                <label class="pl-2" :for="`league-${index}`">
                  {{ item.league?.[locale] }}
                </label>
              </li>
            </ul>
            <div class="pt-4 px-6 font-semibold flex items-center">
              <input
                id="select-all"
                v-model="allSelected"
                type="checkbox"
                class="mr-2 cursor-pointer"
                @click="selectAll"
              />
              <label for="select-all" class="cursor-pointer">
                {{ t('selectAll') }}
              </label>
            </div>
            <button
              class="mt-4 mx-6 font-semibold text-white px-2 py-1 rounded bg-blue-800 text-sm uppercase"
              @click="close()"
            >
              <font-awesome-icon icon="fa-filter" class="text-xs pr-0.1" />
              {{ t('ok') }}
            </button>
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  </div>
</template>

<script lang="ts" setup>
import type { League } from '../interfaces/League.interface';
import type { OddsDetail } from '../interfaces/OddsDetail.interface';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { computed, type Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { BettingTableState } from '../constants/BettingTableState.enum';
import { useBettingStore } from '../store/betting';

const { t, locale } = useI18n();
const bettingStore = useBettingStore();
const bettingState = computed(() => bettingStore.$state);

const allSelected: Ref<boolean> = ref(false);
const leagueList: Ref<League[]> = ref([]);

const setFilterLeague = (hdpList: OddsDetail[]) => {
  if (hdpList?.length > 0) {
    hdpList?.forEach((item: OddsDetail) => {
      leagueList.value.push(item.league);
      bettingState.value.filterLeagueList.push(item.league);
    });
  }
};

const findCurrentOddsState = () => {
  if (bettingState.value.currentBettingTable === BettingTableState.FULL_TIME) {
    setFilterLeague(bettingState.value?.ftHdpList);
  } else if (
    bettingState.value.currentBettingTable === BettingTableState.FIRST_HALF
  ) {
    setFilterLeague(bettingState.value?.fhHdpList);
  } else if (
    bettingState.value.currentBettingTable === BettingTableState.ODDS_EVEN
  ) {
    setFilterLeague(bettingState.value?.hdaList);
  } else if (
    bettingState.value.currentBettingTable === BettingTableState.FULL_BET
  ) {
    setFilterLeague(bettingState.value?.fullBetList);
  }
};
const selectAll = () => {
  leagueList.value = [];
  bettingState.value.filterLeagueList = [];
  allSelected.value = !allSelected.value;

  if (allSelected.value) {
    findCurrentOddsState();
  }
};

const select = (e: MouseEvent) => {
  allSelected.value = false;
  const leagueSelected: League = e?.target?._value;

  const findExistLeague = bettingState.value.filterLeagueList?.find(
    (league: League) => league.en === leagueSelected.en
  );
  if (!findExistLeague) {
    bettingState.value.filterLeagueList?.push(leagueSelected);
  } else {
    bettingState.value.filterLeagueList =
      bettingState.value.filterLeagueList?.filter(
        (league: League) => league.en !== leagueSelected.en
      );
  }
};
</script>

<style>
.championiconblue {
  height: 15px;
  width: 20px;
  background: url(../assets/images/table-icon.png) -5px -584px;
}
</style>
