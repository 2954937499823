export const OddsType = {
  FULL: 'FULL', // fullbet
  HT: 'HT', // live
  FH: 'FH', // first haft
  HDP: 'HDP', // default
  HDA: 'HDA', // 1x2
};

export enum BettingOddsType {
  HDP_AWAY = 'hdpAway',
  HDP_HOME = 'hdpHome',
  OU_OVER = 'ouOver',
  OU_UNDER = 'ouUnder',
  FH_HDP_AWAY = 'fhHdpAway',
  FH_HDP_HOME = 'fhHdpHome',
  FH_OU_OVER = 'fhOuOver',
  FH_OU_UNDER = 'fhOuUnder',
  FH_FT_AWAY = 'fhFtAway',
  FH_FT_DRAW = 'fhFtDraw',
  FH_FT_HOME = 'fhFtHome',
  FT_AWAY = 'ftAway',
  FT_DRAW = 'ftDraw',
  FT_HOME = 'ftHome',
  FH_OU = 'fhOu',
  EVEN = 'even',
  ODD = 'odd',
}

export const FhOddsType = [
  BettingOddsType.FH_HDP_HOME,
  BettingOddsType.FH_HDP_AWAY,
  BettingOddsType.FH_OU_OVER,
  BettingOddsType.FH_OU_UNDER,
];

export const FhFtOddsType = [
  BettingOddsType.FT_HOME,
  BettingOddsType.FT_DRAW,
  BettingOddsType.FT_AWAY,
  BettingOddsType.FH_FT_HOME,
  BettingOddsType.FH_FT_DRAW,
  BettingOddsType.FH_FT_AWAY,
];

export const OeOddsType = [BettingOddsType.EVEN, BettingOddsType.ODD];
