<template>
  <div>
    <div>
      <table class="odds-even odds-details">
        <thead>
          <tr class="">
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
              rowspan="2"
            >
              {{ $t('time') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
              rowspan="2"
              colspan="2"
            >
              {{ $t('event') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
              colspan="3"
            >
              {{ $t('fullTime') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
              colspan="3"
            >
              {{ $t('firstHalf') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
              colspan="2"
            >
              {{ $t('even') }}-{{ $t('odds') }}
            </th>
          </tr>
          <tr>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('home') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('draw') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('away') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('home') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('draw') }}
            </th>
            <th
              class="orange-bg border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('away') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('odds') }}
            </th>
            <th
              class="border-solid border font-bold text-white align-middle leading-6 text-xs"
            >
              {{ $t('even') }}
            </th>
          </tr>
        </thead>
        <tbody v-for="(listItem, index) in list" :key="index">
          <tr v-show="isShowLeague(listItem?.league?.en)">
            <td
              colspan="4"
              class="league-name uppercase text-white text-left font-bold"
            >
              {{ listItem.league?.[locale] }}
            </td>
            <td
              colspan="7"
              class="league-name uppercase text-white text-left font-bold"
            />
          </tr>
          <tr
            v-for="(item, indexItem) in listItem?.record"
            v-show="
              isShowTeamByTime(item, false, bettingState.timeNow) &&
              isShowLeague(listItem?.league?.en)
            "
            :key="indexItem"
            class="odds-group"
          >
            <td>
              {{ timeFormat(item?.koTime) }}
            </td>
            <td>
              <span :class="advColorClass(ADV_SELECTION.HOME_ADV, item?.adv)">
                {{ item?.home?.[locale] }}
              </span>
            </td>
            <td>
              <span :class="advColorClass(ADV_SELECTION.AWAY_ADV, item?.adv)">
                {{ item?.away?.[locale] }}
              </span>
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: '1x2',
                    oddsType: bettingOddsType.FT_HOME,
                    value: item?.ftHome,
                    oldValue: item?.ftHome,
                    oldValue: item?.ftHome,
                    label: item?.home,
                    odds: '',
                    adv: item?.adv,
                    isHome: true,
                    oddsLine: item?.ftHome,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ item?.ftHome }}
                </a>
              </span>
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: '1x2',
                    oddsType: bettingOddsType.FT_DRAW,
                    value: item?.ftDraw,
                    oldValue: item?.ftDraw,
                    label: `${$t('draw')}`,
                    odds: '',
                    adv: item?.adv,
                    oddsLine: item?.ftDraw,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ item?.ftDraw }}
                </a>
              </span>
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: '1x2',
                    oddsType: bettingOddsType.FT_AWAY,
                    value: item?.ftAway,
                    oldValue: item?.ftAway,
                    label: item?.away,
                    odds: '',
                    adv: item?.adv,
                    isHome: false,
                    isHome: true,
                    oddsLine: item?.ftAway,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ item?.ftAway }}
                </a>
              </span>
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: '1x2',
                    oddsType: bettingOddsType.FH_FT_HOME,
                    value: item?.fhFtHome,
                    oldValue: item?.fhFtHome,
                    label: `(1st) ${item?.home?.[locale]}`,
                    odds: '',
                    adv: item?.adv,
                    isHome: true,
                    oddsLine: item?.fhFtHome,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ item?.fhFtHome }}
                </a>
              </span>
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: '1x2',
                    oddsType: bettingOddsType.FH_FT_DRAW,
                    value: item?.fhFtDraw,
                    oldValue: item?.fhFtDraw,
                    label: `(1st) ${$t('draw')}`,
                    odds: '',
                    adv: item?.adv,
                    oddsLine: item?.fhFtDraw,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ item?.fhFtDraw }}
                </a>
              </span>
            </td>
            <td
              class="orange-bg"
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: '1x2',
                    oddsType: bettingOddsType.FH_FT_AWAY,
                    value: item?.fhFtAway,
                    oldValue: item?.fhFtAway,
                    label: `(1st) ${item?.away?.[locale]}`,
                    odds: '',
                    adv: item?.adv,
                    isHome: false,
                    oddsLine: item?.fhFtAway,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ item?.fhFtAway }}
                </a>
              </span>
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ou',
                    oddsType: bettingOddsType.ODD,
                    value: item?.odd,
                    oldValue: item?.odd,
                    label: `${$t('odd')}`,
                    odds: '',
                    adv: item?.adv,
                    oddsLine: item?.odd,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ item?.odd }}
                </a>
              </span>
            </td>
            <td
              @click="
                onClickOdds(
                  {
                    id: item?.id,
                    matchId: item?.matchId,
                    betType: 'ou',
                    oddsType: bettingOddsType.EVEN,
                    value: item?.even,
                    oldValue: item?.even,
                    label: `${$t('even')}`,
                    odds: '',
                    adv: item?.adv,
                    oddsLine: item?.even,
                  },
                  item
                )
              "
            >
              <span>
                <a
                  class="odds cursor-pointer block border-none w-full m-0 p-0 whitespace-nowrap text-center leading-7 font-bold"
                >
                  {{ item?.even }}
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { BettingTableStateKey } from '@/constants/BettingTableState.enum';
import { ADV_SELECTION } from '@/constants/Ticket.enum';
import { useAdvTextColor } from '@/composition/adv-color';
import { useUnixTimeToDate } from '@/composition/datetime-format';
import { useBettingStore } from '../../store/betting';
import { BettingOddsType, OeOddsType } from '@/constants/OddsType.enum';
import { useLeagueDisplay } from '../../composition/league-display';

import type { Hda } from '@/interfaces/Hda.interface';
import type { HdaMatch } from '@/interfaces/HdaMatch.interface';
import type { TeamSelected } from '../../interfaces/TeamSelected.interface';
import { useMemberStore } from '../../store/member';

export default defineComponent({
  name: 'OddsEvenTable',
  props: {
    list: {
      type: Array as PropType<Hda[]>,
      required: true,
    },
  },
  emits: ['click:match', 'click:odds'],
  setup(_, { emit }) {
    const { locale } = useI18n();
    const bettingStore = useBettingStore();
    const memberStore = useMemberStore();
    const bettingOddsType = computed(() => BettingOddsType);
    const { isShowLeague, isShowTeamByTime } = useLeagueDisplay();

    const currentTable = computed(
      () => bettingStore.$state.currentBettingTable
    );
    const member = computed(() => memberStore.member);

    const bettingState = computed(() => bettingStore.$state);
    const bettingTableStateKey = computed(() => BettingTableStateKey);

    const timeFormat = (accDate: string) => {
      return useUnixTimeToDate(accDate, 'HH:mm');
    };
    const advColorClass = (currentAdv: number, advValue: number) => {
      return useAdvTextColor(currentAdv, advValue);
    };

    const onClickOdds = (teamSelected: TeamSelected, matchItem: HdaMatch) => {
      if (OeOddsType.some((el) => el === teamSelected.oddsType)) {
        if (member.value?.isOe) {
          if (teamSelected.value >= 0) {
            emit('click:odds', teamSelected);
            emit('click:match', matchItem);
          }
        } else {
          bettingStore.bettingErrorMessage =
            'ยูซเซอร์ไม่สามารถแทงประเภท คู่-คี่ ได้';
          bettingStore.isShowBettingModal = true;
        }
      } else {
        if (teamSelected.value >= 0) {
          emit('click:odds', teamSelected);
          emit('click:match', matchItem);
        }
      }
    };

    return {
      bettingOddsType,
      currentTable,
      bettingTableStateKey,
      onClickOdds,
      locale,
      timeFormat,
      ADV_SELECTION,
      advColorClass,
      isShowLeague,
      isShowTeamByTime,
      bettingState,
    };
  },
});
</script>

<style>
table.odds-even.odds-details tr:nth-child(1) th:nth-child(1),
table.odds-even.odds-details tr:nth-child(2) th {
  width: 6%;
}
</style>
