<template>
  <table class="record-table">
    <thead class="sticky top-8">
      <tr>
        <th>{{ $t('numberTitle') }}</th>
        <th>{{ $t('info') }}</th>
        <th>{{ $t('detail') }}</th>
        <th>{{ $t('odds') }}</th>
        <th>{{ $t('stake') }}</th>
        <th>{{ $t('commission') }}</th>
        <th>{{ $t('status') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(ticket, index) in list"
        :key="index"
        :class="{ 'opacity-75 !bg-slate-300': ticket.active.en === 'cancel' }"
        class="hover:!bg-[#fff]"
      >
        <td class="text-center px-2">
          {{ index + 1 }}
        </td>
        <td class="text-center px-2 w-32">
          <p class="font-bold">
            REF
            <span class="text-[#002bff]"># {{ ticket?.id }}</span>
          </p>
          <p class="grid ticket-time font-medium">
            <span>
              {{ dateFormat(ticket?.time) }}
            </span>
            <span>
              {{ timeFormat(ticket?.time) }}
            </span>
          </p>
        </td>
        <td class="pb-3 pt-1">
          <div
            v-for="(parlay, indexParlay) in ticket?.parlay"
            :key="indexParlay"
            class="bet-details p-2 leading-[1.3em] border-b-1"
          >
            <p class="info font-bold">
              <span
                :class="colorSelection(parlay?.isAdv)"
                class="pr-1 underline"
              >
                {{ parlay?.selection?.[locale] }}
              </span>
              <mark class="bg-[#fff] px-0.5">
                <span>{{ parlay.oddsLine }}</span>
                <span class="px-0.5">@</span>
                <span>{{ parlay.odds }}</span>
              </mark>
            </p>
            <p class="font-bold text-[#060]">
              {{ parlay?.betType }}
            </p>
            <p class="match">
              <span
                :class="parlay?.adv === 1 ? 'text-[#e8000d]' : 'text-[#002bff]'"
                class="font-bold"
              >
                {{ parlay?.home?.[locale] }}
              </span>
              <span class="px-0.5 text-[#002bff]">-v-</span>
              <span
                :class="parlay?.adv === 2 ? 'text-[#e8000d]' : 'text-[#002bff]'"
                class="font-bold"
              >
                {{ parlay?.away?.[locale] }}
              </span>
            </p>
            <p class="league text-[#060] font-medium">
              {{ parlay?.league?.[locale] }}
            </p>
            <p class="time font-bold italic text-[#666]">
              {{ formatDate(parlay?.koTime) }}
            </p>
            <mark
              v-show="parlay?.status"
              class="result bg-[#FC0] text-black font-bold px-1"
            >
              <span v-html="parlay?.result?.text?.[locale]" />
              @ {{ parlay?.result?.score }}
            </mark>
          </div>
        </td>
        <td class="text-center font-medium px-2">
          <span>{{ formatNumber(ticket?.totalOdds) }}</span>
        </td>
        <td class="text-center px-2">
          <strong>{{ formatNumber(ticket?.stake) }}</strong>
        </td>
        <td class="text-center font-medium px-2">
          <span>{{ formatNumber(ticket?.wlCommMember) }}</span>
        </td>
        <td class="text-center capitalize">
          <strong>{{ ticket?.active?.[locale] }}</strong>
        </td>
      </tr>
    </tbody>
    <tfoot class="bg-yellow-400">
      <tr>
        <th colspan="3">Total:</th>
        <th colspan="4">
          {{ totalStake }}
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, defineComponent, type PropType } from 'vue';
import { useNumberUtils } from '../../composition/number-utils';
import dayjs from 'dayjs';

import type { Ticket } from '../../interfaces/api/Ticket.interface';

export default defineComponent({
  name: 'BetList',
  props: {
    list: {
      type: Array as PropType<Ticket[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const { formatNumber } = useNumberUtils();
    const colorSelection = (isAdv: boolean) => {
      return isAdv ? 'text-red-500' : 'text-blue-700';
    };

    const formatDate = (unixTime: number) => {
      return dayjs(unixTime * 1000).format('DD-MM-YYYY HH:mm');
    };
    const totalStake = computed(() => {
      const amount = props.list?.reduce(
        (acc, item: Ticket) => acc + Number(item.stake),
        0
      );

      return formatNumber(Number(amount));
    });

    const dateFormat = (dateTime: string) => {
      return dayjs(dateTime).format('YYYY-MM-DD');
    };

    const timeFormat = (dateTime: string) => {
      return dayjs(dateTime).format('HH:mm:ss');
    };

    return {
      dateFormat,
      timeFormat,
      locale,
      totalStake,
      colorSelection,
      formatDate,
      formatNumber,
    };
  },
});
</script>
