import { defineStore } from 'pinia';

interface ModalStore {
  isShow: boolean;
  isSuccess: boolean;
  message: string | null;
  isSessionExpired: boolean;
}

export const useModalStore = defineStore('modal', {
  state: (): ModalStore => {
    return {
      isShow: false,
      isSuccess: false,
      message: null,
      isSessionExpired: false,
    };
  },
});
