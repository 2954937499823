<template>
  <div class="inner-container font-medium">
    <div class="head">
      <h1 class="font-bold">
        <font-awesome-icon :icon="icon" />
        {{ title }}
      </h1>
    </div>
    <div class="body">
      <slot />
    </div>
    <div class="foot" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatementPage',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
});
</script>
<style lang="scss">
.inner-container {
  box-shadow: 1px 0 3px 0 #666;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .head {
    font-size: 1.2em;
    padding: 8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #233b7a;
    background-color: #dee9f9;
    zoom: 1;
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFDEE9F9', endColorstr='#FFBED5F4');
    background-size: 100%;
    background-image: linear-gradient(to bottom, #dee9f9 0, #bed5f4 100%);
  }

  .body {
    background-color: #e2e8ff;
    padding: 10px;
    min-height: 300px;
  }

  .foot {
    padding: 10px;
    background-color: #c8dbf5;
    -ms-box-shadow: inset 0 1px 3px 1px #b3ccf1;
    -o-box-shadow: inset 0 1px 3px 1px #b3ccf1;
    box-shadow: inset 0 1px 3px 1px #b3ccf1;
  }
}
</style>
