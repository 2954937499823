export const mockProfile = {
  balance: '9770.36',
  isSingle: true,
  language: 'en',
  maxBet: '20000.00',
  maxPayout: '1000000.00',
  maxSingle: '200000.00',
  minBet: '10.00',
  outstanding: '0.00',
  parlayMax: 15,
  parlayMin: 2,
  username: '00770077zzz',
};
