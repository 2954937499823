<template>
  <div class="max-w-5xl mx-auto">
    <header-app />
    <div class="flex gap-2">
      <div class="l-column relative">
        <AccountInfo class="w-[inherit]" />
        <TicketBlock class="w-[inherit]" />
      </div>
      <div class="c-column w-full">
        <router-view />
      </div>
    </div>
    <teleport to="body">
      <notify-modal
        :message="modalMessage ?? ''"
        :is-open="modalIsShow"
        :is-success="modalIsSuccess"
        @close-notify-modal="onCloseNotifyModal"
      />
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import HeaderApp from '@/components/HeaderApp.vue';
import TicketBlock from '@/components/TicketBlock.vue';
import AccountInfo from '@/components/AccountInfo.vue';
import NotifyModal from '@/components/NotifyModal.vue';
import { useModalStore } from '@/store/modal';
import { useStorage } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { useMemberStore } from '@/store/member';

export default defineComponent({
  name: 'LayoutPrivate',
  components: {
    HeaderApp,
    TicketBlock,
    AccountInfo,
    NotifyModal,
  },

  setup() {
    const modalStore = useModalStore();
    const router = useRouter();
    const memberStore = useMemberStore();

    const modalState = computed(() => modalStore.$state);
    const modalMessage = computed(() => modalState.value.message);
    const modalIsShow = computed(() => modalState.value.isShow);
    const modalIsSuccess = computed(() => modalState.value.isSuccess);

    const onCloseNotifyModal = async () => {
      if (modalState.value.isSessionExpired) {
        const token = useStorage('APP_TOKEN', null);
        token.value = null;
        await router.replace('/login');
      }
      modalState.value.isShow = false;
    };

    onMounted(() => {
      memberStore.getMember();
    });

    return {
      onCloseNotifyModal,
      modalMessage,
      modalIsShow,
      modalIsSuccess,
    };
  },
});
</script>

<style>
.l-column {
  width: 190px;
}
.l-column-bottom {
  width: 100%;
}
.c-column {
  height: auto !important;
  margin-bottom: 15px;
  font-size: 11px;
}
</style>
