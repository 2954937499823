<template>
  <div
    class="flex justify-center blue-bar text-gray-50 text-center fixed w-full left-0 top-0 font-medium"
  >
    <div class="container flex flex-col justify-center blue-bar-inner p-0">
      <div class="flex items-center justify-end">
        <div class="flex items-center w-3/5">
          <div class="text-xs animate-marquee w-full">
            League88 เว็ปบอลสเต็ปที่ดีที่สุด ยินดีต้อนรับค่ะ
          </div>
        </div>
        <div class="flex gap-10 items-center">
          <div v-show="!loading" class="flex gap-10 items-center">
            <span class="text-sm pl-1">
              <font-awesome-icon
                icon="fa-regular fa-futbol"
                class="mr-0.5 text-xs"
              />
              {{ memberState?.member?.username }}
            </span>
            <div class="flex items-center">
              <font-awesome-icon
                icon="fa-regular fa-money-bill-1"
                class="mr-1 text-xs"
              />
              <span class="text-sm">THB</span>
              <strong class="text-yellow-theme text-sm pl-1">
                {{ balanceFormatted }}
              </strong>
            </div>
          </div>
          <div v-show="loading" class="text-sm">
            <font-awesome-icon
              icon="fa-solid fa-spinner"
              class="animate-spin mr-1"
            />
            Loading...
          </div>
          <button
            class="font-bold text-sm no-underline hover:text-yellow-theme"
            @click="onClickLogout"
          >
            {{ t('logout') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNumberUtils } from '../composition/number-utils';
import { useAuthStore } from '../store/auth';
import { useMemberStore } from '../store/member';
import { useLoadingStore } from '../store/wait';

const { t } = useI18n();
const authStore = useAuthStore();
const memberStore = useMemberStore();
const loadingStore = useLoadingStore();
const { formatNumber } = useNumberUtils();

const memberState = computed(() => memberStore.$state);
const loading = computed(() => loadingStore.is('getMemberLoading'));
const balance: ComputedRef<string | undefined> = computed(
  () => memberState.value.member?.balance
);

const balanceFormatted = computed(() => {
  if (balance.value) {
    return formatNumber(balance.value);
  }
  return '0';
});

const onClickLogout = () => {
  authStore.logout();
};
</script>

<style>
.blue-bar {
  height: 33px;
  background-color: #233b7a;
  position: fixed;
  z-index: 101;
}
.blue-bar a {
  color: #e4e4e4;
}
.main .blue-bar-inner {
  width: 960px;
}
</style>
