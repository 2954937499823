<template>
  <div class="font-medium">
    <div class="font-bold text-blue-700">
      {{ parlayItem?.betType }}
    </div>
    <div class="italic">
      <span :class="parlayItem.adv === 1 ? 'text-[#c72600]' : 'text-[#0037cc]'">
        {{ parlayItem.home?.[locale] }}
      </span>
      -vs-
      <span :class="parlayItem.adv === 2 ? 'text-[#c72600]' : 'text-[#0037cc]'">
        {{ parlayItem.away?.[locale] }}
      </span>
    </div>
    <div class="italic">
      [
      <span v-html="parlayItem.oddsLine" />
      ] @ {{ parlayItem.odds }}
    </div>
    <div
      class="underline font-bold"
      :class="parlayItem?.isAdv ? 'text-[#c72600]' : 'text-[#0037cc]'"
    >
      {{ parlayItem.selection?.[locale] }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Parlay } from '../../interfaces/api/Parlay.interface';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'MyBetList',
  props: {
    parlayItem: {
      type: Object as PropType<Parlay>,
      default: () => ({}),
    },
  },
  setup() {
    const { locale } = useI18n();

    return {
      locale,
    };
  },
});
</script>
