<template>
  <navbar-status />
  <div class="mb-2 mt-8">
    <div class="common-header-bg header-inner ml-0">
      <main-menu />
      <menu-info />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavbarStatus from '@/components/NavbarStatus.vue';
import MainMenu from '@/components/Menu/MainMenu.vue';
import MenuInfo from '@/components/Menu/MenuInfo.vue';

export default defineComponent({
  name: 'CounterButton',
  components: {
    NavbarStatus,
    MainMenu,
    MenuInfo,
  },
});
</script>

<style>
.common-header-bg {
  background-image: url(../assets/images/common-header.png);
}
.header-inner {
  height: 96px;
  background-position: 0 -30px;
  margin: 0 auto;
}
.body-inner .header-inner {
  width: 100%;
  height: 96px;
  background-position: 0 -30px;
  box-shadow: 0 0 3px 1px #666;
}
</style>
