<template>
  <div class="markets-content">
    <ul>
      <li :class="activeClass(bettingTableState.FULL_TIME)" class="market">
        <a
          class="text-xs block cursor-pointer relative font-bold"
          @click="onClickMarketMenu(bettingTableState.FULL_TIME)"
        >
          <font-awesome-icon
            v-show="activeClass(bettingTableState.FULL_TIME)"
            icon="fa-solid fa-caret-right"
            class="left-1"
          />
          {{ $t('hdpOu') }}
          <span class="count text-white text-xs relative">
            {{ bettingState?.market?.hdp }}
          </span>
        </a>
      </li>
      <li :class="activeClass(bettingTableState.FIRST_HALF)" class="market">
        <a
          class="text-xs block cursor-pointer relative font-bold"
          @click="onClickMarketMenu(bettingTableState.FIRST_HALF)"
        >
          <font-awesome-icon
            v-show="activeClass(bettingTableState.FIRST_HALF)"
            icon="fa-solid fa-caret-right"
            class="left-1"
          />
          {{ $t('fhHdpOu') }}
          <span class="count text-white text-xs relative">
            {{ bettingState?.market?.fh }}
          </span>
        </a>
      </li>
      <li :class="activeClass(bettingTableState.ODDS_EVEN)" class="market">
        <a
          class="text-xs block cursor-pointer relative font-bold"
          @click="onClickMarketMenu(bettingTableState.ODDS_EVEN)"
        >
          <font-awesome-icon
            v-show="activeClass(bettingTableState.ODDS_EVEN)"
            icon="fa-solid fa-caret-right"
            class="left-1"
          />
          {{ $t('oddsEven') }}
          <span class="count text-white text-xs relative">
            {{ bettingState?.market?.hda }}
          </span>
        </a>
      </li>
      <li :class="activeClass(bettingTableState.FULL_BET)" class="market">
        <a
          class="text-xs block cursor-pointer relative font-bold"
          @click="onClickMarketMenu(bettingTableState.FULL_BET)"
        >
          <font-awesome-icon
            v-show="activeClass(bettingTableState.FULL_BET)"
            icon="fa-solid fa-caret-right"
            class="left-1"
          />
          {{ $t('fullBet') }}
          <span class="count text-white text-xs relative">
            {{ bettingState?.market?.full }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { BettingTableState } from '@/constants/BettingTableState.enum';
import { useBettingStore } from '../store/betting';

export default defineComponent({
  name: 'MarketList',
  setup() {
    const bettingStore = useBettingStore();
    const bettingState = computed(() => bettingStore.$state);

    const bettingTableState = computed(() => BettingTableState);
    const currentTable = computed(
      () => bettingStore.$state.currentBettingTable
    );
    const market = computed(() => bettingStore.$state.market);

    const onClickMarketMenu = (bettingTableState: BettingTableState) => {
      bettingStore.$state.currentBettingTable = bettingTableState;
    };

    const activeClass = (bettingTableState: string) => {
      if (bettingTableState === currentTable.value) {
        return 'selected';
      }
    };
    return {
      bettingState,
      bettingTableState,
      onClickMarketMenu,
      activeClass,
      market,
    };
  },
});
</script>

<style>
.markets-content li {
  border-bottom: 1px solid #cad8fc;
  border-collapse: separate;
}
.markets-content li a {
  color: #000;
  padding: 7px 14px 9px 18px;
}
.markets-content li a:hover {
  background-color: #2d4694;
  color: #fff;
}
.markets-content li a:hover span {
  background-color: #5e9ac8;
  box-shadow: 0 0 1px 1px #d6e8fe;
}
.markets-content li a span {
  float: right;
  padding: 4px 6px;
  background-color: #3f5ead;
  top: -3px;
  border-radius: 4px;
  box-shadow: 0 0 1px 1px #3f5ead;
}
.markets-content li.selected a {
  padding-left: 8px;
  color: #4385b8;
  /* background: url(../assets/images/header-tab-bg.png) no-repeat 7px -423px; */
}
.markets-content li.selected a:hover {
  background-color: #d6e8fe;
}
</style>
