<script setup lang="ts">
import { useLocalStorage } from '@vueuse/core';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  const { token } = route.query;
  const authToken = useLocalStorage('APP_TOKEN', null || '');
  authToken.value = token as string;
  if (authToken.value) {
    await router.push({ name: 'rules' });
  } else {
    await router.push({ name: 'login' });
  }
});
</script>

<template>
  <div />
</template>
