<template>
  <table class="record-table">
    <thead class="sticky top-8">
      <tr>
        <th>{{ $t('date') }}</th>
        <th>{{ $t('remark') }}</th>
        <th>{{ $t('type') }}</th>
        <th>{{ $t('totalTurnOver') }}</th>
        <th>{{ $t('commission') }}</th>
        <th>{{ $t('totalWinLose') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(statement, index) in list"
        :key="index"
        class="cursor-pointer hover:!bg-[#FADF30]"
        @click="onClickStatement(statement?.accDate)"
      >
        <td class="date font-bold px-3 py-1">
          {{ statement?.date?.[locale] }}
        </td>
        <td class="list text-center font-medium capitalize">
          {{ statement?.subject?.[locale] }}
        </td>
        <td class="type text-center font-medium text-blue-700 capitalize">
          {{ statement?.type?.[locale] }}
        </td>
        <td class="font-bold text-right pr-2.5 w-20">
          {{ statement?.stake }}
        </td>
        <td class="font-bold text-right pr-2.5 w-20 text-blue-600">
          {{ statement?.comm }}
        </td>
        <td
          class="font-bold text-right pr-2.5 w-24"
          :class="amountColor(statement?.intTotal)"
        >
          {{ formatNumber(statement?.intTotal) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUnixTimeToDate } from '@/composition/datetime-format';
import { useNumberUtils } from '../../composition/number-utils';

import type { Statement } from '../../interfaces/api/Statement.interface';

export default defineComponent({
  name: 'StatementTable',
  props: {
    list: {
      type: Array as PropType<Statement[]>,
      default: () => [],
    },
  },
  emits: ['click-statement'],
  setup(_, { emit }) {
    const { locale } = useI18n();
    const { formatNumber } = useNumberUtils();

    const amountColor = (amount: number) => {
      return amount <= 0 ? 'text-red-500' : 'text-blue-600';
    };
    const formatDate = (date: string) => useUnixTimeToDate(date);

    const onClickStatement = (accDate: string) => {
      emit('click-statement', accDate);
    };

    return {
      locale,
      amountColor,
      formatDate,
      onClickStatement,
      formatNumber,
    };
  },
});
</script>
