import numbro from 'numbro';

export function useNumberUtils() {
  const formatNumber = (numberValue: number | string, format?: string) => {
    if (numberValue) {
      return numbro(numberValue).format(format || '0,0.00');
    }
    return '0';
  };

  return {
    formatNumber,
  };
}
