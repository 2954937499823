<template>
  <main-body :title="t('account')" icon="th-list">
    <div class="profile-wrap flex">
      <div class="profile-tab w-1/4">
        <ul>
          <li
            :class="activeTabClass(profileTab.BETTING_INFO)"
            class="border-none text-center shadow-none cursor-pointer"
          >
            <a
              class="text-white block p-2"
              @click="onClickTabMenu(profileTab.BETTING_INFO)"
            >
              {{ t('bettingDetail') }}
            </a>
          </li>
          <li
            :class="activeTabClass(profileTab.CHANGE_PASSWORD)"
            class="border-none text-center shadow-none cursor-pointer mt-1"
          >
            <a
              class="text-white block p-2"
              @click="onClickTabMenu(profileTab.CHANGE_PASSWORD)"
            >
              {{ t('changePassword') }}
            </a>
          </li>
          <li
            :class="activeTabClass(profileTab.PREFERENCE)"
            class="border-none text-center shadow-none cursor-pointer mt-1"
          >
            <a
              class="text-white block p-2"
              @click="onClickTabMenu(profileTab.PREFERENCE)"
            >
              {{ t('profileSetting') }}
            </a>
          </li>
        </ul>
      </div>
      <div class="w-full pl-2">
        <container-betting-info v-if="currentTab === profileTab.BETTING_INFO" />
        <container-change-password
          v-if="currentTab === profileTab.CHANGE_PASSWORD"
        />
        <container-preference v-if="currentTab === profileTab.PREFERENCE" />
      </div>
    </div>
  </main-body>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { computed, defineComponent, type Ref } from 'vue';
import MainBody from '@/components/MainBody.vue';
import ContainerBettingInfo from '@/containers/Profile/ContainerBettingInfo.vue';
import ContainerChangePassword from '@/containers/Profile/ContainerChangePassword.vue';
import ContainerPreference from '@/containers/Profile/ContainerPreference.vue';
import { CurrentTab } from '@/constants/Profile.enum';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ProfilePage',
  components: {
    MainBody,
    ContainerBettingInfo,
    ContainerChangePassword,
    ContainerPreference,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const currentTab: Ref<string> = computed(
      () => store.state.profile.currentTab
    );
    const profileTab = computed(() => CurrentTab);

    const onClickTabMenu = (currentTab: string) => {
      store.commit('profile/setCurrentTab', currentTab);
    };

    const activeTabClass = (menuTab: string) => {
      if (currentTab.value === menuTab) {
        return 'active';
      }
      return '';
    };

    return { t, currentTab, onClickTabMenu, profileTab, activeTabClass };
  },
});
</script>

<style lang="scss">
.profile-wrap {
  .profile-tab {
    ul {
      li {
        background-color: #4c6eb4;
        background-size: 100%;
        background-image: linear-gradient(#94a8d2, #4c6eb4);
        &:hover {
          background-color: #1885c7;
          background-size: 100%;
          background-image: linear-gradient(#5ab4eb, #1885c7);
        }
      }
      li.active {
        background-color: #ab5500;
        background-size: 100%;
        background-image: linear-gradient(#ff8812, #ab5500);
        box-shadow: 1px 1px 1px 1px #666;
      }
    }
  }
}
</style>
