import * as dayjs from 'dayjs';
import 'dayjs/locale/th'; // import locale
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import localizedFormat from 'dayjs/plugin/localizedFormat';

const lang = localStorage.getItem('lang') || 'th';

dayjs.extend(localizedFormat);
dayjs.locale(lang); // use locale
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.tz.setDefault('Asia/Bangkok');
