<template>
  <div>
    <form name="profileForm">
      <table
        class="change-password-table w-full shadow-md border-solid border-1"
      >
        <thead>
          <tr>
            <th colspan="2" class="py-1 text-white">
              {{ $t('preference') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="verify-password text-right pr-1">
              {{ $t('defaultLanguage') }}
            </td>
            <td class="pl-3 py-1">
              <select class="border border-blue-500 rounded">
                <option value="en">English</option>
                <option value="th">Thai</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="pl-48 py-1">
              <button
                type="submit"
                class="btn btn-blue text-white font-bold px-3 py-1 rounded"
              >
                {{ $t('ok') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContainerPreference',
});
</script>

<style lang="scss">
.change-password-table {
  td {
    border-right: 1px solid #b0c6ff;
    border-bottom: 1px solid #b0c6ff;
    border-left-width: 0;
    border-top-width: 0;
    &:nth-child(1) {
      width: 30%;
    }
  }
  th {
    border-right: 1px solid #b0c6ff;
    border-bottom: 1px solid #b0c6ff;
    border-left-width: 0;
    border-top-width: 0;
  }
  thead {
    background-color: #4c69b8;
  }
}
.btn-blue {
  background-color: #375aab;
  box-shadow: 1px 1px 1px 1px #aaa;
}
</style>
