import { BettingTableState } from '@/constants/BettingTableState.enum';
import { HdpType } from '@/constants/HdpType.enum';

export default () => ({
  currentBettingTable: BettingTableState.FULL_TIME,
  ftHdpList: [],
  fhHdpList: [],
  hdaList: [],
  fullBetList: [],
  ticketList: [],
  selectedTeamList: [],
  hdpLiveList: [],
  market: {
    hdp: 0,
    fh: 0,
    hda: 0,
    full: 0,
  },
  hdpType: HdpType.HDP_ONE,
  liveList: null,
});
