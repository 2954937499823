import { defineStore } from 'pinia';

export interface WaitState {
  waitingFor: string[];
}

function uniqArray(array: string[]) {
  return array.filter((el, index, arr) => index === arr.indexOf(el));
}

export function start(waitingFor: string[], waiter: string): string[] {
  return uniqArray([...waitingFor, waiter]);
}

export function end(waitingFor: string[], waiter: string): string[] {
  return uniqArray(waitingFor).filter((l) => l !== waiter);
}

export const useLoadingStore = defineStore('wait', {
  state: () => ({
    version: '0.0.1',
    waitingFor: [''],
  }),
  actions: {
    async start(loadingName: string) {
      this.waitingFor = start(this.waitingFor, loadingName);
    },

    async end(loadingName: string) {
      this.waitingFor = end(this.waitingFor, loadingName);
    },
  },
  getters: {
    is:
      (state: WaitState) =>
      (waiter: string): boolean => {
        return state.waitingFor.includes(waiter);
      },
  },
});
