import axios from 'axios';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useStorage } from '@vueuse/core';

import type { AxiosInstance, AxiosRequestConfig } from 'axios';

function getBearer() {
  const token = useStorage('APP_TOKEN', null);
  return `Bearer ${token.value}`;
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_HTTP,
  headers: {
    Authorization: getBearer(),
  },
});

axiosInstance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = getBearer();
  }
  return config;
});

const apiFetch = (url: string, config?: AxiosRequestConfig) => {
  if (config) {
    return useAxios(url, config, axiosInstance);
  }

  return useAxios(url, axiosInstance);
};

export { axiosInstance, apiFetch };
