<template>
  <div class="content">
    <div class="inside-content" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'MainContent',
};
</script>
<style scoped>
.content,
.inside-content {
  background: url(../../assets/images/landing-page.png) no-repeat;
}
.content {
  background: url(../../assets/images/landing-page.png) no-repeat -147px -457px;
  margin: 0 0 0 5px;
  position: relative;
  height: 102px;
}
.inside-content {
  background-position: 0 -1019px;
  height: 84px;
  margin: 12px 0 0 10px;
  position: absolute;
  width: 970px;
  border: 1px solid #2a4696;
}
@media (max-width: 639px) {
  .content {
    background: #bad1f7;
    width: 98%;
    overflow: hidden;
    padding: 4px;
    margin: 16px 0 0 3px;
  }
  .inside-content {
    width: 95%;
    margin: 6px 0 0 5px;
  }
}
@media (max-width: 320px) {
  .box-xs.right img {
    margin: 0 0 0 5px;
  }
}
</style>
