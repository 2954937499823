<template>
  <table class="betting-info w-full shadow-md border-solid border-1">
    <thead>
      <tr>
        <th
          colspan="2"
          class="text-center font-bold border-t-0 border-l-0 p-0.5 border-b-1 text-white py-1"
        >
          {{ $t('bettingInfo') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-center py-1.5">
          {{ $t('maxPerMatch') }}
        </td>
        <td class="text-center py-1.5 font-bold">
          {{ maxSingle }}
        </td>
      </tr>
      <tr>
        <td class="text-center py-1.5">
          {{ $t('maxPayout') }}
        </td>
        <td class="text-center py-1.5 font-bold">
          {{ maxPayout }}
        </td>
      </tr>
      <tr>
        <td class="text-center py-1.5">
          {{ $t('minBet') }}
        </td>
        <td class="text-center py-1.5 font-bold">
          {{ betMin }}
        </td>
      </tr>
      <tr>
        <td class="text-center py-1.5">
          {{ $t('maxBet') }}
        </td>
        <td class="text-center py-1.5 font-bold">
          {{ betMax }}
        </td>
      </tr>
      <tr>
        <td class="text-center py-1.5">
          {{ $t('parlayMin') }}
        </td>
        <td class="text-center py-1.5 font-bold">
          {{ parlayMin }} - {{ parlayMax }}
        </td>
      </tr>
      <tr>
        <td class="text-center py-1.5">
          {{ $t('parlayMax') }}
        </td>
        <td class="text-center py-1.5 font-bold">
          {{ parlayMax }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useMemberStore } from '../../store/member';
import { useNumberUtils } from '../../composition/number-utils';

export default defineComponent({
  name: 'ContainerBettingInfo',
  setup() {
    const { formatNumber } = useNumberUtils();
    const memberStore = useMemberStore();

    memberStore.getMember();

    const memberState = computed(() => memberStore.$state);

    const maxSingle = computed(() => {
      if (memberState.value.member?.maxSingle) {
        return formatNumber(memberState.value.member?.maxSingle);
      }
      return '0';
    });

    const maxPayout = computed(() => {
      if (memberState.value.member?.maxPayout) {
        return formatNumber(memberState.value.member?.maxPayout);
      }
      return '0';
    });

    const betMin = computed(() => {
      if (memberState.value.member?.betMin) {
        return formatNumber(memberState.value.member?.betMin);
      }
      return '0';
    });

    const betMax = computed(() => {
      if (memberState.value.member?.betMax) {
        return formatNumber(memberState.value.member?.betMax);
      }
      return '0';
    });

    const parlayMin = computed(() => {
      if (memberState.value.member?.parlayMin) {
        return formatNumber(memberState.value.member?.parlayMin, '0,0');
      }
      return '0';
    });

    const parlayMax = computed(() => {
      if (memberState.value.member?.parlayMax) {
        return formatNumber(memberState.value.member?.parlayMax, '0,0');
      }
      return '0';
    });

    return { maxSingle, maxPayout, betMin, betMax, parlayMin, parlayMax };
  },
});
</script>

<style lang="scss">
table.betting-info {
  td {
    border-right: 1px solid #b0c6ff;
    border-bottom: 1px solid #b0c6ff;
    &:last-child {
      border-right-width: 0;
    }
  }
  th {
    border-right: 1px solid #b0c6ff;
    border-bottom: 1px solid #b0c6ff;
    background-color: #4c69b8;
    &:last-child {
      border-right-width: 0;
    }
    &:first-child {
      border-right: 1px solid #b0c6ff;
    }
  }
  tbody {
    tr {
      &:last-child {
        td {
          border-bottom-width: 0;
        }
      }
    }
  }
  thead {
    th {
      border-bottom: 1px solid #b0c6ff;
    }
    background-color: #4c69b8;
    border-color: #b0c6ff;
  }
  tr {
    &:nth-child(2n + 1) {
      td {
        background-color: #bbdede;
        &:nth-child(2n) {
          background-color: #bbdede;
        }
      }
    }
  }
}
</style>
