import { defineStore } from 'pinia';
import { apiFetch } from '../composition/useAxios';
import type { TicketStore } from './TicketStore.interface';

export const useTicketStore = defineStore('ticket', {
  state: (): TicketStore => {
    return {
      version: '1.0.0',
      ticketList: [],
      fullTicketList: [],
      ticketParlayList: [],
    };
  },
  actions: {
    async getTicketList() {
      try {
        const { data } = await apiFetch('/ticket/list ', { method: 'GET' });
        if (data?.value) {
          this.ticketList = data.value;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFullTicketList() {
      try {
        const { data } = await apiFetch('/ticket/list/full ', {
          method: 'GET',
        });
        if (data?.value) {
          this.fullTicketList = data.value;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getTicketParlayList(ticketId: number) {
      try {
        const { data } = await apiFetch(`/ticket/${ticketId}/parlay/list`, {
          method: 'GET',
        });
        if (data?.value) {
          this.ticketParlayList = data.value;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
