import type { Module } from 'vuex';
import type { RootState } from '@/vuex-store/RootState.interface';
import state from './state';
import type { TicketState } from './TicketState.interface';

const ticketModule: Module<TicketState, RootState> = {
  namespaced: true,
  state,
};

export default ticketModule;
