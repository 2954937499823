<template>
  <div
    v-if="isMainPage"
    id="aaa"
    :class="fixedTicketBlockClass"
    class="ticket-block"
  >
    <div class="flex flex-col" style="height: calc(100vh - 45px)">
      <div class="ticket-mp-block">
        <div class="ticket-mp-block">
          <div class="selection-tab">
            <div class="tab-header w-full block">
              <ul class="flex">
                <li
                  :class="
                    betTicketState.currentTab === menuTab.TICKET
                      ? 'selected'
                      : ''
                  "
                  class="relative text-center"
                >
                  <a
                    class="ticket-tab justify-center text-white cursor-pointer overflow-hidden flex gap-2"
                    @click="onSwitchTab(menuTab.TICKET)"
                  >
                    <div
                      v-if="errorTicketList.length > 0"
                      class="w-4 h-4 rounded-full bg-red-500"
                    >
                      {{ errorTicketList.length }}
                    </div>
                    {{ $t('tickets') }}
                  </a>
                </li>
                <li
                  :class="
                    betTicketState.currentTab === menuTab.MARKET
                      ? 'selected'
                      : ''
                  "
                  class="relative text-center"
                >
                  <a
                    class="market-tab text-center block text-white cursor-pointer overflow-hidden"
                    @click="onSwitchTab(menuTab.MARKET)"
                  >
                    {{ $t('markets') }}
                  </a>
                </li>
              </ul>
              <div
                :class="
                  betTicketState.currentTab === menuTab.MARKET
                    ? 'selected market'
                    : 'selected ticket'
                "
              />
            </div>
          </div>
          <div class="selection-body tab-mp-body">
            <market-list v-if="betTicketState.currentTab === menuTab.MARKET" />
            <TicketFormContainer
              v-if="betTicketState.currentTab === menuTab.TICKET"
            />
          </div>
        </div>
      </div>
      <my-bet-list />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { MenuTab } from '@/constants/MenuTab.constants';
import MyBetList from '@/components/MyBetList.vue';
import MarketList from '@/components/MarketList.vue';
import TicketFormContainer from '@/components/TicketFormContainer.vue';
import { useRoute } from 'vue-router';
import { useBetTicketStore } from '../store/betTicket';

import type { ComputedRef, Ref } from 'vue';
import type { MenuTab as MenuTabInterface } from '@/interfaces/MenuTab.interface';

export default defineComponent({
  name: 'TicketBlock',
  components: {
    MyBetList,
    MarketList,
    TicketFormContainer,
  },
  setup() {
    const route = useRoute();

    const betTicketStore = useBetTicketStore();
    const betTicketState = computed(() => betTicketStore.$state);

    const fixedTicketBlockClass: Ref<string> = ref('');

    const handleScroll = (): void => {
      if (window.pageYOffset > 189) {
        fixedTicketBlockClass.value = 'ticket-block__fixed';
      } else {
        fixedTicketBlockClass.value = '';
      }
    };

    onBeforeMount(() => {
      window.addEventListener('scroll', handleScroll);
    });

    const isMainPage = computed(() => route.name === 'main');
    const menuTab: ComputedRef<MenuTabInterface> = computed(() => {
      return MenuTab;
    });
    const ticketList = computed(() => betTicketState.value.ticketList);
    const errorTicketList = computed(() =>
      betTicketState.value.ticketList.filter(
        (el) => el.error && el.error?.errorCode !== 'InvalidOdds'
      )
    );

    watch(ticketList, (newTicketList) => {
      if (newTicketList.length) {
        betTicketState.value.currentTab = MenuTab.TICKET;
      }
    });

    const onSwitchTab = (newCurrentTab: string) => {
      betTicketState.value.currentTab = newCurrentTab;
    };

    return {
      errorTicketList,
      betTicketState,
      menuTab,
      onSwitchTab,
      isMainPage,
      fixedTicketBlockClass,
    };
  },
});
</script>

<style>
.ticket-block__fixed {
  position: sticky;
  top: 30px;
}
.ticket-block .ticket-mp-block {
  padding: 3px 0 0;
}
.selection-tab .tab-header ul {
  list-style: none;
}
.selection-tab .tab-header ul li {
  background-color: transparent;
  max-width: 49%;
}
.selection-tab .tab-header ul li a {
  padding: 10px 0 6px;
  width: 94px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.selection-tab .tab-header ul li a.ticket-tab {
  background: url(../assets/images/one-px-bg.png) repeat-x 0 0;
}
.selection-tab .tab-header ul li a.market-tab {
  background: url(../assets/images/one-px-bg.png) repeat-x 0 -30px;
}
.selection-tab .tab-header ul li a:hover {
  color: gold;
}
.selection-tab .tab-header ul li:first-child {
  margin-right: 3px;
}
.selection-tab .tab-header ul li.selected a {
  box-shadow: 1px -1px 1px 0 #333;
}
.selection-tab .tab-header .selected {
  height: 7px;
  width: 100%;
}
.selection-tab .tab-header .selected.market {
  background-color: #649fcc;
}
.selection-tab .tab-header .selected.ticket {
  background-color: #3c5cac;
}
.selection-tab ul li {
  background-color: #80b2e5;
  color: grey;
  font: 700 12px/14px 'Trebuchet MS', Arial, Helvetica, sans-serif;
}
.selection-body {
  background-color: #d6e8fe;
  border: 1px solid #f4f9ff;
  min-height: 5px;
}
</style>
