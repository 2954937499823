<template>
  <bet-list :list="ticketState?.fullTicketList" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import BetList from '@/components/Ticket/BetList.vue';
import { useTicketStore } from '../../store/ticket';

export default defineComponent({
  name: 'ContainerBetTable',
  components: {
    BetList,
  },
  setup() {
    const ticketStore = useTicketStore();

    ticketStore.getFullTicketList();

    const ticketState = computed(() => ticketStore.$state);

    return { ticketState };
  },
});
</script>

<style></style>
