import { defineStore } from 'pinia';
import { MenuTab } from '../constants/MenuTab.constants';
import type { BetTicketStore } from './BetTicketStore.interface';

export const useBetTicketStore = defineStore('betTicket', {
  state: (): BetTicketStore => {
    return {
      version: '1.0.0',
      ticketList: [],
      selectedTeamList: [],
      currentTab: MenuTab.MARKET,
    };
  },
  persist: true,
});
