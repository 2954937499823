import { computed } from 'vue';
import { useStore } from 'vuex';

export function useHdpFormat(hdp: string | number) {
  const store = useStore();
  const currentHdp = computed(() => store.state.betting.hdpType);

  const getOddsFormat = (hdp: string | number, position: number) => {
    const oddsFormat = String(hdp).split('|')[position];
    if (oddsFormat !== '-1') {
      return oddsFormat;
    }
    return null;
  };

  if (hdp && hdp !== '-1') {
    if (currentHdp.value === 1) {
      return getOddsFormat(hdp, 0);
    }
    if (currentHdp.value === 2) {
      return getOddsFormat(hdp, 1);
    }
    return null;
  }
  return null;
}
