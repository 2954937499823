<template>
  <div class="product-list relative flex justify-between">
    <div class="logo-login pt-4 ml-5">
      <router-link to="/">
        <logo class="md:pt-0" />
      </router-link>
    </div>
    <div>
      <ul class="relative ml-2 flex mr-5">
        <li
          v-for="(menu, index) in menuList"
          :key="menu.label"
          class="text-center flex font-bold font-size-13"
          :class="`${routeName === menu.name && 'selected cursor-pointer'}`"
          @click="onClickMenu(index)"
        >
          <div class="product-tab-left" />
          <div class="product-tab-body text-white relative cursor-pointer p-0">
            <router-link
              class="inline-block relative no-underline text-white z-50"
              :to="menu.path"
            >
              {{ $t(`${menu.label}`) }}
            </router-link>
          </div>
          <div class="product-tab-right" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import Logo from '@/components/Head/Logo.vue';

import type { Ref } from 'vue';
import type { Menu } from '@/interfaces/Menu.interface';

export default defineComponent({
  name: 'MainMenu',
  components: { Logo },
  setup() {
    const route = useRoute();
    const routeName = computed(() => route.name);
    const currentMenu: Ref<number> = ref(0);
    const menuList: Ref<Menu[]> = ref([
      { name: 'main', label: 'ballStep', path: '/' },
      { name: 'statement', label: 'statement', path: '/statement' },
      { name: 'betList', label: 'fullBetList', path: '/bet-list' },
      { name: 'result', label: 'result', path: '/result' },
      { name: 'profile', label: 'account', path: '/profile' },
    ]);

    const onClickMenu = (index: number) => {
      currentMenu.value = index;
    };

    return { currentMenu, menuList, onClickMenu, routeName };
  },
});
</script>

<style>
.product-list {
  height: 74px;
  width: 100%;
}
.product-list ul {
  right: 0;
  top: 25px;
}
.font-size-13 {
  font-size: 13px;
}
.product-list ul li {
  margin: 0 5px;
}
.product-list .logo-login {
  width: 165px;
}
.product-list .selected .product-tab-left {
  width: 9px;
  background: url(../../assets/images/common-header.png) 0 -173px;
}
.product-list .selected .product-tab-body {
  padding: 0 10px;
  color: #fff;
  background: url(../../assets/images/common-header.png) 0 -128px;
  position: relative;
}
.product-list .selected .product-tab-body a {
  color: #fff;
}
.product-list .selected .product-tab-right {
  width: 9px;
  background: url(../../assets/images/common-header.png) -10px -173px;
}
.product-tab-body,
.product-tab-left,
.product-tab-right {
  height: 44px;
}
.product-tab-left {
  width: 9px;
}
.product-tab-body {
  color: #283e87;
  font-family: trebuchet ms, serif;
}
.product-tab-body a {
  color: #00175f;
  line-height: 44px;
}
.product-tab-body a:hover {
  color: #4871d3;
}
.product-tab-right {
  width: 9px;
}
</style>
