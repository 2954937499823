<template>
  <statement-table
    :list="statementState.statementList"
    @click-statement="onClickStatement"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import StatementTable from '@/components/Statement/StatementTable.vue';
import { useStatementStore } from '../../store/statement';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ContainerStatementTable',
  components: {
    StatementTable,
  },
  emits: ['change-acc-date'],
  setup(_, { emit }) {
    const router = useRouter();
    const statementStore = useStatementStore();

    const statementState = computed(() => statementStore.$state);

    const onClickStatement = (accDate: string) => {
      emit('change-acc-date', accDate);
      router.push(`/statement?accDate=${accDate}`);
    };

    return { statementState, onClickStatement };
  },
});
</script>

<style></style>
