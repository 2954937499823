<template>
  <filter-result @current-date="onFilterDate" />
  <result-table :list="resultState.resultList" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import FilterResult from '@/components/Result/FilterResult.vue';
import ResultTable from '@/components/Result/ResultTable.vue';
import { useResultStore } from '../../store/result';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'ContainerResultTable',
  components: {
    FilterResult,
    ResultTable,
  },
  setup() {
    const resultStore = useResultStore();

    onMounted(async () => {
      const accDate = dayjs().subtract(11, 'hour').format('YYYY-MM-DD');
      await resultStore.getResultList(accDate);
    });

    const resultState = computed(() => resultStore.$state);

    const onFilterDate = (date: string) => {
      resultStore.getResultList(dayjs(date).format('YYYY-MM-DD'));
    };

    return { resultState, onFilterDate };
  },
});
</script>

<style></style>
