export enum ADV_SELECTION {
  HOME_ADV = 1,
  AWAY_ADV = 2,
}

export enum TICKET_STATUS {
  IDLE = 'IDLE',
  UP = 'UP',
  DOWN = 'DOWN',
  DEL = 'DEL',
}
