<template>
  <full-bet-table
    v-if="hasFullBetList"
    :list="bettingState.fullBetList"
    @click:match="onClickMatch"
    @click:odds="onClickOdds"
  />
  <not-found-card v-else />
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent, onMounted } from 'vue';
import FullBetTable from '@/components/Betting/FullBetTable.vue';
import { useSetTicket } from '@/composition/set-ticket';
import NotFoundCard from '@/components/NotFoundCard.vue';
import { useBettingStore } from '../store/betting';
import { OddsType } from '../constants/OddsType.enum';

import type { FbMatch } from '@/interfaces/FbMatch.interface';
import type { TeamSelected } from '@/interfaces/TeamSelected.interface';

export default defineComponent({
  name: 'ContainerFullBetTable',
  components: {
    FullBetTable,
    NotFoundCard,
  },
  setup() {
    const bettingStore = useBettingStore();
    const bettingState = computed(() => bettingStore.$state);

    onMounted(() => {
      bettingStore.getOddsList(OddsType.FULL);
    });

    const hasFullBetList: ComputedRef<boolean> = computed(
      () => bettingState.value?.fullBetList?.length > 0
    );

    const { useSetTicketInputList, useSetSelectedTicketInputList } =
      useSetTicket();

    const onClickMatch = (matchItem: FbMatch) => {
      return useSetTicketInputList(matchItem);
    };

    const onClickOdds = (newOddsItem: TeamSelected) => {
      return useSetSelectedTicketInputList(newOddsItem);
    };

    return {
      bettingState,
      onClickMatch,
      onClickOdds,
      hasFullBetList,
    };
  },
});
</script>

<style></style>
