<template>
  <div class="lang-selection">
    <div class="lang-drop-down">
      <span class="lang-img" :class="currentLanguage.value" />
      <a class="text-xs" @click="onClickLanguageBtn">
        <span>{{ currentLanguage.label }}</span>
        <span class="lang-divider" />
      </a>
      <ul :class="isOpen ? 'block' : 'hidden'" class="lang-drop-down-ul">
        <li
          v-for="languageItem in languageList"
          :key="languageItem.value"
          @click="onChangeLanguage(languageItem)"
        >
          <a :class="languageItem.value" class="selector-list-a text-xs">
            {{ languageItem.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Ref } from 'vue';
import type { Language } from '@/interfaces/Language.interface';

export default defineComponent({
  name: 'LanguageSwitch',
  setup() {
    const { locale } = useI18n();

    const isOpen: Ref<boolean> = ref(false);
    const currentLanguage: Ref<Language> = ref({
      label: 'ภาษาไทย',
      value: 'th',
    });
    const languageList: Ref<Language[]> = ref([
      { label: 'ภาษาไทย', value: 'th' },
      { label: 'English', value: 'en' },
    ]);

    onMounted(() => {
      const localStorageLang = localStorage.getItem('lang');
      if (localStorageLang) {
        locale.value = localStorageLang;
      } else {
        localStorage.setItem('lang', locale.value);
      }
      const findLangItem: Language | undefined = languageList.value?.find(
        (item) => item.value === locale.value
      );
      if (findLangItem) {
        currentLanguage.value = findLangItem;
      }
    });

    const onClickLanguageBtn = () => {
      isOpen.value = true;
    };

    const onChangeLanguage = (languageItem: Language) => {
      isOpen.value = false;
      currentLanguage.value = languageItem;
      locale.value = languageItem.value;
      localStorage.setItem('lang', locale.value);
    };

    return {
      isOpen,
      currentLanguage,
      languageList,
      onClickLanguageBtn,
      onChangeLanguage,
    };
  },
});
</script>

<style scoped>
.lang-selection {
  display: inline-block;
  float: left;
  font-weight: 700;
  color: #274ea0;
  line-height: 18px;
  cursor: pointer;
}
.lang-selection .lang-drop-down {
  float: left;
  position: relative;
  white-space: nowrap;
}
.lang-selection .lang-drop-down .lang-img.th {
  background: url(../../assets/images/flag-th.png);
}
.lang-selection .lang-drop-down .lang-img.en {
  background: url(../../assets/images/flag-en.png);
}
.lang-selection .lang-drop-down .lang-img {
  display: inline-block;
  zoom: 1;
  width: 16px;
  height: 16px;
  margin: 1px 3px 0 6px;
  background-repeat: no-repeat;
}
.lang-selection .lang-drop-down span {
  float: left;
}
.lang-selection .lang-drop-down .lang-divider {
  display: inline-block;
  zoom: 1;
  width: 17px;
  height: 15px;
  margin-left: 3px;
  margin-top: 1px;
  background: url(../../assets/images/global-setting-icon.png) no-repeat right -627px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul {
  width: 110px;
  position: absolute;
  margin-top: 20px;
  border: 1px solid #deebfd;
  border-top: none;
  background-color: #fff;
  left: -1px;
  box-shadow: 1px 1px 2px #17275f;
  -webkit-box-shadow: 1px 1px 2px #17275f;
  -moz-box-shadow: 1px 1px 2px #17275f;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li {
  color: #22377b;
  border-top: 1px solid #d2e7ff;
  height: 100%;
  white-space: nowrap;
  width: 110px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a {
  display: block;
  padding: 4px 24px;
  white-space: nowrap;
  color: #274ea0;
  text-decoration: none;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.th {
  background: url(../../assets/images/flag-th.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.en {
  background: url(../../assets/images/flag-en.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li:hover {
  background-color: #d2e7ff;
  border-top: 1px solid #d2e7ff;
}

.lang-selection {
  display: inline-block;
  float: left;
  font-weight: 700;
  color: #274ea0;
  line-height: 18px;
  cursor: pointer;
}
.lang-selection .lang-drop-down {
  float: left;
  position: relative;
  white-space: nowrap;
}
.lang-selection .lang-drop-down .lang-img.th {
  background: url(../../assets/images/flag-th.png);
}
.lang-selection .lang-drop-down .lang-img {
  display: inline-block;
  zoom: 1;
  width: 16px;
  height: 16px;
  margin: 1px 3px 0 6px;
  background-repeat: no-repeat;
}
.lang-selection .lang-drop-down span {
  float: left;
}
.lang-selection .lang-drop-down .lang-divider {
  display: inline-block;
  zoom: 1;
  width: 17px;
  height: 15px;
  margin-left: 3px;
  margin-top: 1px;
  background: url(../../assets/images/global-setting-icon.png) no-repeat right -627px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul {
  width: 110px;
  position: absolute;
  margin-top: 20px;
  border: 1px solid #deebfd;
  border-top: none;
  background-color: #fff;
  left: -1px;
  box-shadow: 1px 1px 2px #17275f;
  -webkit-box-shadow: 1px 1px 2px #17275f;
  -moz-box-shadow: 1px 1px 2px #17275f;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li {
  color: #22377b;
  border-top: 1px solid #d2e7ff;
  height: 100%;
  white-space: nowrap;
  width: 110px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a {
  display: block;
  padding: 4px 24px;
  white-space: nowrap;
  color: #274ea0;
  text-decoration: none;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.th {
  background: url(../../assets/images/flag-th.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li a.en {
  background: url(../../assets/images/flag-en.png) no-repeat 5px;
}
.lang-selection .lang-drop-down .lang-drop-down-ul li:hover {
  background-color: #d2e7ff;
  border-top: 1px solid #d2e7ff;
}
</style>
