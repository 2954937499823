import { defineStore } from 'pinia';
import { apiFetch } from '../composition/useAxios';
import { useLoadingStore } from './wait';

import type { ResultStore } from './ResultStore.interface';

export const useResultStore = defineStore('result', {
  state: (): ResultStore => {
    return {
      version: '1.0.0',
      resultList: [],
    };
  },
  actions: {
    async getResultList(date: string) {
      const loadingStore = useLoadingStore();
      try {
        loadingStore.start('getResultList');
        const { data } = await apiFetch(`/result?accDate=${date}`, {
          method: 'GET',
        });
        if (data?.value) {
          this.resultList = data.value;
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingStore.end('getResultList');
      }
    },
  },
});
