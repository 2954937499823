<template>
  <div class="headerboxblue m-0 sticky">
    <div class="headerboxbluecenter w-full grid grid-cols-12 items-center">
      <div class="headertitlebox gap-2 col-span-3 flex items-center">
        <div class="headiconleft" />
        <span class="font-bold text-xs">
          {{ $t(bettingTableStateKey[currentTable]) }}
        </span>
      </div>
      <fetch-interval class="col-span-2" />
      <div class="col-span-4 flex gap-3">
        <div
          class="headerrighticonblue relative font-bold text-xs w-auto cursor-pointer"
        >
          <button
            class="font-bold px-2 border border-blue-900 rounded-sm"
            @click="onClickPrint"
          >
            {{ $t('print') }}
          </button>
        </div>
        <div
          class="headerrighticonblue border border-blue-900 rounded-sm relative font-bold text-xs w-auto"
        >
          <label>
            <select v-model="hdpValue" class="font-bold cursor-pointer">
              <option :value="1">HDP1</option>
              <option :value="2">HDP2</option>
            </select>
          </label>
        </div>
        <div
          class="headerrighticonblue border border-blue-900 rounded-sm relative font-bold text-xs w-auto"
        >
          <label>
            <select v-model="oddsType" class="font-bold cursor-pointer">
              <option :value="bettingTableState.FULL_TIME">
                {{ $t('hdpOu') }}
              </option>
              <option :value="bettingTableState.FIRST_HALF">
                {{ $t('fhHdpOu') }}
              </option>
              <option :value="bettingTableState.ODDS_EVEN">
                {{ $t('oddsEven') }}
              </option>
              <option :value="bettingTableState.FULL_BET">
                {{ $t('fullBet') }}
              </option>
            </select>
          </label>
        </div>
      </div>
      <filter-league class="col-span-3" />
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import {
  BettingTableState,
  BettingTableStateKey,
} from '@/constants/BettingTableState.enum';
import { HdpType } from '@/constants/HdpType.enum';
import FetchInterval from '@/components/Betting/FetchInterval.vue';
import FilterLeague from '@/components/Betting/FilterLeague.vue';
import { useBettingStore } from '../../store/betting';

import type { Ref } from 'vue';

export default defineComponent({
  name: 'HeaderBetList',
  components: {
    FetchInterval,
    FilterLeague,
  },
  setup() {
    const store = useStore();
    const hdpValue: Ref<HdpType> = ref(HdpType.HDP_ONE);
    const oddsType: Ref<BettingTableState> = ref(BettingTableState.FULL_TIME);
    const bettingStore = useBettingStore();

    const currentTable = computed(
      () => bettingStore.$state.currentBettingTable
    );

    const bettingTableStateKey = computed(() => BettingTableStateKey);
    const bettingTableState = computed(() => BettingTableState);

    watch(hdpValue, (value) => {
      store.commit('betting/setHdp', value);
    });

    watch(oddsType, (value) => {
      bettingStore.$state.currentBettingTable = value;
    });

    onMounted(() => {
      oddsType.value = currentTable.value;
    });
    const onClickPrint = () => {
      window.print();
    };

    return {
      onClickPrint,
      currentTable,
      bettingTableStateKey,
      hdpValue,
      oddsType,
      bettingTableState,
    };
  },
});
</script>

<style>
.sticky {
  position: sticky;
  top: 33px;
}
.headerboxblue {
  width: 100%;
  height: 30px;
}
.headerboxbluecenter {
  background-image: url(../../assets/images/headerblueline1.jpg);
  height: 30px;
}
.headiconleft {
  margin: 3px 0 4px 10px;
  height: 22px;
  width: 22px;
  background-image: url(../../assets/images/sports-icon.png);
}
.headertitlebox {
  color: #1d2e65;
  font-family: trebuchet ms, Arial Black, Gadget, sans-serif;
  height: 30px;
}
.headerrighticonblue {
  color: #1d2e65;
  font-family: trebuchet ms, Arial Black, Gadget, sans-serif;
}
.championiconblue {
  height: 15px;
  width: 20px;
  background: url(../../assets/images/table-icon.png) -5px -584px;
}
.titlerefreshiconblue {
  background: url(../../assets/images/table-icon.png) -46px -41px;
  float: left;
  height: 14px;
  width: 14px;
}
.headercentericonblue {
  font-family: trebuchet ms, Arial Black, Gadget, sans-serif;
  float: left;
  right: 370px;
  height: 30px;
}
.headercentericonblue a {
  color: #1d2e65;
}
.headercentericonblue a:hover {
  color: #4c69b8;
}
.headerrighticonblue span {
  color: #1d2e65;
}
.headerrighticonblue span:hover {
  color: #4c69b8;
}
</style>
