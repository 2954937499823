<template>
  <div
    v-show="isShowOverlay"
    class="w-full h-full fixed top-0 left-0 bg-black/50 backdrop-blur z-[102] flex justify-center"
  >
    <div>
      <h3 class="text-white font-display text-3xl font-medium mt-16">
        {{ t('pleaseWaitUpdating') }}... {{ count }}
      </h3>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useInterval } from '@vueuse/core';
import axios from 'axios';
import log from 'loglevel';
import { ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const COUNTDOWN = 5;
const isShowOverlay = ref(false);
const count = ref(COUNTDOWN);

const appVersion = await axios.get(`/version.txt?c=${Date.now()}`);

log.info(`remoteInfo: v${appVersion.data}`);

const currentVersion = localStorage.getItem('app_version');

const { counter, resume } = useInterval(1000, {
  controls: true,
  immediate: false,
});

if (currentVersion !== appVersion.data) {
  log.info('Has new update...');
  isShowOverlay.value = true;
  localStorage.setItem('app_version', appVersion.data);
  resume();
}

watch(counter, () => {
  count.value = COUNTDOWN - counter.value;
});

watchEffect(() => {
  if (counter.value >= 5) {
    location.reload();
  }
});
</script>
