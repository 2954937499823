import { defineStore } from 'pinia';
import { apiFetch } from '../composition/useAxios';
import type { StatementStore } from './StatementStore.interface';

export const useStatementStore = defineStore('statement', {
  state: (): StatementStore => {
    return {
      version: '1.0.0',
      statementList: [],
      statementDetailList: [],
    };
  },
  actions: {
    async getStatementList() {
      try {
        const { data } = await apiFetch('/statement ', { method: 'GET' });
        if (data?.value) {
          this.statementList = data.value;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getStatementDetailList(accDate?: string) {
      try {
        if (accDate) {
          const { data } = await apiFetch(`/ticket/detail?accDate=${accDate}`, {
            method: 'GET',
          });
          if (data?.value) {
            this.statementDetailList = data.value;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
