<template>
  <league-modal />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LeagueModal from '@/containers/LeagueModal.vue';

export default defineComponent({
  name: 'FilterLeague',
  components: { LeagueModal },
});
</script>

<style>
.championiconblue {
  height: 15px;
  width: 20px;
  background: url(../../assets/images/table-icon.png) -5px -584px;
}
</style>
