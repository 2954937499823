<template>
  <div class="common-header-shadow header-authen">
    <div class="common-header-bg header-inner">
      <div class="product-list flex justify-between items-center sm:block">
        <a href="/">
          <logo />
        </a>
        <navbar-menu class="hidden sm:block" />
        <sidebar-menu class="sm:hidden" />
      </div>
      <header-bottom-link />
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Head/Logo.vue';
import NavbarMenu from '@/components/Head/NavbarMenu.vue';
import HeaderBottomLink from '@/components/Head/HeaderBottomLink.vue';
import SidebarMenu from '@/components/Head/SidebarMenu.vue';

export default {
  name: 'PublicNavbar',
  components: {
    Logo,
    NavbarMenu,
    HeaderBottomLink,
    SidebarMenu,
  },
};
</script>

<style scoped>
.header-authen {
  width: 992px;
  position: relative;
  z-index: 99;
  background: repeat-y 2px 0;
  margin: 0 auto;
}
.header-inner {
  width: 987px;
  height: 96px;
  background-position: 0 -30px;
}
.product-list {
  height: 75px;
  width: 100%;
  position: relative;
}
.common-header-bg {
  background-image: url(../../assets/images/common-header.png);
}
@media (max-width: 639px) {
  .header-inner {
    width: auto;
  }
  .header-authen {
    width: auto;
  }
}
</style>
