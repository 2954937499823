import numbro from 'numbro';

export function formatNumber(
  numberValue: string | number,
  isDecimal?: boolean
) {
  if (numberValue) {
    return numbro(numberValue).format({
      thousandSeparated: true,
      mantissa: isDecimal ? 2 : 0,
    });
  }
  return '0';
}
