<template>
  <div>
    <div class="hidden sm:grid grid-rows-1 grid-flow-col main-wrapper">
      <div class="col-span-8 box left box-large">
        <div class="box-header" />
        <div class="box-body">
          <carousel-container />
        </div>
        <div class="box-footer" />
      </div>
      <div class="col-span-4 box right box-medium">
        <div class="box-header" />
        <div class="box-body mt-2 mb-1">
          <a href="/">
            <img
              v-show="locale === 'th'"
              class="max-w-none"
              alt="league88"
              src="@/assets/images/v1_th.jpg"
            />
            <img
              v-show="locale === 'en'"
              class="max-w-none"
              alt="league88"
              src="@/assets/images/v1_en.jpg"
            />
          </a>
        </div>
        <div class="box-footer">
          <div />
        </div>
      </div>
    </div>
    <div class="grid grid-rows-1 grid-flow-col main-wrapper clearfix">
      <div class="col-span-4 box left box-small">
        <div class="box-header" />
        <div class="box-body">
          <h2>{{ $t('football') }}</h2>
          <a href="">
            <img
              v-show="locale === 'th'"
              class="max-w-none"
              alt="league88"
              src="@/assets/images/A2_th.jpg"
            />
            <img
              v-show="locale === 'en'"
              class="max-w-none"
              alt="league88"
              src="@/assets/images/A2_en.jpg"
            />
          </a>
        </div>
        <div class="box-footer">
          <div />
        </div>
      </div>

      <div class="col-span-4 box box-small">
        <div class="box-header" />
        <div class="box-body">
          <h2>{{ $t('games') }}</h2>
          <a href="">
            <img
              v-show="locale === 'th'"
              class="max-w-none"
              alt="league88"
              src="@/assets/images/A3_th.jpg"
            />
            <img
              v-show="locale === 'en'"
              class="max-w-none"
              alt="league88"
              src="@/assets/images/A3_en.jpg"
            />
          </a>
        </div>
        <div class="box-footer">
          <div />
        </div>
      </div>

      <div class="col-span-6 box sm:col-span-2 box box-xs pr-1 sm:pr-0">
        <div class="box-header" />
        <div class="box-body">
          <h2>EGR Power 50</h2>
          <a href="" target="_self">
            <img
              class="max-w-none"
              alt="league88"
              src="@/assets/images/A4_th.jpg"
            />
          </a>
        </div>
        <div class="box-footer">
          <div />
        </div>
      </div>

      <div class="col-span-6 sm:col-span-2 box right box-xs pl-1 sm:pl-0">
        <div class="box-header" />
        <div class="box-body body-follow">
          <h2>Follow Us</h2>
          <a href="" target="_self">
            <img
              class="max-w-none"
              alt="league88"
              src="@/assets/images/A5_th.jpg"
            />
          </a>
        </div>
        <div class="box-footer">
          <div />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CarouselContainer from '@/components/Login/CarouselContainer.vue';

import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
</script>

<style scoped>
.main-wrapper {
  background: url(../../assets/images/landing-bodybg.png) repeat-y;
}
.box {
  position: relative;
  margin-bottom: 7px;
  background: url(../../assets/images/landing-page.png) no-repeat -148px -184px;
}
.box img {
  margin: 5px;
  border: 1px solid #334182;
}
.box .box-header {
  background: url(../../assets/images/landing-page.png) right -184px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 8px;
  font-size: 0;
}
.box .box-footer {
  left: 3px;
  background: url(../../assets/images/landing-page.png) no-repeat right -719px;
}
.box .box-footer div {
  background: url(../../assets/images/landing-page.png) no-repeat;
}
.box .box-body {
  background: url(../../assets/images/landing-page.png) no-repeat right -194px;
  margin: 6px -3px -4px 3px;
  overflow: hidden;
}
.box .box-body h2 {
  background: url(../../assets/images/landing-page.png) right -140px;
  height: 32px;
  position: absolute;
  top: 11px;
  left: 8px;
  padding: 0 40px 0 10px;
  font: 700 11px/20px Tahoma, sans-serif;
  color: #132351;
}
.box.left {
  background-position: -147px -457px;
}
.box.left .box-footer {
  background-position: -147px -719px;
}
.box.left .box-footer div {
  background-position: right -446px;
}
.box.right {
  background-position: right -457px;
}
.box.right .box-header {
  background-position: -148px -184px;
  left: 0;
}
.box.right .box-body {
  background-position: -148px -190px;
  margin: 6px 5px 0 0;
  padding: 0 0 0 3px;
}
.box.right .box-footer {
  background-position: right -719px;
  left: 3px;
}
.box.right .box-footer div {
  background-position: -148px -446px;
  left: 0;
}
.box-large,
.box-medium,
.box-small,
.box-xs {
  display: inline;
  float: left;
  margin-left: 5px;
}
.box-large {
  width: 623px;
  height: 255px;
}
.box-large img {
  width: 611px;
  height: 242px;
}
.box-medium {
  width: 359px;
  height: 255px;
}
.box-medium.right .box-footer {
  width: 356px;
}
.box-small {
  width: 311px;
}
.box-xs {
  width: 175px;
  height: 185px;
}
.box.box-xs img {
  width: 162px;
}
.box-xs.right img {
  width: 160px;
  height: 172px;
}

.box-xs.right .box-footer {
  width: 98.3%;
}
.left .box-footer,
.box .box-footer,
.right .box-footer {
  background-position: -148px -445px;
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 8px;
}
@media (max-width: 639px) {
  .box-xs.right img,
  .box.box-xs img {
    width: 100%;
  }
  .box img {
    width: 100%;
  }
  .box-xs.right img {
    height: 183px;
  }
  .box .box-footer,
  .box {
    background: unset;
  }
  .box .box-body {
    background: #bad1f7;
    padding-right: 10px;
  }
  .box-large {
    width: 99%;
  }
  .main-wrapper {
    background: unset;
    padding: 0 4px 2px 0;
    margin: 0;
  }
  .box.right .box-body {
    padding: 0;
  }
  .box-medium {
    width: 100%;
  }
  .box-medium,
  .box-small {
    margin: 0;
    display: none;
  }
  .box-large,
  .box-xs {
    margin: 0;
  }
  .box.right .box-body {
    margin: 10px 0px 0px 3px;
  }
  .box.right .box-body {
    padding-right: 10px;
  }
  .box-small {
    width: 99%;
  }
  .box-xs {
    width: 100%;
  }
  .box.right .box-body.body-follow {
    margin: 6px 0 0 0;
  }
}
@media (max-width: 320px) {
  .box-xs.right img {
    height: auto;
  }
}
</style>
