<template>
  <header-bet-list />
  <slot />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HeaderBetList from '@/components/Betting/HeaderBetList.vue';

export default defineComponent({
  name: 'SlotBettingContainer',
  components: {
    HeaderBetList,
  },
});
</script>
