<template>
  <teleport to="body">
    <div
      v-if="isOpen"
      class="fixed flex w-full h-full top-0 justify-center items-center z-[9999]"
    >
      <div class="fixed inset-0 bg-black bg-opacity-25" />
      <div
        class="w-full max-w-md h-fit transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl"
      >
        <div
          as="h3"
          class="text-lg font-medium leading-6 text-gray-900 flex justify-center border-b-2 border-blue-400 pb-3"
        >
          <span class="pl-2">
            {{ title }}
          </span>
        </div>
        <div class="mt-4 text-center">
          <p class="text-sm text-gray-500 font-medium">
            <slot>
              {{ message }}
            </slot>
          </p>
        </div>

        <div class="mt-4 flex gap-6 justify-center">
          <button
            tabindex="2"
            type="button"
            :disabled="loading"
            class="disabled:opacity-40 inline-flex justify-center rounded-md border border-transparent border-slate-200 px-4 py-2 text-sm font-medium text-slate-800 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-500 focus-visible:ring-offset-2"
            @click="onCloseModal"
          >
            {{ $t('cancel') }}
          </button>
          <button
            tabindex="1"
            type="button"
            :disabled="loading"
            class="disabled:opacity-40 inline-flex justify-center gap-2 items-center rounded-md border border-transparent bg-yellow-300 px-4 py-2 text-sm font-medium text-slate-800 hover:bg-yellow-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2"
            @click="onSubmitModal"
          >
            <font-awesome-icon
              v-if="loading"
              icon="fa-solid fa-spinner"
              class="animate-spin"
            />
            {{ $t('ok') }}
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { PropType } from 'vue';

export default defineComponent({
  components: {},
  props: {
    isOpen: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String as PropType<string>,
      default: '',
    },
  },
  emits: ['close-notify-modal', 'submit-notify-modal'],
  setup(_, { emit }) {
    const onCloseModal = () => {
      emit('close-notify-modal');
    };
    const onSubmitModal = () => {
      emit('submit-notify-modal');
    };

    return { onCloseModal, onSubmitModal };
  },
});
</script>
