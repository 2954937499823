<template>
  <div class="my-bet-title">
    <div class="single-tab flex cursor-pointer font-bold">
      <div class="tab-bg tab-left" />
      <div class="one-px-bg tab-body text-xs block text-center text-white">
        {{ $t('myBets') }}
      </div>
      <div class="tab-bg tab-right" />
    </div>
  </div>
  <div class="my-bet-block overflow-auto">
    <div class="my-bet-block grid">
      <div class="font-bold">
        <div
          v-for="(item, index) in ticketState?.ticketList"
          :key="item?.id"
          class="text-xs py-[3px] hover:bg-blue-50 selection-body fav-body cursor-pointer mb-0.5 bg-[#D6E8FE] border-[#D6E8FE]"
          @click="clickShowParlay(item?.id, index)"
        >
          <div
            class="grid grid-cols-2 justify-between leading-none items-center h-[20px]"
          >
            <span class="px-1 text-[#008] font-bold text-end">
              {{ item?.id }}
            </span>
            <span class="text-xs px-1 font-bold text-start">
              {{ item?.accDate }}
            </span>
          </div>
          <div
            v-show="showParlay === index"
            id="parlay-list"
            class="duration-1000 text-xs"
          >
            <div colspan="2" class="p-1">
              <div
                v-show="ticketState?.ticketParlayList.length === 0"
                class="py-2 text-center"
              >
                {{ $t('loading') }}...
              </div>
              <sidebar-ticket-detail
                v-for="(
                  parlayItem, parlayIndex
                ) in ticketState?.ticketParlayList"
                :key="parlayIndex"
                :parlay-item="parlayItem"
              />
            </div>
          </div>
          <div class="grid justify-center">
            <div class="grid grid-cols-2">
              <span class="text-right px-1">
                {{ $t('ticket.totalOdds') }} :
              </span>
              <span class="text-green-800 text-left px-1">
                {{ getTotalOdds(item) }}
              </span>
            </div>
            <div class="grid grid-cols-2">
              <span class="text-right px-1">{{ $t('ticket.stake') }} :</span>
              <span class="text-green-800 font-bold text-left px-1">
                {{ item?.stake }}
              </span>
            </div>
            <div class="grid grid-cols-2">
              <span class="text-right px-1">{{ $t('ticket.payout') }} :</span>
              <span class="text-green-800 font-bold text-left px-1">
                {{ getPayout(item) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!hasTicketList">
        <div class="selection-body my-bet-body text-center">
          <span class="my-bet-no-bet text-xs">{{ $t('noPendingBets') }}.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, type ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNumberUtils } from '../composition/number-utils';
import { useTicketStore } from '../store/ticket';
import { useBetTicketStore } from '../store/betTicket';
import SidebarTicketDetail from '@/components/Ticket/SidebarTicketDetail.vue';

import type { Ticket } from '../interfaces/api/Ticket.interface';
import { MenuTab } from '../constants/MenuTab.constants';
import type { MenuTab as MenuTabInterface } from '@/interfaces/MenuTab.interface';

export default defineComponent({
  name: 'MyBetList',
  components: { SidebarTicketDetail },
  setup() {
    const { locale } = useI18n();
    const showParlay = ref<number | null>(null);
    const { formatNumber } = useNumberUtils();
    const ticketStore = useTicketStore();
    const betTicketStore = useBetTicketStore();

    ticketStore.getTicketList();

    const ticketState = computed(() => ticketStore.$state);
    const betTicketState = computed(() => betTicketStore.$state);

    const menuTab: ComputedRef<MenuTabInterface> = computed(() => {
      return MenuTab;
    });

    const hasTicketList = computed(
      () => ticketState.value?.ticketList?.length > 0
    );

    const getPayout = (item: Ticket) => {
      const totalPayout = Number(item?.stake) * Number(item?.totalOdds);
      return formatNumber(totalPayout);
    };

    const getTotalOdds = (item: Ticket) => {
      const totalOdds = Number(item?.totalOdds);
      return formatNumber(totalOdds);
    };

    const clickShowParlay = (ticketId: number, index: number) => {
      ticketState.value.ticketParlayList = [];
      ticketStore.getTicketParlayList(ticketId);
      if (showParlay.value === index) {
        showParlay.value = null;
      } else {
        showParlay.value = index;
      }
    };

    return {
      locale,
      menuTab,
      showParlay,
      getPayout,
      getTotalOdds,
      ticketState,
      betTicketState,
      hasTicketList,
      clickShowParlay,
    };
  },
});
</script>

<style>
.one-px-bg {
  background-image: url(../assets/images/one-px-bg.png);
}
.tab-bg {
  background-image: url(../assets/images/header-tab-bg.png);
}
.single-tab {
  font-family: trebuchet ms, Arial, Helvetica, sans-serif;
  height: 30px;
}
.single-tab .tab-body {
  width: 89.4%;
  height: 30px;
  line-height: 30px;
}
.single-tab .tab-bg.tab-right {
  background-position: -3px 0;
}
.single-tab .tab-bg.tab-left,
.single-tab .tab-bg.tab-right {
  width: 10px;
  height: 30px;
}
.selection-body {
  background-color: #d6e8fe;
  border: 1px solid #f4f9ff;
  min-height: 5px;
}
.my-bet-block .bet-list {
  max-height: 400px;
  overflow-y: auto;
}
</style>
