import type { Module } from 'vuex';
import type { RootState } from '@/vuex-store/RootState.interface';
import state from './state';
import type { ResultState } from './ResultState.interface';

const statementModule: Module<ResultState, RootState> = {
  namespaced: true,
  state,
};

export default statementModule;
