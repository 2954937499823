<template>
  <div class="w-full">
    <slot-betting-container>
      <container-full-time-table
        v-if="currentTable === bettingTableState.FULL_TIME"
      />
      <container-first-half-table
        v-if="currentTable === bettingTableState.FIRST_HALF"
      />
      <container-odds-even-table
        v-if="currentTable === bettingTableState.ODDS_EVEN"
      />
      <container-full-bet-table
        v-if="currentTable === bettingTableState.FULL_BET"
      />
    </slot-betting-container>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted } from 'vue';
import SlotBettingContainer from '@/containers/SlotBettingContainer.vue';
import ContainerFullTimeTable from '@/containers/ContainerFullTimeTable.vue';
import ContainerFirstHalfTable from '@/containers/ContainerFirstHalfTable.vue';
import ContainerOddsEvenTable from '@/containers/ContainerOddsEvenTable.vue';
import ContainerFullBetTable from '@/containers/ContainerFullBetTable.vue';
import { BettingTableState } from '@/constants/BettingTableState.enum';
import { useBettingStore } from '../store/betting';

export default defineComponent({
  name: 'HomePage',
  components: {
    SlotBettingContainer,
    ContainerFullTimeTable,
    ContainerFirstHalfTable,
    ContainerOddsEvenTable,
    ContainerFullBetTable,
  },
  setup() {
    const bettingStore = useBettingStore();

    const currentTable = computed(
      () => bettingStore.$state.currentBettingTable
    );
    const bettingTableState = computed(() => BettingTableState);

    onUnmounted(() => {
      bettingStore.$state.currentBettingTable = BettingTableState.FULL_TIME;
    });

    return { currentTable, bettingTableState };
  },
});
</script>
