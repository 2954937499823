<template>
  <section class="sm:hidden">
    <login-container />
  </section>
  <section>
    <home-banner />
  </section>
  <section>
    <main-content />
  </section>

  <teleport to="body">
    <notify-modal
      :message="modalMessage"
      :is-open="showModal"
      @close-notify-modal="onCloseNotifyModal"
    />
  </teleport>
  <teleport to="body">
    <div
      v-if="loginError"
      class="absolute bottom-2 right-2 text-slate-300 max-w-[500px] bg-red-600 rounded-md p-1"
    >
      {{ loginError }}
    </div>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import HomeBanner from '@/components/Login/HomeBanner.vue';
import MainContent from '@/components/Login/MainContent.vue';
import LoginContainer from '@/containers/LoginContainer.vue';
import NotifyModal from '@/components/NotifyModal.vue';
import { useModalStore } from '../store/modal';
import { useAuthStore } from '@/store/auth';

export default defineComponent({
  name: 'LoginPage',
  components: {
    LoginContainer,
    HomeBanner,
    MainContent,
    NotifyModal,
  },

  setup() {
    const modalStore = useModalStore();
    const authStore = useAuthStore();

    const loginError = computed(() => authStore.internalError);

    const modalMessage = computed(
      () => modalStore.message ?? 'Something went wrong'
    );
    const showModal = computed(() => modalStore.isShow);

    const onCloseNotifyModal = () => {
      modalStore.isShow = false;
    };

    return {
      onCloseNotifyModal,
      loginError,
      modalMessage,
      showModal,
    };
  },
});
</script>

<style>
.margin-left-diff-6 {
  margin-left: -6px;
}
@media (max-width: 639px) {
  .margin-left-diff-6 {
    margin: 0;
  }
}
</style>
