<template>
  <div class="px-4">
    <div
      v-if="ticket && !isError"
      class="max-w-[500px] mx-auto my-5 border-2 border-cyan-800"
    >
      <div
        class="flex justify-between py-2 px-4 bg-slate-300 font-semibold text-sm"
      >
        <div>REF#{{ ticket?.id }}</div>
        <div>{{ ticket?.time }}</div>
      </div>
      <div class="bg-slate-100">
        <div class="grid text-sm p-4 border-b-2 border-cyan-800">
          <div>
            {{ $t('ticketSlip.stake') }}:
            <span class="font-semibold">
              {{ ticket.stake }} {{ $t('bath') }}
            </span>
          </div>
          <div>
            {{ $t('ticketSlip.totalOdds') }}:
            <span class="font-semibold">
              {{ ticket.totalOdds }}
            </span>
          </div>
          <div>
            {{ $t('ticketSlip.status') }}:
            <span class="font-semibold">
              {{ ticket.active[locale] }}
            </span>
          </div>
          <div
            v-if="ticket?.winlose"
            class="font-semibold text-blue-600 pt-1"
            :class="{ 'text-red-600': 0 > getWinlose(ticket) }"
          >
            {{ $t('ticketSlip.winlose') }}:
            <span>{{ getWinlose(ticket) ?? '-' }} {{ $t('bath') }}</span>
          </div>
        </div>
        <div
          v-for="(parlay, index) in ticket?.parlay"
          :key="index"
          class="p-4 border-b-2 border-dashed border-cyan-800 text-xs bg-ticket-slip-parlay"
          :class="{ 'border-none': index + 1 === ticket?.parlay.length }"
        >
          <div class="grid grid-cols-2 gap-1">
            <div class="grid gap-1">
              <div class="font-black">
                <span
                  class="text-blue-700"
                  :class="{ '!text-red-600': parlay?.adv === 1 }"
                >
                  {{ parlay?.home[locale] }}
                </span>
                -v-
                <span
                  class="text-red-600"
                  :class="{ '!text-blue-700': parlay.adv === 1 }"
                >
                  {{ parlay.away[locale] }}
                </span>
              </div>
              <div class="text-slate-500">
                {{ parlay?.league[locale] }}
              </div>
              <div class="text-slate-600 font-bold italic">
                {{ formatDate(parlay.koTime) }}
              </div>
            </div>
            <div class="grid gap-1 justify-items-end text-end h-fit">
              <div
                class="bg-yellow-300 w-fit px-1 font-black"
                :class="resultTextColor(parlay)"
              >
                <span v-if="parlay.result">
                  <span v-html="parlay.result?.text[locale]" />
                  @ {{ parlay.result?.score }}
                </span>
                <span v-if="!parlay.result">รอคิดผล</span>
              </div>
              <div class="font-black text-blue-700">
                {{ parlay.betType }}
              </div>
              <div
                class="grid justify-end items-center text-slate-600 h-fit font-black"
              >
                {{ parlay?.selection[locale] }}
                <span class="text-amber-800">
                  {{ formatNumber(parlay?.oddsLine, true) }} @
                  {{ formatNumber(parlay?.odds, true) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isError"
      class="mt-10 text-center text-2xl text-slate-300 font-medium"
    >
      ไม่พบตั๋ว
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';

import { apiFetch } from '@/composition/useAxios';
import { formatNumber } from '@/utils/number-format';

const { locale } = useI18n();
const route = useRoute();

const ticket = ref(null);
const isError = ref<boolean>(false);

function resultTextColor(parlay: any) {
  if (parlay.result?.text?.en.toLocaleLowerCase().includes('refund')) {
    return '!bg-red-100 !text-slate-700 line-through opacity-70';
  }
  if (parlay.result?.text?.en.toLocaleLowerCase().includes('won')) {
    return 'text-green-800';
  }
  if (parlay.result?.text?.en.toLocaleLowerCase().includes('lose')) {
    return 'text-red-700';
  }
  return 'text-blue-700';
}

function formatDate(unixTime: number) {
  return dayjs(unixTime * 1000).format('DD-MM-YYYY HH:mm');
}

function getWinlose(ticket: any) {
  return ticket?.winlose + ticket.stake;
}

async function fetchTicketSlip() {
  const { ticketId, createdAt } = route.params;
  try {
    const { data, error } = await apiFetch(`/slip/${ticketId}`, {
      headers: {
        'Accept-Language': locale.value,
      },
      method: 'GET',
    });
    if (data.value) {
      if (Number(createdAt) === data.value.createdAt) {
        ticket.value = data.value;
      } else {
        isError.value = true;
      }
    }
    if (error.value) {
      isError.value = true;
    }
  } catch (error) {
    isError.value = true;
  }
}

onMounted(() => {
  locale.value = 'th';
  isError.value = false;
  ticket.value = null;
  fetchTicketSlip();
});
</script>

<style locale="scss">
.bg-ticket-slip-parlay {
  background-color: #bbdede;
}
</style>
